<template>
  <div class="gaze-confirm-modal">
    <gaze-modal :visible="isOpen" @close="$emit('closeModal')" :is-header="false">
      <template #content>

        <div class="confirm-modal" v-if="!customError.isError">
          <div class="confirm-text" v-if="success">
            <span v-show="type==='offer'">
                {{$t('modal.confirmModal.success.offer')}}
            </span>
            <span v-show="type==='offerCancel'">
               {{$t('modal.confirmModal.success.offerCancel')}}
            </span>
            <span v-show="type==='offerReject'">
               {{$t('modal.confirmModal.success.reject')}}
            </span>
            <span v-show="type==='offerAccept'">
                {{$t('modal.confirmModal.success.offerAccept')}}
            </span>
            <span v-show="type==='register'">
                {{$t('modal.confirmModal.success.register')}}
            </span>

            <span v-show="type==='update'">
                {{$t('modal.confirmModal.success.update')}}
            </span>
            <span v-show="type==='cancel'">
                {{$t('modal.confirmModal.success.cancel')}}
            </span>

            <span v-show="type==='buy'">
                {{$t('modal.confirmModal.success.buy')}}
            </span>
          </div>

          <div class="confirm-text" v-else>
            <div v-show="type==='offer'" class="flex-column">
              <div>
                {{$t('modal.confirmModal.fail.offer')}}
              </div>

              <div class="confirm-route-qa pointer-hover" @click="qaRouter">
                {{$t('modal.confirmModal.fail.offerReason')}}
              </div>
            </div>
            <div v-show="type==='offerCancel'" class="flex-column">
              <div>
                {{$t('modal.confirmModal.fail.offerCancel')}}
              </div>

              <div class="confirm-route-qa pointer-hover" @click="qaRouter">
                {{$t('modal.confirmModal.fail.offerCancelQaRouter')}}
              </div>
            </div>
            <div v-show="type==='offerReject'" class="flex-column">
              <div>
                {{$t('modal.confirmModal.fail.offerReject')}}
              </div>

              <div class="confirm-route-qa pointer-hover" @click="qaRouter">
               {{$t('modal.confirmModal.fail.offerRejectQaRouter')}}
              </div>
            </div>
            <div v-show="type==='offerAccept'" class="flex-column">
              <div>
                {{$t('modal.confirmModal.fail.offerAccept')}}
              </div>

              <div class="confirm-route-qa pointer-hover" @click="qaRouter">
               {{$t('modal.confirmModal.fail.offerAcceptQaRouter')}}
              </div>
            </div>


            <div v-show="type==='register'" class="flex-column">
              <div>
                {{$t('modal.confirmModal.fail.register')}}
              </div>
              <div class="confirm-route-qa pointer-hover" @click="qaRouter">
              {{$t('modal.confirmModal.fail.registerQaRouter')}}
              </div>
            </div>

            <div v-show="type==='update'" class="flex-column">
              <div>
                {{$t('modal.confirmModal.fail.update')}}
              </div>

              <div class="confirm-route-qa pointer-hover" @click="qaRouter">
                {{$t('modal.confirmModal.fail.updateQaRouter')}}
              </div>
            </div>

            <div v-show="type==='cancel'" class="flex-column">
              <div>
                {{$t('modal.confirmModal.fail.cancel')}}
              </div>

              <div class="confirm-route-qa pointer-hover" @click="qaRouter">
                {{$t('modal.confirmModal.fail.cancelQaRouter')}}
              </div>
            </div>

            <div v-show="type==='buy'" class="flex-column">
              <div>
                {{$t('modal.confirmModal.fail.buy')}}
              </div>

              <div class="confirm-route-qa pointer-hover" @click="openExternalWindow('https://gazemarket.gitbook.io/support/faq/undefined-1/nft')">
                {{$t('modal.confirmModal.fail.buyQaRouter')}}
              </div>
            </div>
          </div>

          <div v-if='success' class="confirm-btn pointer-hover" @click="confirmSuccess">
            <div style="width: 100%;text-align: center;">{{$t('modal.confirmModal.check')}}</div>
          </div>
          <div v-else class="confirm-btn pointer-hover" @click="confirmFail">
            <div style="width: 100%;text-align: center;">
              {{$t('modal.confirmModal.check')}}
            </div>
          </div>
        </div>
        <div class="confirm-modal" v-else>
          <div class="confirm-text">
            <div class="flex-column">
              <div>
                {{ customError.title }}
              </div>

              <div class="confirm-route-qa pointer-hover">
                {{ customError.content }}
              </div>
            </div>
          </div>
          <div class="confirm-btn pointer-hover" @click="customConfirm">
            <div style="width: 100%;text-align: center;">
              {{$t('modal.confirmModal.check')}}
            </div>
          </div>
        </div>

      </template>
    </gaze-modal>

  </div>
</template>

<script>
import {defineComponent} from "vue";
import GazeModal from "@/components/common/modal/GazeModal";
import {useRouter} from "vue-router";
import {useModal} from "@/script/common/modalScript";
import {TrstConfirmError} from "@/models/commonModels";
import {windowUtils} from "@/utils/windowUtils";

export default defineComponent({
  name: "NftConfirmModalComp",
  components: {
    GazeModal,
  },
  emits: [
    "success",
    "fail",
    "update:modelValue",
    "update:customError",
  ],
  props: {
    type: {
      default: "",
    },
    nftInfo: {
      default: null
    },
    success: {
      default: false
    },
    title: {
      default: ''
    },
    customError: {
      default: {
        isError: false,
        title: "",
        content: "",
        onOk: {},
      }
    }
  },
  setup(props, context) {
    const router = useRouter()
    const {
      openExternalWindow
    } = windowUtils()
    const confirmSuccess = () => {
      context.emit("success");
    }
    const confirmFail = () => {
      context.emit("fail");
    }
    const {
      isOpen,
      toggleModal,
    } = useModal();
    toggleModal(true);
    const toggleConfirmSuccessModal = () => {
      context.emit('toggleConfirmSuccessModal')
      // router.push('/')
    }

    const qaRouter = () => {
      router.push({name: 'ContactView'})
    }

    const initCustomError = () => {
      context.emit("update:customError", {
        isError: false,
        title: "",
        content: "",
        onOk: {},
      })
    }

    const customConfirm = () => {
      props.customError.onOk();
      initCustomError();
    }

    return {
      confirmSuccess,
      confirmFail,
      isOpen,
      toggleModal,
      toggleConfirmSuccessModal,
      qaRouter,
      customConfirm,
      openExternalWindow
    }
  }
})
</script>

<style scoped>

</style>