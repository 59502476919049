
import {computed, defineComponent} from "vue";
import {store} from "@/store";
import WalletInstallPopup from "@/components/pages/popup/WalletInstallPopup.vue";
import WarningPopup from "@/components/pages/popup/WarningPopup.vue";
import WarningBasketPopup from "@/components/pages/popup/WarningBasketPopup.vue";
import LoginAlertPopup from "@/components/pages/popup/LoginAlertPopup.vue";
import CommonAlertPopup from "@/components/pages/popup/CommonAlertPopup.vue";
export default defineComponent({
  name: "PopupView.vue",
  components: {
    WalletInstallPopup,
    WarningPopup,
    WarningBasketPopup,
    LoginAlertPopup,
    CommonAlertPopup
  },
  setup() {
    const popup = computed(() => {
      return store.state.open.popup;
    })

    return {
      popup
    }
  }
})
