import {Module} from "vuex";
import {RootState} from "../index";

export interface ProfileState {
    [key: string]: any,
    id: number,
    name: string,
    email: string,
    mrktPolicyAgreed: boolean,
    isRcmd: boolean,
    isVerified: boolean,
    isVerifiedEmail: boolean,
    profileImg: string,
    intrd: string,
    socialLinks: any,
}

export const profileModule: Module<ProfileState, RootState> = {
    namespaced: true,
    state: {
        id: 0,
        name: "",
        email: "",
        mrktPolicyAgreed: false,
        isRcmd: false,
        isVerified: false,
        isVerifiedEmail: false,
        profileImg: "",
        intrd: "",
        socialLinks: {},
    },
    mutations: {
        FETCH_PROFILE_INFO: (state: ProfileState, payload: ProfileState) => {
            Object.keys(state).forEach((key) => {
                state[key] = payload[key];
            })
        },
        FETCH_PROFILE_IMAGE: (state, imageUrl) => {
            state.profileImg = imageUrl;
        }
    },
    actions: {
    },
    getters: {
    },
};
