import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-layout" }
const _hoisted_2 = { class: "header-sticky" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_system_header_view = _resolveComponent("mobile-system-header-view")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_mobile_system_header_view)
    ]),
    _createElementVNode("section", null, [
      _createVNode(_component_router_view)
    ])
  ]))
}