import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "logged-in-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: "menu-list first pointer-hover",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.moveRouter('MyPageView')))
      }, _toDisplayString(_ctx.$t('common.header.loggedInHoverMenu.myNft')), 1),
      _createElementVNode("div", {
        class: "menu-list pointer-hover",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.moveRouter('Dashboard')))
      }, _toDisplayString(_ctx.$t('common.header.loggedInHoverMenu.dashboard')), 1),
      _createElementVNode("div", {
        class: "menu-list pointer-hover",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.moveRouter('WalletManageView')))
      }, _toDisplayString(_ctx.$t('common.header.loggedInHoverMenu.paymentManagement')), 1),
      _createElementVNode("div", {
        class: "menu-list pointer-hover",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.moveRouter('UserConfig')))
      }, _toDisplayString(_ctx.$t('common.header.loggedInHoverMenu.accountSetting')), 1),
      _createElementVNode("div", {
        class: "menu-list logout-btn pointer-hover",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
      }, _toDisplayString(_ctx.$t('common.header.loggedInHoverMenu.logout')), 1)
    ])
  ]))
}