import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_basket_view = _resolveComponent("basket-view")!
  const _component_gaze_app_loading = _resolveComponent("gaze-app-loading")!
  const _component_loading_transfer_view = _resolveComponent("loading-transfer-view")!
  const _component_popup_view = _resolveComponent("popup-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })),
    _createVNode(_component_basket_view),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_gaze_app_loading, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.transferLoading)
      ? (_openBlock(), _createBlock(_component_loading_transfer_view, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_popup_view)
  ], 64))
}