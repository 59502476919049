import {message} from "ant-design-vue";
import {store} from "@/store";
import {openPopup} from "@/script/common/popupScript";

export const setKaikasInitEvent = (kaikas: any) => {
    kaikas.on("accountsChanged", function (accounts: any) {
        store.commit("wallet/FETCH_KAIKAS_INFO", {isEnabled: true, address: accounts[0]});
        openPopup('warning',{message:'paymentManage.activeWallet'})
        // Time to reload your interface with accounts[0]!
    });

    kaikas.on("networkChanged", function (networkId: any) {
        console.log('network', networkId)
        // `networkChanged` event is only useful when auto-refresh on network is disabled
        // Otherwise, Kaikas will auto-reload pages upon network change
    });

    kaikas.on("disconnected", function () {
        console.log('disconnected')
        // Use the `disconnected` event to check the wallet connection status.
    });
}