// import { DeleteProductTag, ProductTag } from "@/models/productModels";
import {ProductList} from "@/models/productModels";


import {Module} from "vuex";
import {RootState} from "../index";


export interface filterState {
    product: {
        filterTagList: Array<any> | [],
        selectedTagList: Array<any>,
    }
}

export const filterModule: Module<filterState, RootState> = {
    namespaced: true,
    state: () => ({
        product: {
            filterTagList: [
                {
                    title: 'Status1',
                    filterName: ['Offer Sale', 'Direct Sale', 'Has Offers', 'Closed Auction'],
                    selected: false
                },
                {
                    title: 'Status2',
                    filterName: ['Offer Sale', 'Direct Sale', 'Has Offers', 'Closed Auction'],
                    selected: false
                }, {
                    title: 'Status3',
                    filterName: ['Offer Sale', 'Direct Sale', 'Has Offers', 'Closed Auction'],
                    selected: false
                }, {
                    title: 'Status4',
                    filterName: ['Offer Sale', 'Direct Sale', 'Has Offers', 'Closed Auction'],
                    selected: false
                }, {
                    title: 'Status5',
                    filterName: ['Offer Sale', 'Direct Sale', 'Has Offers', 'Closed Auction'],
                    selected: false
                },
            ],
            selectedTagList: [],
        }
    }),
    mutations: {
        SET_FILTER_TAG: (state, filterTagList) => {
            state.product.filterTagList = filterTagList;
        },
        SET_SELECTED_TAG: (state, selectedTag) => {
            state.product.selectedTagList = selectedTag;
        },
        ADD_SELECTED_TAG: (state, item: string) => {

            state.product.selectedTagList.push(item)
        }

    },
    actions: {
        applyFilterTag: ({commit}, list) => {

            commit("SET_FILTER_TAG", list)
        },
        applySelectTag: ({commit}, tag) => {

            commit("SET_SELECTED_TAG", tag)
        },

        // applyProductTag: ({ commit }, { typeTagList, statusTagList }) => {
        //     const newTypeTagList: Array<ProductTag> = [];
        //     const newStatusTagList: Array<ProductTag>  = [];
        //     typeTagList.forEach((tag: ProductTag) => {
        //         if (tag.selected && tag.value !== "all") newTypeTagList.push(tag);
        //     })
        //     statusTagList.forEach((tag: ProductTag) => {
        //         if (tag.selected && tag.value !== "all") newStatusTagList.push(tag);
        //     })
        //     commit("SET_PRODUCT_TAG", { newTypeTagList, newStatusTagList })
        // },
        // deleteProductTag: ({ commit }, { index, listName }) => {
        //     commit("DELETE_PRODUCT_TAG", { index, listName});
        // }
    },
    getters: {},
};
