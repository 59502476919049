import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nft_buying_modal_comp = _resolveComponent("nft-buying-modal-comp")!
  const _component_nft_offer_sign_comp = _resolveComponent("nft-offer-sign-comp")!
  const _component_nft_confirm_modal_comp = _resolveComponent("nft-confirm-modal-comp")!
  const _component_gaze_pending_modal = _resolveComponent("gaze-pending-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.priceOpen)
      ? (_openBlock(), _createBlock(_component_nft_buying_modal_comp, {
          key: 0,
          onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglePrice(false))),
          onNextToSign: _ctx.nextToSign,
          buyInfosData: _ctx.buyInfosData
        }, null, 8, ["onNextToSign", "buyInfosData"]))
      : _createCommentVNode("", true),
    (_ctx.signOpen)
      ? (_openBlock(), _createBlock(_component_nft_offer_sign_comp, {
          key: 1,
          onCloseModal: _ctx.closeSignModal,
          title: _ctx.$t('modal.signModal.buy.title'),
          onSignTrst: _ctx.signTrstBuyingApprove
        }, null, 8, ["onCloseModal", "title", "onSignTrst"]))
      : _createCommentVNode("", true),
    (_ctx.confirmOpen)
      ? (_openBlock(), _createBlock(_component_nft_confirm_modal_comp, {
          key: 2,
          success: _ctx.signProps,
          "custom-error": _ctx.customError,
          "onUpdate:custom-error": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customError) = $event)),
          type: _ctx.type,
          onSuccess: _ctx.reloadWindow,
          onFail: _ctx.closeSignModal
        }, null, 8, ["success", "custom-error", "type", "onSuccess", "onFail"]))
      : _createCommentVNode("", true),
    (_ctx.pendingOpen)
      ? (_openBlock(), _createBlock(_component_gaze_pending_modal, {
          key: 3,
          visible: _ctx.pendingOpen,
          title: '결제 지연 안내',
          contents: '결제'
        }, null, 8, ["visible"]))
      : _createCommentVNode("", true)
  ], 64))
}