<template>
  <div class="gaze-app-loading">
    <img src="@/assets/image/logo/bubble-logo.png" class="loading-icon">
  </div>
</template>

<script>
import { onUnmounted } from "vue";

export default {
  name: "GazeLoading",
  setup() {
    document.body.style.overflow = "hidden";
    onUnmounted(() => {
      document.body.style.overflow = "unset";
    })
  }
}
</script>

<style scoped>

</style>