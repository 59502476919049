import {Module} from "vuex";
import {RootState} from "../index";
import router from "@/router";
import {notification, Modal} from "ant-design-vue";
import {loginApi} from "@/api/authApis";
import {LoginForm, LoginState, RegisterForm} from "@/models/authModels";


export interface EmailState {
    isVerifiedEmail:boolean
}

const initAuth = (): EmailState => {
    return {
        isVerifiedEmail:false
    };
};

export const emailModule: Module<EmailState, RootState> = {
    namespaced: true,
    state: () => initAuth(),

    mutations: {
        SET_VERIFIED_EMAIL: (state, info) => {
            state.isVerifiedEmail = info.isVerifiedEmail;

        },

    },
    actions: {
        checkEmail: async ({commit}, registerVerifiedEmail:any) => {
            commit("SET_VERIFIED_EMAIL", registerVerifiedEmail);
        },


    },
    getters: {},
};
