import {
    COIN_GROUP,
    NFT_STATUS_GROUP,
    WALLET_GROUP,
    NFT_HISTORY_TYPE,
    NFT_RESULT_GROUP,
    NFT_SIGN_RESULT_GROUP
} from "@/config/constants";

export const getCoin = (unit: string) => {
    if (COIN_GROUP[unit as keyof typeof COIN_GROUP]) return COIN_GROUP[unit as keyof typeof COIN_GROUP];
    return COIN_GROUP.default;
}

export const getCoinIcon = (unit: string,color?:string) => {
    if(color==='WHITE' ){
        return COIN_GROUP[unit as keyof typeof COIN_GROUP].whiteIcon;
    }
    if (COIN_GROUP[unit as keyof typeof COIN_GROUP]){
        return COIN_GROUP[unit as keyof typeof COIN_GROUP].icon;
    }
    return COIN_GROUP.default.icon;
}

export const getNftStatus = (status: string, result?: string) => {
    const nftStatus =  NFT_STATUS_GROUP[status as keyof typeof NFT_STATUS_GROUP]
    const nftResult = NFT_RESULT_GROUP[result as keyof typeof NFT_RESULT_GROUP]
    const nftSignResult = NFT_SIGN_RESULT_GROUP[result as keyof typeof NFT_SIGN_RESULT_GROUP]
    if (nftStatus.value==='TRANSFER' && nftResult) return {
        name: `${nftStatus.name}`,
        result:`${nftResult.name}`,
        icon: nftResult.icon,
        value: nftStatus.value
    }

    if(nftStatus.value!=='TRANSFER' && nftResult)return {
        name: `${nftStatus.name}`,
        result:`${nftResult.name}`,
        icon: nftSignResult.icon,
        value: nftStatus.value
    }

    if (nftStatus) return nftStatus;
    return NFT_STATUS_GROUP.default;
}

export const getNftHistoryType = (status: string) => {
    if (NFT_HISTORY_TYPE[status as keyof typeof NFT_HISTORY_TYPE]) return NFT_HISTORY_TYPE[status as keyof typeof NFT_HISTORY_TYPE];
    return NFT_HISTORY_TYPE.default;
}
export const getNftResult = (result: string) => {
    if (NFT_RESULT_GROUP[result as keyof typeof NFT_RESULT_GROUP]) return NFT_RESULT_GROUP[result as keyof typeof NFT_RESULT_GROUP];
    return NFT_RESULT_GROUP.default;
}

export const getWalletByUnit = (unitName: string) => {
    const wallet = Object.entries(WALLET_GROUP).find(([key, value]) => {
        const units: string[] = value.units;
        return units.includes(unitName);
    })

    return wallet ? WALLET_GROUP[wallet[0] as keyof typeof WALLET_GROUP ] : WALLET_GROUP.default
}

export const getWalletByName = (walletName: string) => {
    const wallet = WALLET_GROUP[walletName as keyof typeof WALLET_GROUP ];
    return wallet ? wallet : WALLET_GROUP.default
}