export const windowUtils = ()=>{
    const openExternalWindow =(url:string) =>{
        window.open(`${url}`)
    }

    return{
        openExternalWindow
    }

}
export const isMobileDevice =()=> {
    const userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

export const isMetamaskMobile =() =>{
    const userAgent = navigator.userAgent;
    return /MetaMAskMobile/i.test(userAgent);
}

export const isKaikasMobile =() =>{
    const userAgent = navigator.userAgent;
    return /Kaikas/i.test(userAgent);
}