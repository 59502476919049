
import {defineComponent, ref, computed, onMounted, onBeforeUnmount} from "vue";
import {useStore} from "@/store";
import {useRoute, useRouter} from "vue-router";
import {
  useMobileSideBar,
} from "@/script/layout/navBarScript";
import LoggedInHoverMenuBar from "@/components/layout/bar/LoggedInHoverMenuBar.vue";
import GazeInput from "@/components/common/input/GazeInput.vue";
import MobileSideBar from "@/components/layout/mobile/MobileSideBar.vue";
import {useBasket} from "@/script/basket/basketScript";
import {useHeaderFilter, useHeaderSearch} from "@/script/common/headerSearchScript";
import {debounce} from 'lodash'
import {getCoinIcon} from "@/script/common/constantScript";
import {useTempImg} from "@/script/temp/tempScript";
import {useLogin} from "@/script/login/loginScript";
import {useHeaderHover} from "@/script/header/headerHoverScript";
import NftImageOrVideoSmall from "@/components/pages/nft/comp/NftImageOrVideoSmall.vue";
import i18nComp from "@/components/layout/header/i18nComp.vue";
import {useI18n} from "vue-i18n";
import SearchSvg from "@/components/svg/Search.vue";
import CartSvg from "@/components/svg/CartSvg.vue";
import HamburgerSvg from "@/components/svg/Hamburger.vue";

export default defineComponent({
  name: "HeaderView",
  components: {
    LoggedInHoverMenuBar,
    GazeInput,
    MobileSideBar,
    NftImageOrVideoSmall,
    i18nComp,
    SearchSvg,
    CartSvg,
    HamburgerSvg,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute()
    const isLoginLoading = computed(() => {
      return store.state.loading.app.isLogin;
    })
    const {
      searchValue,
      searchKeyword,
      fetchHomeSearchList,
      resetSearchKeyword,
      searchNftList,
      searchCltnList,
      searchMemberList,
      searchNftListLength,
      searchCltnListLength,
      searchMemberListLength,
    } = useHeaderSearch()

    const {nftShoppingHoverMenu, userProfileImg} = useHeaderFilter()

    const {tempProfile} = useTempImg()
    const {locale} = useI18n()

    const {
      fetchHeaderShopCltn,
      headerShopCltnData,
      routeCltnDetail
    } = useHeaderHover()
    fetchHeaderShopCltn()

    const setLocalStorageNftTab = () => {
      localStorage.setItem('nft-selectedTab', 'nft')
    }

    const setLocalStorageCltnTab = () => {
      localStorage.setItem('nft-selectedTab', 'cltn')
    }
    const searchByKeyword = debounce(() => {
      fetchHomeSearchList(searchKeyword.keyword)
    }, 100)
    const globalSearchKeyword = computed(() => {
      return store.state.search.keyword
    })
    const moveNftList = async (url: any) => {
      await setLocalStorageNftTab()
      await router.push({name: 'NftListView', query: {name: url, keyword: globalSearchKeyword.value}})
    }

    const moveCltnList = async (url: any) => {
      await setLocalStorageCltnTab()
      await router.push({name: 'NftListView', query: {name: url, keyword: globalSearchKeyword.value}})
    }

    const moveNftListCoinType = async (url: any, coin: any) => {
      await setLocalStorageNftTab()
      await router.push({name: 'NftListView', query: {name: url, tokenStnd: coin}})
    }

    const moveNftListTags = async (url: any, tag: any, index: any) => {
      await setLocalStorageNftTab()
      await router.push({name: 'NftListView', query: {name: url, tag: tag, index: index}})
    }


    const goHome = () => {
      window.location.href = ''
    }
    const closeModal = () => {
      menuOpen.value = false
      window.location.reload()
    }
    const closeMenu = () => {
      dropdown.active.value = false
    }
    const {moveLoginPage} = useLogin()

    const useIsLogin = computed(() => store.state.auth.isLogin);
    const menuOpen = ref(false)
    const isOpenNftShoppingHoverMenu = ref(false)

    const nftWallets = [
      {
        icon: require('@/assets/image/icons/header/shopping-hover/3d.svg'),
        title: 'KLAY'
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/3d.svg'),
        title: 'MATIC'
      },
    ]

    const cltnImg = [
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png')
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png')
      }, {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png')
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png')
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png')
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png')
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png')
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png')
      },

      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png')
      },

    ]
    const isMenuOpen = ref(false)

    const {isOpen, toggleSidebar} = useMobileSideBar()

    const userName = computed(() => store.state.auth.name);

    const dropdown = {
      active: ref(false),
      close: () => {
        dropdown.active.value = false
      }
    }

    const {
      toggleBasket,
      checkedBasketList
    } = useBasket()
    const searchRef = ref()
    const openSearchBar = {
      active: ref(false),
      close: () => {
        openSearchBar.active.value = false
        resetSearchKeyword()
      }
    }

    const resetSearchBar = () => {
      openSearchBar.active.value = false
    }

    onBeforeUnmount(() => {
      document.removeEventListener('click', dropdown.close)
      document.removeEventListener('click', openSearchBar.close)
    })

    onMounted(() => {
      document.addEventListener('click', dropdown.close)
      document.addEventListener('click', openSearchBar.close)

    })

    const tempThumimg = {
      icon: require('@/assets/image/thumb.png')
    }

    const moveDetailPage = async (type: any, id: any) => {
      if (type === 'nft') {
        await router.push(`/nft/${id}`)
        openSearchBar.active.value = false
      }
      if (type === 'cltn') {
        await router.push(`/cltn/detail/${id}`)
        openSearchBar.active.value = false
      }

      if (type === 'member') {
        await router.push(`/member/detail/${id}`)
        openSearchBar.active.value = false
      }
      await resetSearchKeyword()
    }

    return {
      isOpen,
      toggleSidebar,
      userName,
      router,
      isMenuOpen,
      nftShoppingHoverMenu,
      nftWallets,
      isOpenNftShoppingHoverMenu,
      cltnImg,
      useIsLogin,
      menuOpen,
      closeModal,
      dropdown,
      closeMenu,
      toggleBasket,
      openSearchBar,
      searchRef,
      searchValue,
      searchKeyword,
      searchByKeyword,
      resetSearchKeyword,
      searchNftList,
      searchCltnList,
      searchMemberList,
      tempThumimg,
      searchNftListLength,
      searchCltnListLength,
      searchMemberListLength,
      moveNftList,
      moveDetailPage,
      getCoinIcon,
      moveNftListCoinType,
      checkedBasketList,
      moveNftListTags,
      tempProfile,
      userProfileImg,
      resetSearchBar,
      moveLoginPage,
      isLoginLoading,
      headerShopCltnData,
      routeCltnDetail,
      moveCltnList,
      locale
    };
  },
});
