const mobileSideBarNftShoppingMenu:any = [
    {
        icon: require('@/assets/image/icons/header/shopping-hover/sub-cards.png'),
        title: 'common.header.mobile.mobileNftShoppingSection.category.curation',
        tag:'GC',
        index: 0,
    },
    {
        icon: require('@/assets/image/icons/header/shopping-hover/coin.svg'),
        title: 'common.header.mobile.mobileNftShoppingSection.category.underTen',
        tag:'MAXPRICEWON',
        index: 2,
    },
    {
        icon: require('@/assets/image/icons/header/shopping-hover/wine.svg'),
        title: 'common.header.mobile.mobileNftShoppingSection.category.wine',
        tag:'WINE',
        index: 3,
    },
    {
        icon: require('@/assets/image/icons/header/shopping-hover/digital-art.svg'),
        title: 'common.header.mobile.mobileNftShoppingSection.category.digitalArt',
        tag:'PICTURE',
        index: 5,
    },

    {
        icon: require('@/assets/image/icons/header/shopping-hover/ticket.svg'),
        title: 'common.header.mobile.mobileNftShoppingSection.category.ticketMember',
        tag:'TICKET',
        index: 4,
    },

    {
        icon: require('@/assets/image/icons/header/shopping-hover/3d.svg'),
        title: 'common.header.mobile.mobileNftShoppingSection.category.finance',
        tag:'FINANCE',
        index: 7,
    },
]
export default mobileSideBarNftShoppingMenu