import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/wis/events/home',
        name: 'EventHomeView',
        component: () => import('@/components/pages/event/EventHomeView.vue'),
        meta: {
            black: true,
        },
    },

    {
        path: '/wis/events/process',
        name: 'EventView',
        component: () => import('@/components/pages/event/EventView.vue'),
        meta: {
            black: true,
        },
    },
    {
        path: '/wis/events/connect',
        name: 'EventConnectWalletView',
        component: () => import('@/components/pages/event/wallet/EventConnectWalletView.vue'),
        meta: {
            black: true,
            isNotEventLogin:true
        },
    },
    {
        path: '/wis/events/connect/complete',
        name: 'EventConnectCompleteView',
        component: () => import('@/components/pages/event/wallet/EventConnectCompleteView.vue'),
        meta: {
            black: true,
            isNotEventLogin:true
        },
    },
    {
        path: '/wis/events/connect/loading',
        name: 'EventLoadingView',
        component: () => import('@/components/pages/event/wallet/EventLoadingView.vue'),
        meta: {
            black: true,
            headerNone:true,
            isNotEventLogin:true
        },
    },
    {
        path: '/wis/events/verified',
        name: 'EventVerifiedView',
        component: () => import('@/components/pages/event/EventVerifiedView.vue'),
    },
    {
        path: '/wis/events/wait',
        name: 'EventWaitView',
        component: () => import('@/components/pages/event/EventWaitView.vue'),
    },
    {
        path: '/wis/events/register',
        name: 'EventRegisterView',
        component: () => import('@/components/pages/event/EventRegisterView.vue'),
    },
    {
        path: '/wis/events/login',
        name: 'EventLoginView',
        component: () => import('@/components/pages/event/EventLoginView.vue'),
    },
    {
        path: '/wis/events/connect/kaikas',
        name: 'EventConnectKaikasWalletView',
        component: () => import('@/components/pages/event/wallet/EventConnectKaikasWalletView.vue'),
        meta: {
            black: true,
            isNotEventLogin:true
        },
    },
];

export default routes