import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/error',
        name: 'CommonErrorView',
        component: () => import('@/components/pages/error/CommonErrorView.vue'),
        props: true
    },
];

export default routes