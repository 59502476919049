import { Module } from 'vuex'
import { RootState } from '../index'
import {getAllNftsApi, getNftDetailApi, getNftHistoryApi} from "@/api/nftApis";

export interface kaikasState {
    kaikasWalletAddress: any;
}

export const kaikasModule: Module<kaikasState, RootState> = {
    namespaced: true,
    state: () => ({
        kaikasWalletAddress: '',
    }),
    mutations: {
        SAVE_KAIKAS_WALLETADDRESS: (state, payload) => {
            state.kaikasWalletAddress = payload
        },
    },
    actions: {
        saveKaikasWalletAddress: ({commit, state}, value) => {
            commit("SAVE_KAIKAS_WALLETADDRESS",value );
        },
    },
    getters: {},
}