import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "common-layout" }
const _hoisted_2 = {
  key: 1,
  class: "header-sticky"
}
const _hoisted_3 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_event_black_header = _resolveComponent("event-black-header")!
  const _component_header_view = _resolveComponent("header-view")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_view = _resolveComponent("footer-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.header.black)
      ? (_openBlock(), _createElementBlock("header", {
          key: 0,
          class: _normalizeClass(_ctx.header.headerNone ? 'display-none' : '')
        }, [
          _createVNode(_component_event_black_header)
        ], 2))
      : (_openBlock(), _createElementBlock("header", _hoisted_2, [
          _createVNode(_component_header_view)
        ])),
    _createElementVNode("section", null, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("footer", _hoisted_3, [
      _createVNode(_component_footer_view)
    ])
  ]))
}