// import { DeleteProductTag, ProductTag } from "@/models/productModels";
import {ProductList} from "@/models/productModels";


import {Module} from "vuex";
import {RootState} from "../index";


export interface mypageState {
    id:any,
    userList: Array<any>,
    itrtNftList: Array<any>,
    itrtCltnList: Array<any>
    haveNftTotal: any,
    itrtNftTotal: any,
    itrtCltnTotal: any
}

export const mypageModule: Module<mypageState, RootState> = {
    namespaced: true,
    state: () => ({
        id: '',
        userList: [],
        itrtNftList: [],
        itrtCltnList: [],
        haveNftTotal: 0,
        itrtNftTotal: 0,
        itrtCltnTotal: 0

    }),
    mutations: {

        ADD_USER_ID: (state, item) => {
            state.id = item.id
        },

        DELETE_USER_ID: (state, item) => {
            state.id = ''
        },

        ADD_USER_HAVE_NFT_LIST: (state, item) => {
            state.userList.push(...item)
        },

        DELETE_USER_HAVE_NFT_LIST: (state, item) => {
            state.userList = []
        },

        ADD_USER_HAVE_NFT_TOTAL: (state, item) => {
            state.haveNftTotal = item
        },

        ADD_USER_ITRT_NFT_LIST: (state, item) => {

            state.itrtNftList.push(...item)
        },

        DELETE_USER_ITRT_NFT_LIST: (state, item) => {
            state.itrtNftList = []
        },
        ADD_USER_ITRT_NFT_TOTAL: (state, item) => {

            state.itrtNftTotal = item
        },

        ADD_USER_ITRT_CLTN_LIST: (state, item) => {

            state.itrtCltnList.push(...item)
        },

        DELETE_USER_ITRT_CLTN_LIST: (state, item) => {
            state.itrtCltnList = []
        },
        ADD_USER_ITRT_CLTN_TOTAL: (state, item) => {

            state.itrtCltnTotal = item
        },


    },
    actions: {

        applyUserId: ({commit}, nft) => {

            commit("ADD_USER_ID", nft)
        },
        deleteUserId: ({commit}) => {
            commit("DELETE_USER_ID")
        },

        applyUserHaveNftList: ({commit}, nft) => {

            commit("ADD_USER_HAVE_NFT_LIST", nft)
        },
        deleteUserHaveNftList: ({commit}) => {
            commit("DELETE_USER_HAVE_NFT_LIST")
        },
        applyUserItrtNftList: ({commit}, nft) => {

            commit("ADD_USER_ITRT_NFT_LIST", nft)
        },
        deleteUserItrtNftList: ({commit}, nft) => {
            commit("DELETE_USER_ITRT_NFT_LIST", nft)
        },

        applyUserItrtCltnList: ({commit}, nft) => {

            commit("ADD_USER_ITRT_CLTN_LIST", nft)
        },
        deleteUserItrtCltnList: ({commit}, nft) => {
            commit("DELETE_USER_ITRT_CLTN_LIST", nft)
        },

        applyUserHaveNftTotal: ({commit}, nft) => {

            commit("ADD_USER_HAVE_NFT_TOTAL", nft)
        },
        applyUserItrtNftTotal: ({commit}, nft) => {

            commit("ADD_USER_ITRT_NFT_TOTAL", nft)
        },
        applyUserItrtCltnTotal: ({commit}, nft) => {

            commit("ADD_USER_ITRT_CLTN_TOTAL", nft)
        },

    },
    getters: {},
};
