import {ref, reactive, UnwrapRef, SetupContext, EmitsOptions, computed} from 'vue';
import {LoginForm, RegisterForm} from '@/models/authModels';
import {
    EMAIL,
    FILE_REQUIRED,
    PHONE,
    PWD,
    REG_NUM,
    REQUIRED,
    NICKNAME,
    REQUIRED_CHANGE,
    requireRule, emailCheck, nicknameCheck, pwdCheck, emailRequiredRule, passwordRequiredRule, nicknameRequiredCheck
} from '@/utils/rules';
import {Store} from 'vuex';
import {RootState} from '@/store';
import {getUserNickNameCheckApi, registerApi} from '@/api/authApis';
import {useRouter} from 'vue-router';
import {notification} from 'ant-design-vue';
import {Modal, message} from 'ant-design-vue';
import {store} from '@/store';
import {debounce} from 'lodash';
import {wisEventRegisterApi} from "@/api/wisApis";
import {useI18n} from "vue-i18n";
import {openPopup} from "@/script/common/popupScript";

export const useRegister = () => {
    const router = useRouter();

    const registerForm: UnwrapRef<RegisterForm> = reactive({
        name: '',
        email: '',
        pwd: '',
        nickname: '',
        lang:'ko',
        mrktPolicyAgreed: false,
    });
    const checkPwdForm = reactive({
        pwdCheck: '',
    });

    const errName = ref();
    const loading = ref(false);

    const onRegister = async (registerForm: RegisterForm) => {
        try {
            loading.value = true;
            const registerRes = await registerApi(registerForm, termsMarketing.value);
            await store.dispatch('email/checkEmail', registerRes.isVerifiedEmail);
            openPopup("warning", {message: '회원가입이 완료되었습니다.'})
            await router.push({name: 'WaitView', params: {emailTypeParams: 'emailVerify'}});
        } catch (error: any) {
            if (error.response.data.message === 'ALREADY_EXISTS : Already exist name') {
                errName.value = 1;
            }
            if (error.response.data.message === 'ALREADY_EXISTS : Already exist email') {
                errName.value = 2;
            }

            if (error.response.data.message === 'INVALID_EMAIL') {
                errName.value = 3;
            }

            if (error.response.data.message === 'INVALID_VALUE : 연속된 4자리 숫자 or 문자 비밀번호') {
                errName.value = 4;
            }

            formRef.value.validate();
        } finally {
            // errMsg.value =''
            errName.value = '';
            loading.value = false;
        }
    };
    const onWisRegister = async (registerForm: any) => {
        try {
            loading.value = true;
            const registerRes = await wisEventRegisterApi(registerForm, termsMarketing.value);
            await store.dispatch('email/checkEmail', registerForm.email);
            notification.success({
                message: '회원가입이 완료되었습니다.',
            });
            await router.push({name: 'EventWaitView'});
        } catch (error: any) {
            if (error.response.data.message === 'ALREADY_EXISTS : Already exist name') {
                errName.value = 1;
            }
            if (error.response.data.message === 'ALREADY_EXISTS : Already exist email') {
                errName.value = 2;
            }

            if (error.response.data.message === 'INVALID_EMAIL') {
                errName.value = 3;
            }

            if (error.response.data.message === 'INVALID_VALUE : 똑같은 4자리 숫자 or 문자 비밀번호') {
                errName.value = 4;
            }

            formRef.value.validate();
        } finally {
            // errMsg.value =''
            errName.value = '';
            loading.value = false;
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        // return
    };

    const errorMsgFieldNickName = async (_rule: any) => {
        if (errName.value === 1) {
            return Promise.reject('이미 사용 중인 닉네임이에요.');
        }
    };

    const errorMsgFieldEmail = async (_rule: any) => {
        if (errName.value === 2) {
            return Promise.reject('해당 이메일은 이미 가입되어 있어요.');
        }
        errMsg.value = '';
    };

    const errorMsgFieldPassword = async (_rule: any) => {
        if (errName.value === 4) {
            return Promise.reject('연속적인 숫자 또는 문자는 사용 할 수 없습니다.');
        }
        errMsg.value = '';
    };

    const errorTerms = async (_rule: any) => {
        if (termsService.value === false) {
            return Promise.reject('필수 항목입니다.');
        }
    };

    const errorPITerms = async (_rule: any) => {
        if (termsPersonalInfo.value === false) {
            return Promise.reject('필수 항목입니다.');
        }
    };
    const formRef = ref();
    const errMsg = ref();
    const errorMsgField = async (_rule: any) => {
        if (errMsg.value) {
            return Promise.reject(`${errMsg.value}`);
        }
        errMsg.value = '';
    };

    const onChangeNickName = async (e: any) => {
        try {
            const result = await getUserNickNameCheckApi(e.target.value);
            errName.value = '';
        } catch (error: any) {
            if (error.response.data.errorCode === 1003) {
                errName.value = 1;
            }
        } finally {
            formRef.value.validateFields('name');
        }
    };

    const { locale, t } = useI18n();
    const validateChange = ref(false);
    const rules = computed(() => {
        const error = document.querySelector('#register-form')?.querySelector('.ant-form-item-explain-error')
        validateChange.value = !!error;
        return {
            name: [
                {validator: errorMsgFieldNickName, trigger: 'blur',message: t('rules.alreadyUse')},
                requireRule(t),

            ],
            email: [
                {validator: errorMsgFieldEmail,message: t('rules.alreadyEmail')},
                emailRequiredRule(),
                emailCheck(t),
            ],
            pwd: [
                {validator: errorMsgFieldPassword,message: t('rules.contiNumberSentence')},
                passwordRequiredRule(),
                pwdCheck(t),
            ],
            nickname: [
                {validator: errorMsgFieldNickName,message: t('rules.alreadyUse')},
                nicknameRequiredCheck(t),
                nicknameCheck(t),
            ],
            termsService: [
                {validator: errorTerms,message: t('rules.emailRequired')}
            ],
            termsPersonalInfo: [
                {validator: errorPITerms,message: t('rules.emailRequired')}
            ],
        }
    })


    const socialRules = reactive({
        termsService: [{validator: errorTerms}],
        termsPersonalInfo: [{validator: errorPITerms}],
    });

    const termsCheckIcon = {
        uncheck: require('@/assets/image/icons/register/terms-checked-icon.png'),
        checked: require('@/assets/image/icons/register/terms-icon.png'),
    };
    const termsService = computed(() => {
        return store.state.terms.service;
    });

    const toggleTermsModal = computed(() => {
        return store.state.terms.toggleTermsModal;
    });

    const termsPersonalInfo = computed(() => {
        return store.state.terms.personalInfo;
    });

    const toggleTermsPIModal = computed(() => {
        return store.state.terms.toggleTermsPersonalInfo;
    });

    const termsMarketing = computed(() => {
        return store.state.terms.marketing;
    });

    const toggleTermsMarketingModal = computed(() => {
        return store.state.terms.toggleTermsMarketing;
    });
    const termsToggleServiceModal = async () => {
        await store.dispatch('terms/openTermsService', !toggleTermsModal.value);
    };

    const termsTogglePersonalInfoModal = async () => {
        await store.dispatch('terms/openTermsPersonalInfo', !toggleTermsPIModal.value);
    };

    const termsToggleMarketingModal = async () => {
        await store.dispatch('terms/openTermsMarketing', !toggleTermsMarketingModal.value);
    };

    const termsAgreeCheckService = async () => {
        await store.dispatch('terms/changeTermsService', true);
        await store.dispatch('terms/openTermsService', !toggleTermsModal.value);
    };

    const termsRejectCheckService = async () => {
        await store.dispatch('terms/changeTermsService', false);
        await store.dispatch('terms/openTermsService', !toggleTermsModal.value);
    };

    const termsAgreePICheckService = async () => {
        await store.dispatch('terms/changeTermsPersonalInfo', true);
        await store.dispatch('terms/openTermsPersonalInfo', !toggleTermsPIModal.value);
    };

    const termsRejectPICheckService = async () => {
        await store.dispatch('terms/changeTermsPersonalInfo', false);
        await store.dispatch('terms/openTermsPersonalInfo', !toggleTermsPIModal.value);
    };
    const termsAgreeMarketingCheckService = async () => {
        await store.dispatch('terms/changeTermsMatketing', true);
        await store.dispatch('terms/openTermsMarketing', !toggleTermsMarketingModal.value);
    };

    const termsRejectMarketingCheckService = async () => {
        await store.dispatch('terms/changeTermsMatketing', false);
        await store.dispatch('terms/openTermsMarketing', !toggleTermsMarketingModal.value);
    };

    const moveRegisterPage = async () => {
        await router.push({name: 'RegisterView'});
    };

    return {
        registerForm,
        rules,
        formRef,
        onRegister,
        onFinishFailed,
        checkPwdForm,
        loading,
        onChangeNickName,
        termsCheckIcon,
        termsService,
        toggleTermsModal,
        termsToggleServiceModal,
        termsAgreeCheckService,
        termsRejectCheckService,
        termsPersonalInfo,
        toggleTermsPIModal,
        termsTogglePersonalInfoModal,
        termsAgreePICheckService,
        termsRejectPICheckService,
        termsMarketing,
        toggleTermsMarketingModal,
        termsToggleMarketingModal,
        termsAgreeMarketingCheckService,
        termsRejectMarketingCheckService,
        moveRegisterPage,
        onWisRegister,
        socialRules,
        validateChange
    };
};
