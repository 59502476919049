<template>
  <div v-if="isMetamaskMobile()" class="gaze-buying-nft-modal">
    <gaze-modal :visible="true" @close="closeModal" :title="$t('modal.nftBuy.title')">
      <template #content>
        <div class="gaze-buying-nft-modal-section">
          <div>
            <div class="gaze-buying-nft-modal-area metamask-modal-contents">
              <div class="mobile-gaze-modal-text mobile-only">
                <div class="mobile-gaze-modal-text-desc">{{$t('modal.nftBuy.mobile.title')}}</div>
              </div>
              <div class="gaze-buying-nft-modal-title">{{$t('modal.nftBuy.subTitle')}}</div>
              <div class="buying-info-top">
                <div class="buying-info-section">
                  <div class="buying-info-img">
                    <nft-image-or-video-small :nft="buyInfosData"></nft-image-or-video-small>
                  </div>
                  <div class="buying-info-desc">
                    <div class="buying-info-name">
                      {{ buyInfosData.nftName ? buyInfosData.nftName : ' - ' }}
                    </div>
                    <div class="buying-info-owner-section">
                      <div class="buying-info-owner">{{$t('modal.nftBuy.owner')}}</div>
                      <div
                          :class="
                          buyInfosData.userImage
                            ? 'buying-info-owner-img'
                            : 'buying-info-owner-img border-none'
                        "
                      >
                        <img
                            :src="
                            buyInfosData.userImage ? buyInfosData.userImage : tempProfile.default
                          "
                            alt=""
                        />
                      </div>
                      <div class="buying-info-owner-name">
                        {{ buyInfosData.userName ? buyInfosData.userName : ' - ' }}
                      </div>
                    </div>
                    <div class="buying-info-owner-price-section">
                      <div class="buying-info-owner-price">
                        <div class="buying-info-owner-price-img">
                          <img :src="getCoinIcon(buyInfosData.priceUnit)" alt=""/>
                        </div>
                        <div class="buying-info-owner-price-amount">
                          {{ buyInfosData.price ? buyInfosData.price.toLocaleString('ko') : ' - ' }}
                          {{ buyInfosData.priceUnit }}
                          <span class="mobile-buying-info-owner-price-amount mobile-only">
                            (￦
                            {{
                              buyInfosData.priceWon
                                  ? buyInfosData.priceWon.toLocaleString('ko')
                                  : ' - '
                            }})
                          </span>
                        </div>
                      </div>
                      <div class="buying-info-owner-price-won desktop-only">
                        (￦
                        {{
                          buyInfosData.priceWon
                              ? buyInfosData.priceWon.toLocaleString('ko')
                              : ' - '
                        }})
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="buying-result-section">
                <div class="buying-result-nft-price-section">
                  <div class="result-fs-16-regular">{{$t('modal.nftBuy.amount')}}</div>
                  <div class="result-fs-18-bold">
                    {{ buyInfosData.price }} {{ buyInfosData.priceUnit }}
                  </div>
                </div>
                <div class="buying-result-service-fee-section">
                  <div class="result-fs-16-regular">{{$t('modal.nftBuy.serviceFee')}}</div>
                  <div class="result-fs-18-bold">
                    {{ buyInfosData.platformFee }} {{ buyInfosData.priceUnit }}
                  </div>
                </div>

                <div class="buying-result-nft-total-price-section">
                  <div class="result-fs-18-bold">{{$t('modal.nftBuy.totalAmount')}}</div>
                  <div>
                    <div class="result-total-price">
                      <div class="result-total-price-img">
                        <img src="@/assets/image/icons/nft-buying-modal/buying-klaytn.png" alt=""/>
                      </div>
                      <div class="result-fs-28-bold">
                        {{ buyInfosData.price ? buyInfosData.price : ' - ' }}
                        {{ buyInfosData.priceUnit }}
                      </div>
                    </div>

                    <div class="result-fs-16">
                      ( ￦ {{ buyInfosData.priceWon?.toLocaleString() }} )
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="buying-btn-section pointer-hover">
              <div class="onboarding-link-btn-section mobile-only">
                <!--                <div class="onboarding-link-btn pointer-hover" @click="toggleOnboarding">모바일 지갑에서 NFT 결제 방법</div>-->
                <!--                <img class="onboarding-link-btn-arrow" src="@/assets/image/icons/onboarding-modal/right-vector.png" alt="">-->
              </div>
              <div class="buying-btn" @click="nextToSign">
                <div>{{$t('modal.nftBuy.buyBtnText1')}}{{ buyInfosData.price }} {{ buyInfosData.priceUnit }} {{$t('modal.nftBuy.buyBtnText2')}}</div>
              </div>
            </div>


          </div>
        </div>
      </template>
    </gaze-modal>
  </div>
  <div v-else class="gaze-buying-nft-modal">
    <gaze-modal :visible="true" @close="closeModal" :title="$t('modal.nftBuy.title')">
      <template #content>
        <div class="gaze-buying-nft-modal-section">
          <div>
            <div class="gaze-buying-nft-modal-area">
              <div class="mobile-gaze-modal-text mobile-only">
                <div class="mobile-gaze-modal-text-desc">{{$t('modal.nftBuy.mobile.title')}}</div>
              </div>
              <div class="gaze-buying-nft-modal-title">{{$t('modal.nftBuy.subTitle')}}</div>
              <div class="buying-info-top">
                <div class="buying-info-section">
                  <div class="buying-info-img">
                    <nft-image-or-video-small :nft="buyInfosData"></nft-image-or-video-small>
                  </div>
                  <div class="buying-info-desc">
                    <div class="buying-info-name">
                      {{ buyInfosData.nftName ? buyInfosData.nftName : ' - ' }}
                    </div>
                    <div class="buying-info-owner-section">
                      <div class="buying-info-owner">{{$t('modal.nftBuy.owner')}}</div>
                      <div
                          :class="
                          buyInfosData.userImage자
                            ? 'buying-info-owner-img'
                            : 'buying-info-owner-img border-none'
                        "
                      >
                        <img
                            :src="
                            buyInfosData.userImage ? buyInfosData.userImage : tempProfile.default
                          "
                            alt=""
                        />
                      </div>
                      <div class="buying-info-owner-name">
                        {{ buyInfosData.userName ? buyInfosData.userName : ' - ' }}
                      </div>
                    </div>
                    <div class="buying-info-owner-price-section">
                      <div class="buying-info-owner-price">
                        <div class="buying-info-owner-price-img">
                          <img :src="getCoinIcon(buyInfosData.priceUnit)" alt=""/>
                        </div>
                        <div class="buying-info-owner-price-amount">
                          {{ buyInfosData.price ? buyInfosData.price.toLocaleString('ko') : ' - ' }}
                          {{ buyInfosData.priceUnit }}
                          <span class="mobile-buying-info-owner-price-amount mobile-only">
                            (￦
                            {{
                              buyInfosData.priceWon
                                  ? buyInfosData.priceWon.toLocaleString('ko')
                                  : ' - '
                            }})
                          </span>
                        </div>
                      </div>
                      <div class="buying-info-owner-price-won desktop-only">
                        (￦
                        {{
                          buyInfosData.priceWon
                              ? buyInfosData.priceWon.toLocaleString('ko')
                              : ' - '
                        }})
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="buying-result-section">
                <div class="buying-result-nft-price-section">
                  <div class="result-fs-16-regular">{{$t('modal.nftBuy.amount')}}</div>
                  <div class="result-fs-18-bold">
                    {{ buyInfosData.price }} {{ buyInfosData.priceUnit }}
                  </div>
                </div>
                <div class="buying-result-service-fee-section">
                  <div class="result-fs-16-regular">{{$t('modal.nftBuy.serviceFee')}}</div>
                  <div class="result-fs-18-bold">
                    {{ buyInfosData.platformFee }} {{ buyInfosData.priceUnit }}
                  </div>
                </div>

                <div class="buying-result-nft-total-price-section">
                  <div class="result-fs-18-bold">{{$t('modal.nftBuy.totalAmount')}}</div>
                  <div>
                    <div class="result-total-price">
                      <div class="result-total-price-img">
                        <img src="@/assets/image/icons/nft-buying-modal/buying-klaytn.png" alt=""/>
                      </div>
                      <div class="result-fs-28-bold">
                        {{ buyInfosData.price ? buyInfosData.price : ' - ' }}
                        {{ buyInfosData.priceUnit }}
                      </div>
                    </div>

                    <div class="result-fs-16">
                      ( ￦ {{ buyInfosData.priceWon?.toLocaleString() }} )
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="buying-btn-section pointer-hover">
              <div class="onboarding-link-btn-section mobile-only">
                <!--                <div class="onboarding-link-btn pointer-hover" @click="toggleOnboarding">모바일 지갑에서 NFT 결제 방법</div>-->
                <!--                <img class="onboarding-link-btn-arrow" src="@/assets/image/icons/onboarding-modal/right-vector.png" alt="">-->
              </div>
              <div class="buying-btn" @click="nextToSign">
                <div>{{$t('modal.nftBuy.buyBtnText1')}}{{ buyInfosData.price }} {{ buyInfosData.priceUnit }} {{$t('modal.nftBuy.buyBtnText2')}}</div>
              </div>
            </div>


          </div>
        </div>
      </template>
    </gaze-modal>
  </div>

  <!--  <onboarding-modal-comp v-if="openOnboarding" :price-unit="buyInfosData.priceUnit" :priceUnit="buyInfosData.priceUnit" @closeModal="toggleOnboarding"></onboarding-modal-comp>-->
</template>

<script>
import {defineComponent, nextTick, ref} from 'vue';
import {useTempImg} from '@/script/temp/tempScript';
import {getCoinIcon} from '@/script/common/constantScript';
import GazeModal from '@/components/common/modal/GazeModal';
import NftImageOrVideoSmall from '@/components/pages/nft/comp/NftImageOrVideoSmall';
import OnboardingModalComp from "@/components/pages/nft/comp/onboarding/OnboardingModalComp.vue";
import {useModal} from "@/script/common/modalScript";
import {isMetamaskMobile} from "@/utils/windowUtils";

export default defineComponent({
  name: 'NftBuyingComp',
  components: {
    GazeModal,
    NftImageOrVideoSmall,
    // OnboardingModalComp
  },
  emits: ['closeModal', 'nextToSign'],
  props: {
    buyInfosData: {
      default: '',
    },
  },
  setup(props, context) {
    // const {} = useBuyingNftModal()
    const {tempProfile, tempNftImg} = useTempImg();
    const closeModal = () => {
      context.emit('closeModal');
    };

    const nextToSign = () => {
      context.emit('nextToSign');
    };
    const {
      isOpen: openOnboarding,
      toggleModal: toggleOnboarding,
    } = useModal()
    const buyingModalClose = () => {
      context.emit('buyingModalClose');
    };

    return {
      closeModal,
      nextToSign,
      buyingModalClose,
      tempProfile,
      tempNftImg,
      getCoinIcon,
      openOnboarding,
      toggleOnboarding,
      isMetamaskMobile
    };
  },
});
</script>

<style scoped></style>
