const mobileCoinType = [
    {
        icon:require('@/assets/image/icons/mobile/cointype/klay-white.png'),
        type:'KLAY'
    },
    {
        icon:require('@/assets/image/icons/mobile/cointype/matic-white.png'),
        type:'MATIC'
    },
]

export default mobileCoinType