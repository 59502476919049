import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "i18n-lan-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_icon = _resolveComponent("i18n-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "i18n-select pointer-hover",
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleSelectedLanguage && _ctx.toggleSelectedLanguage(...args)))
  }, [
    _createVNode(_component_i18n_icon, {
      width: "24",
      height: "24"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.$root.$i18n.locale === 'en' ? "EN" : "한국어") + " ", 1),
      (_ctx.isSelectedLanguage)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.headerStyle ==='black' ? 'i18n-lan-hover black' :'i18n-lan-hover')
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$root.$i18n.locale === 'en' ? 'selected-locale selected' : 'selected-locale'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => ([_ctx.$root.$i18n.locale = 'en',_ctx.setLang('en')]))
            }, " English ", 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$root.$i18n.locale === 'ko' ? 'selected-locale selected' : 'selected-locale'),
              onClick: _cache[1] || (_cache[1] = ($event: any) => ([_ctx.$root.$i18n.locale = 'ko',_ctx.setLang('ko')]))
            }, " 한국어 ", 2)
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}