import {RouteRecordRaw} from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/terms/privacy',
        name: 'TermsOfPrivacy',
        component: () => import('@/components/pages/terms/TermsOfPrivacy.vue'),
    },
    {
        path: '/terms/use',
        name: 'TermsOfUse',
        component: () => import('@/components/pages/terms/TermsOfUse.vue'),
    },
];

export default routes;
