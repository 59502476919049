import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { moduleA as ma, moduleAState } from './modules/moduleA.store';
import { moduleB as mb, moduleBState } from './modules/moduleB.store';
import { authModule as auth, AuthState } from "./modules/auth.store";
import { openModule as open, OpenState } from "./modules/open.store";
import { filterModule as filter, filterState } from "./modules/filter.store";
import {emailModule as email, EmailState} from "@/store/modules/email.store";
import {pageModule as page, pageState} from "@/store/modules/page.store";
import {memberModule as member, memberState} from "@/store/modules/member.store";
import {findModule as find, findState} from "@/store/modules/find.store";
import {nftModule as nft, NftState} from "@/store/modules/nft.store";
import {searchModule as search,searchState} from "@/store/modules/search.store";
import {basketModule as basket, BasketState,} from "@/store/modules/basket.store";
import {mypageModule as mypage, mypageState} from "@/store/modules/mypage.store";
import {profileModule as profile, ProfileState} from "@/store/modules/profile.store";
import { walletModule as wallet, WalletState } from "@/store/modules/wallet.store";
import {kaikasModule as kaikas, kaikasState} from "@/store/modules/kaikas.store";
import {cltnModule as cltn, CltnState} from "@/store/modules/cltn.store";
import {termsModule as terms , TermsState} from "@/store/modules/terms.store";
import {paymentModule as payment, PaymentState} from "@/store/modules/payment.store";
import {loadingModule as loading, LoadingState} from "@/store/modules/loading.store";

export interface RootState {
  ma: moduleAState,
  mb: moduleBState,
  auth: AuthState,
  open:OpenState,
  filter:filterState
  email:EmailState,
  page:pageState,
  member:memberState,
  find : findState,
  nft: NftState,
  search:searchState,
  basket: BasketState,
  mypage : mypageState,
  profile: ProfileState,
  wallet: WalletState
  kaikas:kaikasState,
  cltn:CltnState,
  terms:TermsState,
  payment:PaymentState
  loading: LoadingState,
}

export const key: InjectionKey<Store<RootState>> = Symbol()

export function useStore() {
  return baseUseStore(key)
}
export const store = createStore<RootState>({
  modules: {
    ma,
    mb,
    auth,
    open,
    filter,
    email,
    page,
    member,
    find,
    nft,
    search,
    basket,
    mypage,
    profile,
    wallet,
    kaikas,
    cltn,
    terms,
    payment,
    loading,
  }
})
