import {createI18n} from "vue-i18n";
import ko from './locales/ko';
import en from './locales/en';


const messages = {
    'ko': ko,
    'en': en,
}

export default createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages,
})
