import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/image/icons/close-x-icon-fill.svg'


const _hoisted_1 = { class: "input-row" }
const _hoisted_2 = ["disabled", "value", "placeholder", "type"]
const _hoisted_3 = {
  key: 1,
  class: "default-area"
}
const _hoisted_4 = {
  key: 2,
  class: "default-area"
}
const _hoisted_5 = {
  key: 3,
  class: "end-icon-area pointer-hover"
}
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.className),
    style: _normalizeStyle(_ctx.style)
  }, [
    (_ctx.topPlaceholder)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.topHolderClass),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focusInput && _ctx.focusInput(...args)))
        }, _toDisplayString(_ctx.topPlaceholder), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "start", { initInput: _ctx.initInput }),
      (_ctx.fixPlaceholder)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "fix-placeholder",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focusInput && _ctx.focusInput(...args)))
          }, _toDisplayString(_ctx.fixPlaceholder), 1))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        ref: "inputRef",
        disabled: _ctx.disabled,
        value: _ctx.modelValue,
        placeholder: _ctx.placeholder,
        type: _ctx.type,
        style: _normalizeStyle({
            'padding-top' : _ctx.topPlaceholder ? '11px' : 0
          }),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
        onInput: _cache[4] || (_cache[4] = ($event: any) => {_ctx.$emit('update:modelValue', $event.target.value); _ctx.$emit('change', $event.target.value); _ctx.onFieldChange();})
      }, null, 44, _hoisted_2),
      (_ctx.modelValue && _ctx.defaultIcon && _ctx.searchBar===false)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _imports_0,
              onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.initInput && _ctx.initInput(...args)), ["stop"])),
              class: "pointer-hover"
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.searchBar && _ctx.defaultIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("img", {
              src: _imports_0,
              onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.initSearchBarInput && _ctx.initSearchBarInput(...args)), ["stop"])),
              class: "pointer-hover"
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.endIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.endIcon,
              onClick: _cache[7] || (_cache[7] = ($event: any) => {_ctx.$emit('clickEndIcon');}),
              class: _normalizeClass(_ctx.endIconClass)
            }, null, 10, _hoisted_6)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "end", { initInput: _ctx.initInput })
    ])
  ], 6))
}