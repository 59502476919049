import {reactive, ref, h} from 'vue';
import {getNftApproveRequestApi, getNftInfoApi, getNftInfoPricesApi} from '@/api/nftApis';
import NftSaleConfigComp from '@/components/pages/nft/comp/sale/NftSaleConfigComp.vue';
import {BuyingAction, OfferAction, RegisterAction} from '@/models/types/types';

export const useNftModal = () => {
  const infoPriceData = ref();
  const infoPriceRequest: any = reactive({
    nftId: '',
    price: '',
  });
  const fetchInfoPrices = async (nftId: any, request: any) => {
    try {
      infoPriceData.value = await getNftInfoPricesApi(nftId, request);
    } catch (e) {
      infoPriceData.value = '';
    }
  };

  const checkPrice = async (nftId: any) => {
    await fetchInfoPrices(nftId, infoPriceRequest.price);
  };

  const tempThumnail = {
    icon: require('@/assets/image/icons/nft-buying-modal/profile.png'),
  };

  return {
    fetchInfoPrices,
    infoPriceData,
    infoPriceRequest,
    checkPrice,
    tempThumnail,
  };
};
export const useNftOfferToggle = () => {
  const nftOfferRef = ref();
  const openOfferModal = (
    nftId: string,
    state: OfferAction,
    priceUnit: any,
    ownerWalletAddress: any,
    detail:any
  ) => {
    nftOfferRef.value.openOfferModal(nftId, state, priceUnit, ownerWalletAddress,detail);
  };
  return {
    nftOfferRef,
    openOfferModal,
  };
};

export const useNftBuyToggle = () => {
  const nftBuyRef = ref();
  const openBuyingModal = (nftId: string, state: BuyingAction) => {
    nftBuyRef.value.openBuyingModal(nftId, state);
  };
  return {
    nftBuyRef,
    openBuyingModal,
  };
};

export const useNftSaleConfigToggle = () => {
  const nftSaleConfigRef = ref();
  const openSaleConfig = (nftId: string, state: RegisterAction) => {
    nftSaleConfigRef.value.openSaleConfig(nftId, state);
  };
  return {
    nftSaleConfigRef,
    openSaleConfig,
  };
};

export const useNftSaleModal = () => {
  const priceRequest: any = reactive({
    nftId: '',
    price: '',
  });

  const saleNftInfo = ref();
  const fetchSaleNftInfo = async (nftId: any) => {
    saleNftInfo.value = await getNftInfoApi(nftId);
  };

  return {
    priceRequest,
    fetchSaleNftInfo,
    saleNftInfo,
  };
};

export const useNftRegisterModal = () => {
  const infoRegisterPriceData = ref();
  const infoRegisterPriceRequest: any = reactive({
    nftId: '',
    price: '',
  });
  const fetchInfoPrices = async (nftId: any, request: any) => {
    try {
      infoRegisterPriceData.value = await getNftInfoPricesApi(nftId, request);
    } catch (e) {
      infoRegisterPriceData.value = '';
    }
  };
  const checkPrice = async (nftId: any) => {
    await fetchInfoPrices(nftId, infoRegisterPriceRequest.price);
  };

  const checkUpdatePrice = async (nftId: any) => {
    await fetchInfoPrices(nftId, infoRegisterPriceRequest.price);
  };
  const registerInfo = ref();
  const fetchRegisterInfo = async (nftId: any) => {
    registerInfo.value = await getNftInfoApi(nftId);
  };

  return {
    fetchInfoPrices,
    infoRegisterPriceData,
    infoRegisterPriceRequest,
    checkPrice,
    fetchRegisterInfo,
    registerInfo,
  };
};
