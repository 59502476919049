export default {
    common: {
        header: {
            signIn: 'Sign In',
            shoppingHover: {
                category: {
                    title: 'NFT Category',
                    curation: 'gaze Curation',
                    underTen: 'Under ￦100,000',
                    digitalArt: 'Digital Art',
                    memberTicket: 'Ticket/Membership',
                    wine: 'Wine',
                    finance: 'Finance'
                },
                priceUnit: {
                    title: 'Currency',
                    klay: 'KLAY',
                    matic: 'MATIC',
                    bottomTitle: 'SHOP Collection',
                    more: 'More'
                }
            },
            searchBar: {
                placeholder: 'Search NFTs, Collections, Users'
            },
            loggedInHoverMenu: {
                myNft: 'MY NFT',
                dashboard: 'Dashboard',
                paymentManagement: 'Manage payment',
                accountSetting: 'Account Setting',
                logout: 'Sign out'
            },
            mobile: {
                mobileSideBarMenu: {
                    nftName: 'Search NFT',
                    nftShopping: 'NFT SHOP',
                },
                mobileLoginSection: {
                    loginBtn: 'Sign In',
                    signUpBtn: 'Sign Up',
                    myNft: 'My NFT',
                    dashboard: 'Dashboard',
                    paymentManagement: 'Manage Payments',
                    accountSetting: 'Account Setting',
                    logout: 'Logout'
                },
                mobileNftSearchSection: {
                    cltn: 'Collections',
                    member: 'Members',
                    noResult: 'No search results found.',
                    allResult: 'See all '
                },
                mobileNftShoppingSection: {
                    category: {
                        title: 'NFT Category',
                        curation: 'gaze Curation',
                        underTen: 'Under ￦100,000',
                        wine: 'Wine',
                        digitalArt: 'Digital Art',
                        ticketMember: 'Ticket/Membership',
                        finance: 'Finance',
                    },
                    priceUnit: {
                        title: 'Trade Currency',
                    }

                }
            }
        },
        footer: {
            infoSection: {
                company: 'Guarantable co, Ltd.',
                ceo: 'CEO : Dongjoo Lee',
                policyAdmin: 'Personal info manager: Bonsung Gu',
                address: 'Address: 1016, Teratower 2-A, Songpadaero 201, Songpa-gu, Seoul',
                email: "Email : {email}",
                businessRegistrationNumber: 'Business Registration Number : 575-87-02196',
                copyRight: '© 2022 gaze. All Rights Reserved',
                termsConditions: 'Terms & Conditions',
                privacyPolicy: 'Privacy Policy',
                businessInfo: 'Check business information'
            },
            contactUs: {
                title: 'Contact us',
                contents1: 'Hello, thank you for your interest in gaze.',
                contents2: 'What are you curious about?',
                contents3: 'First, check the frequently asked questions',
                contents4: 'Go to Help & FAQ',
                contents5: 'If this still does not solve,',
                contents6: 'please contact our customer support team.',
                contents7: 'Contact customer support team',
            },
            aboutSection: {
                title: 'Where the Gaze stays,\nthe beginning of a new life',
                slide: {
                    finance: 'Finance',
                    wine: 'Wine',
                    ticket: 'Ticket',
                    membership: 'Membership',
                    bag: 'Bag',
                    jewelry: 'Jewelry',
                },
                desc: {
                    title1: 'Lifestyle',
                    title2: 'NFT',
                    title3: 'Commerce Platform',
                    contents1: '“Gaze 👀”, a space that catches the eyes of the heart so that anyone can',
                    contents2: ' create and enjoy the value they aspire to and imagine.',
                },
                ourMission: {
                    imagine: {
                        title1: 'Make your',
                        title2: 'imagination',
                        title3: 'come true.'
                    },
                    mingle: {
                        title1: 'Socialize with',
                        title2: 'people with',
                        title3: 'similar interest.'
                    },
                    keep: {
                        title1: 'Store safely',
                        title2: ''
                    },
                    mobile: {
                        imagine: {
                            text1: 'Make your imagination\ncome true.',
                            text2: ''
                        },
                        mingle: {
                            text1: 'Socialize with people with\nsimilar interest.',
                            text2: ''
                        }
                    }
                },
                descTwoSection: {
                    // You can safely buy and sell by recording valuable products and moments of experience in your daily life.
                    //     where you can purchase, transfer, or sell various services and products you use in your daily life.
                    engage: {
                        text1: 'You can safely buy and sell by recording valuable products and moments of experience',
                        text2: 'in your daily life.',
                        text3: 'where you can purchase, transfer, or sell various services and products you use in your',
                        text4: 'daily life.'
                    },

                    mingle: {
                        text1: 'We provide a social life based on topics of interest to enjoy and',
                        text2: 'communicate with members with similar interests,',
                        text3: 'Real-time SNS peed that can exchange NFT trends among members',
                        text4: 'A reward-based community that communicates with members on topics of interest',
                    },
                    keep: {
                        text1: 'Your collection of products and services, and all information of transactions with others',
                        text2: 'are safely stored through the certified gaze blockchain technology.',
                        text3: 'Relief technology that guarantees the safety of all assets and transactions',

                    }
                },

                descThreeSection: {
                    text1: 'gaze is cultivating a new life culture to regain initiative in various areas of life by',
                    text2: 'making all members become participants through blockchain technology and',
                    text3: 'independently create products and content.',
                }

            }
        },
        filter: {
            priceUnit: [
                {
                    unit: 'All Coins',
                },
                {
                    unit: 'KLAY',
                },
                {
                    unit: 'MATIC',
                },
            ],
            statusList: [
                {
                    status: 'All Status',
                },
                {
                    status: 'Not for sale',
                },
                {
                    status: 'For sale',
                },

            ],
            nftSortOptions: [
                {
                    option: 'Most Recent',
                },
                {
                    option: 'Most Popular',
                },
                {
                    option: 'Least Popular',
                },
                {
                    option: 'Price : high to row',
                },
                {
                    option: 'Price : row to high'
                },
            ],
            searchNft: 'Search NFT',
            searchPrice: {
                title: 'Price',
                maxPrice: 'Max price *',
                minPrice: 'Minimum price *',
                applyBtn: 'Apply'
            },
            chrtNft: 'NFT Character',
            mobile: {
                chrtNft: {
                    title: 'NFT Character',
                    filter: 'NFT Character filter',
                    complete: 'OK',
                    allReset: 'All reset',
                    search: 'NFT search'
                }
            },
            total: 'Total',
            count: '',
            onlySale: 'Buy now'
        },
        nftCardComp: {
            leftDay: ' left',
            about: ' ',
            saleBtn: 'Buy now',
            unSaleBtn: 'Not for sale',
            noPrice: 'No sale',
            highOfferPrice: 'Maximum Offer Price',
        },
        datePicker: {
            placeholder: 'Select date',
            locale: 'en',
            close: 'Cancel',
            ok: 'OK'
        },
        metamask: 'Metamask',
        kaikas: 'Kaikas'
    },
    home: {
        first: {
            title1: 'Caught',
            title2: 'the eye',
            title3: 'NFT.',
            contents1: 'Own your valuable NFT products',
            contents2: 'and enjoy NFT benefits.',
            buttonText: 'Explore NFTs',
        },
        nftCarouselView: {
            titleContents: 'ALL NFT Items',
            leftDay: ' day',
            about: ' ',
            saleBtn: 'Buy now',
            unSaleBtn: 'Not for sale'
        },
        onboardingView: {
            title: 'First time with NFT?',
            card1: {
                title: 'Prepare\nPayment Currency',
                contents1: 'You need a coin',
                contents2: 'as trade currency.',
                buttonText: 'How to Buy Coins'
            },
            card2: {
                title: 'Connect\nPayment Methods',
                contents1: 'You need a wallet',
                contents2: 'to hold a coin',
                buttonText: 'How to Get Wallets'
            },
            card3: {
                title: 'Buy\nNFTs',
                contents1: 'Sign',
                contents2: 'to buy NFTs',
                buttonText: 'How to buy NFTs'
            },
            card4: {
                title1: 'Buy Now',
                title2: 'NFTs',
            },
        },
        membersView: {
            moreBtn: 'More',
            mobile: {
                moreBtn: 'See all collectors'
            }
        },
        trendingTags: {
            comingUp: 'Hot trend',
            new: 'new',
            wine: 'Wine',
            best: 'best',
            membership: 'Membership',
            collectors: 'collectors',
            klay: 'KLAY',
            aboutGaze: 'about gaze',
            popularCollection: 'Popular Collection',
            nft: 'NFT',
            matic: 'MATIC'
        },
        mobile: {
            first: {
                title1: 'Caught the eye',
                title2: 'NFT.',
                contents1: 'Own your valuable NFT products and enjoy',
                contents2: 'NFT benefits.',
            }
        }
    },

    listView: {
        nftList: {
            title: 'NFT',
            bannerSection: {
                leftSection: {
                    title1: 'Caught',
                    title2: 'the eye NFT',
                },
                rightSection: {
                    purchase: 'Purchase',
                    forSale: 'For Sale',
                    resell: 'Resell'
                },
            },
            filterSection: {
                nftFilter: {
                    cardFilterList: [
                        {
                            title: 'gaze Curation'
                        },
                        {
                            title: 'Most Popular'
                        },
                        {
                            title: 'Under ￦100,000'
                        },
                        {
                            title: 'Wine'
                        },
                        {
                            title: 'Ticket /Membership'
                        },
                        {
                            title: 'Digital art'
                        },
                        {
                            title: 'Music'
                        },
                        {
                            title: 'Finance'
                        },
                    ],
                    nftSortOptions: {
                        mostRecent: 'Most Recent',
                        mostPopular: 'Most Popular',
                        leastPopular: 'Least Popular',
                        highPrice: 'Price : high to row',
                        rowPrice: 'Price : row to high',
                        totalCount: ''
                    }

                },
                cltnFilter: {
                    searchCltn: 'Search Collections',
                    cltnSortOptions: {
                        mostRecent: 'Most Recent',
                        mostPopular: 'Most Popular',
                        leastPopular: 'Least Popular',
                        highNth: 'Volume : high to row',
                        rowNth: 'Volume : row to high'
                    },
                },
                memberFilter: {
                    searchMember: 'Search Users'
                },
            },
            emptySection: {
                nft: {
                    title: 'Nothing found.',
                    btnText: 'Find trending NFTs in gaze'
                },
                cltn: {
                    title: 'Nothing found.',
                    btnText: 'Find trending Collenction in gaze'
                },
                member: {
                    title: 'Nothing found.',
                    btnText: 'Find trending Members in gaze'
                }
            }

        },
        collectionList: {
            title: 'Collection'
        },
        memberList: {
            title: 'Member'
        }
    },
    nftDetailView: {
        publicNo: 'Non-disclosure Product',
        explain: {
            title: 'Description'
        },
        chrtInfo: {
            title: 'Filters'
        },
        benefitDetail: {
            title: 'Benefits',
            benefit: 'Benefit'
        },
        blockInfo: {
            title: 'Details',
            tksd: 'Token Standard',
            blockChainNetwork: 'Blockchain Network',
            creatorLoyalty: 'Creator Loyalty'
        },
        cltnInfo: {
            title: 'Collection Information',
            nftCount: 'Items',
            owner: 'Owner',
            volume: 'Volume',
            leastPrice: 'Lowest Price'
        },
        otherNftList: {
            title: 'More from this collection'
        },
        priceInfo: {
            title: '',
            owner: 'Owner',
            price: 'Price',
            highOfferPrice: 'Top Offer',
            lastSalePrice: 'Last sale price',
            deadline: 'Sale ends in',
            deadlineDays: 'days',
            buyBtn1: '',
            buyBtn2: 'Buy now for ',
            unSaleBtn: 'Not for sale',
            updateBtn: 'Modify for sale',
            registerBtn: 'Register for sale',
            registerCancelBtn: 'Cancel for sale',
            offerBtn: 'Price Offer',
            offerCancelBtn: 'Price Offer Cancel',

        },
        historySection: {
            title: 'History',
            filter: {
                transferType: {
                    register: 'Register',
                    offer: 'Offer',
                    modify: 'Modify',
                    transfer: 'Wallet transfer'
                },
                applyBtn: 'Apply',
                cancelBtn: 'Cancel'
            },
            timeline: {
                buy: 'NFT Purchase on ',
                first: 'First Register ',
                register: 'Register for sale ',
                transfer: 'NFT wallet transfer',
                offer: 'Price Offer on ',
                accept: 'Offer Accept on ',
                reject: 'Offer Reject on ',
                offerCancel: 'Offer Cancel on ',
                cancel: 'Register Cancel',
                modify: 'Modify Price ',
                more: 'More',
                empty: 'There is no history.'
            },
        },
        mobile: {
            tab: {
                details: 'Details',
                benefits: 'Benefits',
                history: 'History'
            },
            footer: {
                cancel: 'Register Cancel',
                update: 'Modify for sale',
                register: 'Register for sale',
                unSale: 'Not for sale',
                offer: 'Price Offer',
                offerCancel: 'Price Offer Cancel',
                buy: 'Buy now'
            }
        }
    },
    cltnDetailView: {
        title: 'Collection Information',
        nftCount: 'Items',
        owner: 'Owner',
        volume: 'Volume',
        leastPrice: 'Lowest Price',
        nftUtilGuide: 'NFT Utility Claim Guide',
        empty: 'Not Found.',
        mobile: {
            nftChrtFilter: ''
        }
    },
    modal: {
        nftBuy: {
            title: 'Checkout',
            subTitle: 'NFT Purchase Info',
            owner: 'Owner',
            amount: 'NFT price',
            serviceFee: 'Platform Fee',
            totalAmount: 'You will pay',
            buyBtnText1: 'You will pay ',
            buyBtnText2: '',
            mobile: {
                title: 'NFT Purchase Info.'
            }
        },
        multiNftBuy: {
            title: 'Checkout',
            subtitle: 'NFT Purchase Info',
            total: 'Total',
            count: '',
            owner: 'Owner',
            nftPrice: 'NFT price',
            totalPrice: 'Total Price',
            buyBtnText1: 'You will pay ',
            buyBtnText2: '',
            mobile: {
                title: 'NFT Purchase Info'
            }
        },
        nftRegister: {
            title: 'Register for sale',
            subtitle: 'List NFT for sale',
            currentPrice: 'Purchased Price',
            price: 'Price',
            canOffer: 'Let customers make offers',
            platformFee: 'Platform Fee',
            creatorLoyalty: 'Creator Loyalty',
            totalAmount: 'Total',
            setSalePeriod: {
                title: 'Duration',
                none: 'No set',
                oneDay: '1 Day',
                twoDay: '2 Days',
                oneWeek: '1 Week',
                sixMonth: '6 months'
            },
            mobile: {
                setSalePeriod: {
                    subtitle: 'Set Period',
                },
                dayOptions: [
                    {
                        period: 'No period'
                    },
                    {
                        period: '1 day'
                    },
                    {
                        period: '2 day'
                    },
                    {
                        period: '1 week'
                    },
                    {
                        period: '6 months'
                    },
                ]
            },
            saleRegisterBtn: 'Register for sale',
            failRegister:'Sales registration failed',
        },
        nftModify: {
            title: 'Modify for sale',
            subtitle: 'Modify NFT sales information',
            nowPrice: 'Current Price',
            saleModifyBtn: 'Modify for sale'
        },
        nftOffer: {
            title: 'NFT Price Offer',
            subtitle: 'Offer a price',
            nowPrice: 'Current selling price',
            hopePrice: 'Desired amount',
            notice: {
                title: 'Please verify before making a price offer.',
                text1: 'When making a price proposal, the proposed amount will be pre-authorized, and if the proposal is directly canceled or rejected (owner rejects the proposal, proposal period expires), the payment will be canceled and refunded to the wallet within a maximum of 15 minutes.',
                text2: 'When making a price proposal, the proposed amount will be pre-authorized. If the proposal is canceled or rejected directly (such as the owner rejecting the proposal or the proposal period expiring), the payment will be canceled, and a refund will be processed to the wallet within a maximum of 15 minutes.'
            },
            offerBtn: 'Submit a sales offer',
            mobile: {
                subtitle: 'NFT Offer Info'
            },
        },
        signModal: {
            title: 'Sign',
            desc: 'Request a price proposal.\nPlease complete all steps by proceeding to sign.',
            buy: {
                title: 'NFT Purchase',
                pendingTitle: 'Delayed Purchase Notification',
                pendingContents: 'Purchase'
            },
            offer: {
                title: 'NFT Offer',
                pendingTitle: 'Delayed Offer Notification',
                pendingContents: 'Offer'
            },
            saleCancel: {
                title: 'Sale Cancellation'
            },
            signBtnText: 'Sign',
            signReason: 'Why do we need to sign?'
        },
        pendingModal: {
            ko: {
                subtitle1: '',
                subtitle2: '',
                subtitle3: '',
                notice1: '',
                notice2: ''
            },
            en: {
                subtitle1: ' is currently pending due to blockchain network delays',
                notice1: 'Depending on the network conditions, it may take up to 10 minutes.',
                notice2: 'You can check the real-time status of your ',
                notice3: 'processing.'
            },
            waitBtn: 'Shopping',
            checkBtn: 'Status',
            whyPending: 'The reason for purchase delays is?'
        },
        confirmModal: {
            success: {
                offer: 'Your offer has been successfully completed.',
                offerCancel: 'Your offer has been successfully canceled.',
                reject: 'Your offer has been successfully rejected.',
                offerAccept: 'Your offer has been successfully approved.',
                register: 'Your listing has been successfully completed.',
                update: 'Your listing has been successfully changed.',
                cancel: 'Your listing has been successfully canceled..',
                buy: 'Your purchase has been successful.'
            },
            fail: {
                // 제안
                offer: 'Your offer has failed.',
                offerReason: 'Why your offer failed?',

                offerCancel: 'The cancellation of the offer has failed.',
                offerCancelQaRouter: 'Why did the cancellation of the offer fail?',

                offerReject: 'The offer rejection has failed.',
                offerRejectQaRouter: 'Why did the rejection of the offer fail?',

                offerAccept: 'The offer approval has failed.',
                offerAcceptQaRouter: 'Why did the approval of the offer fail?',
                // 판매
                register: 'Your listing has failed.',
                registerQaRouter: 'Why did the listing fail?',

                update: 'The modification for sale failed.',
                updateQaRouter: 'Why did the modification fail?',

                cancel: 'The cancellation for sale has failed.',
                cancelQaRouter: 'Why did the cancellation for sale fail?',

                // 구매
                buy: 'Your purchase has failed.',
                buyQaRouter: 'Why your purchase failed?',

                // 공통
                buyFail: 'Your purchase has failed.'
            },
            openModal: {
                previousRequest: 'The previous request is being processed.',
                anotherWallet: 'It is a wallet address registered to another account.',
                noActive: 'It is not an active proposal.',
                noSaleAnotherWallet: 'You cannot make a purchase with a wallet registered to another account.',
                consecutiveNum: '연속된 4자리 숫자 or 문자 비밀번호',
                notYourOwnNFT: 'You cannot add your own NFT to the shopping cart.',
                notYourOwnWallet: {
                    title: 'It is not the wallet that owns the NFT.',
                    contents: 'Please sign with the wallet that owns the NFT.'
                },
                failSaleCancel: 'Failed to cancel the sale.',
                notYourOwnOffer: {
                    title: 'It is not the wallet that made the offer',
                    contents: 'Please sign with the wallet that made the offer'
                },
                over1Won: 'Please enter an amount greater than 1 won',
                over0Won: 'Please enter an amount greater than 0 won',
                notForSale: 'Not For Sale'
            },
            sale: {
                register: 'Your listing has been successfully completed',
                update: 'Your listing has been successfully changed',
                cancel: 'Your listing has been successfully stopped',
                buy: 'Your purchase has been successful'
            },
            check: 'OK'
        },
        walletInstallModal: {
            pc: {
                title: ' wallet has not been installed yet.',
                desc1: '',
                desc2: '',
                english: {
                    // The MetaMask wallet has not been installed yet.
                    text1: 'The',
                    text2: 'wallet has not been installed yet.',
                    text3: 'Chrome extension installation is required.',
                    text4: 'Please refresh the page after installation.'
                }
            },

            mobile: {
                title: 'Wallet installation',
                title2: 'Payment process',
                desc1: 'Mobile ',
                desc2: ' You need the',
                contents: {
                    title: '',
                    text1: '',
                    text2: '',
                    text3: '',
                    english: {}
                },
                ok: 'OK',
                install: 'Install',
                move: 'OK',
                cancel: 'Cancel',
                en: {
                    contents: {
                        title: 'Navigate to the ',
                        text1: 'To proceed on mobile,',
                        text2: ' you need to sign the transaction using the ',
                        text3: 'Would you like me to go to the ',
                        question: '?'
                    },
                }
            },

        },
        warningPopup: {
            // modal.warningPopup.isMyNft
            isMyNft: 'You cannot buy your own NFT.',
            connectMetamask: 'Please connect the MetaMask wallet.',
            signDiscontinued: 'The signature has been discontinued.',
            signTerminated: 'The signature has been terminated.',
            notSale: 'The product is not available for sale.'
        },
        loginAlertPopup: {
            title: 'This is a service that requires login. ',
            contents: 'You will be redirected to the login page.',
            ok: 'OK'
        },
    },
    login: {
        title: 'Sign In',
        email: 'Email Address *',
        password: 'Password *',
        loginBtnText: 'Sign In',
        forgotPassword: 'Forgot password?',
        notYetGaze: `Don't have a gaze account yet?`,
        signup: 'Sign up',
        notExactly: 'It doesn’t match account info you\nentered. Please check again.',
        unverifiedPage: {
            title: 'Please complete account verification.',
            contents: 'The verification code has been resent to your registered\ne-mail address.',
            email: '',
            question: 'Have you not received the sign-up verification e-mail yet?',
            resend: '\nResend authentication code',
            btnText: 'OK'
        },
        unverifiedModal: {
            title: 'The authentication code has been re-sent.',
            ok: 'OK',
            cancel: 'CANCEL'
        },
        waitVerified: {
            titleSignup: 'Please complete your signup to\nverify your email.',
            titlePassword: 'Please complete your change password to\nverify your password.',
            subtitle: 'We sent an email to your email address.',
            subtitle2: 'Please complete the authentication to activate your account. The link will expire in 24 hours.',
            subtitle3: 'Please complete the authentication to finalize the password change.',
            subtitle4: 'The link will expire in 24 hours.',
            waitEmail: 'You’re almost there. Verifying the email…'
        },
        verified: {
            title: 'Welcome to be a\ngaze member 🙌',
            contents: 'Email Verification Completed!',
            loginBtn: 'Go to Sign In'
        },
        expired: {
            title: 'The verification link already\nhas expired.',
            contents: {
                text1: 'The link has already expired, you might need to resend the link.',
                text2: 'Click on the button below to resend the link and verify your email.'
            },
            resendBtn: 'Resend the verification link',
            notReceiveVerifyEmail: 'Haven’t received sign up verification email yet?',
            notReceivePasswordEmail: 'Haven’t received password verification email yet?',
            invisibleEmail: 'Please check the spam mailbox if you have not received the email.'
        },
        mobile: {
            unverifiedPage: {
                title: '',
                contents: 'The verification code has been resent to your\nregistered e-mail address.',
                question1: 'Have you not received the sign-up',
                question2: 'verification e-mail yet?',
            },
        }
    },
    signup: {
        title: 'Sign Up',
        email: 'Email Address *',
        nickname: 'Nickname *',
        password: 'Password *',
        passwordCheck: 'Confirm Password *',
        terms: {
            service: '[Required] Agree to Terms and Conditions',
            personal: '[Required] Agree to Privacy Policy',
            marketing: '[Optional] Agree to Marketing Consent'
        },
        lang: {
            korean: 'Korean',
            english: 'English',
        },
        signupBtn: 'Sign Up',
        alreadyAccount: 'Already have a gaze account?',
        loginBtn: 'Sign In'


    },
    findPassword: {
        title: 'Please type the e-mail you\nregistered when signing up to\nreset your password.',
        email: 'Email Address *',
        emailBtn: 'Send Email',
        signupBtn: 'Sign up',
        loginBtn: 'Sign In',
        mobile: {
            title: 'Password change\nhas been completed.',
            loginBtn: 'Go to login'
        }
    },
    resetPassword: {
        "title": "Please reset your password.",
        "subtitle": "Please check the password input rules.",
        "contents1": "* Please change your password to avoid common pitfalls such as consecutive numbers, birthdays, or phone numbers.",
        "contents2": "* Please enter a password with 8 to 20 characters, including English letters, numbers, and special symbols.",
        "password": "Enter Password *",
        "passwordConfirm": "Confirm Password *",
        "passwordResetBtn": "Reset Password"
    },
    waitPassword: {
        title: 'Password reset link has been sent to\nthe e-mail address below.',
        email: 'Email Address'
    },
    myPage: {
        profileEdit: 'Edit profile',
        navigator: {
            haveNft: 'Have NFT',
            itrtNft: 'Liked NFT',
            itrtCltn: 'Liked Collentions'
        },
        nftCard: {
            shareSelect: {
                linkCopy: 'Copy Link',
                twitter: 'Share Twitter',
                facebook: 'Share Facebook'
            },
            shareBtn: 'Share',
            registerCancel: 'Cancel listing for sale',
            engLeftDay: 'Sale ends in ',
            leftDay: 'days',
            noPrice: 'No sale',
            highOfferPrice: 'Top offer',
            buyBtn: 'Buy now',
            emptyNftList: 'Not found',
            unSaleBtn: 'Not for sale',
            updateBtn: 'Modify for sale',
            registerBtn: 'Register for sale',
            registerCancelBtn: 'Cancel for sale',
        },
        cltnCard: {
            nftCount: 'Itmes',
            owner: 'Owner',
            volume: 'Volume',
            lowestPrice: 'Lowest Price',
            storePage: 'Store Page',
            emptyCltnList: 'Nothing found.'
        }
    },
    dashboard: {
        sttc: {
            title: 'Dashboard',
            subtitle: 'My asset',
            explain: 'You currently hold this much coins.',
            totalExplain: 'We calculated the holding value based on the current market price.',
            klay: 'KLAY',
            matic: 'MATIC',
            myNft: 'My NFT',
            myNftExplain: 'You currently hold this much NFT and are in the process of trading.',
            totalHaveNft: 'Total Owned NFTs',
            saleNFt: 'For sales NFTs',
            offerCount: 'Offers Made Count',
            getOfferCount: 'Offers Received Count',
            count: 'Count'
        },
        walletManage: {
            title: 'NFT Status',
            explain: 'Register your wallet and collect your NFT transaction status in one place.',
            subtitle1: 'Total ',
            subtitle2: '',
            subtitle3: 'wallets have connected.',
            managePayment: 'Manage Payments',
            wallet: {
                kaikas: 'Kaikas',
                metamask: 'MetaMask',
                register: 'Register Wallet',
                failTitle: 'Failed to register the payment method.',
                failContents: 'The payment method was not registered successfully.',
                reRegister: 'Please register again.',
                alreadyRegisterTitle: 'Already Registered',
                alreadyRegister: 'This wallet is already registered to another account.',
                alreadyRegisterSolve: 'How to revise if the wallet is already registered?',
                ok: 'OK'
            },
            mobile: {
                subtitle: 'wallets have connected'
            }
        },
        trstList: {
            navigator: {
                haveNft: 'NFTs Owned',
                offerNft: 'NFTs on offer',
                history: 'Trading history',
                filter: {
                    sortOptions: [
                        {
                            option: 'Most Recent'
                        },
                        {
                            option: 'Most Popular'
                        },
                        {
                            option: 'Least Popular'
                        },
                        {
                            option: 'Price : high to row'
                        },
                        {
                            option: 'Price : row to high'
                        },
                    ],
                    statusOptions: [
                        {
                            option: 'All currency'
                        },
                        {
                            option: 'KLAY'
                        },
                        {
                            option: 'MATIC'
                        },
                    ]
                }

            }
        },
        emptyComp: {
            text1: 'There is no journey with Gaze yet.',
            text2: 'Check the hottest products at Gaze now!'
        },
        searchEmptyComp: {
            nothing: 'Nothing Found.'
        },
        priceName: {
            default: 'Price',
            TRANSFER: 'Purchase Price',
            REGISTER: 'Sale Listing Price',
            MODIFY: 'Modified Price',
            CANCEL: 'Cancellation Price',
            ACCEPT: 'Accepted Offer Price',
            OFFER: 'Offer Price',
            OFFER_CANCEL: 'Offer Cancellation Price',
            REJECT: 'Rejected Offer Price',
        },
        buttonName: {
            REGISTER: 'Register for sale',
            MODIFY: 'Modify for sale',
            CANCEL: 'Cancel for sale',
            OFFER_CANCEL: 'Offer cancellation',
            REJECT: 'Offer rejection',
            ACCEPT: 'Offer accept',
        }

    },
    paymentManage: {
        title: 'Manage payment',
        subtitle: 'Register your wallet to buy NFT.',
        notHaveWallet: 'Don\'t have a blockchain wallet?',
        addWallet: 'Register additional wallets',
        copy: 'Copied!',
        changeWalletNick: 'Change Wallet Nickname',
        deleteWallet: 'Delete wallet',
        success: {
            title: 'Shall we register the next wallet?',
            subtitle: 'When you change the account in your wallet, the address information will be updated.',
            error: 'Failed to retrieve the wallet address. Please refresh and try again.',
            register: 'Register',
            cancel: 'Cancel'
        },
        activeWallet: 'The active account has been changed.',
        beforeDeleteWallet: {
            deleteCopleteWallet: 'Wallet deletion is complete.',
            remainProduct: 'There is a product on sale.',
            representativeWallet: 'You cannot delete a representative wallet.'
        },
        rejectSign: 'Signature refused.'
    },
    saleConfigComp: {
        register: {
            title: 'Register for sale'
        },
        update: {
            title: 'Modify for sale'
        },
    },
    userConfig: {
        title: 'Account Setting',
        profileInfo: 'Profile Info',
        individualInfo: 'Personal Info',
        uploadImg: "Upload Photo",
        deleteImg: 'Delete Photo',
        nickname: 'Nickname',
        nicknameChange: 'Change',
        nickPlaceholder: 'New nickname',
        change: 'Change',
        cancel: 'Cancel',
        intro: 'Blo',
        connectSns: {
            title: 'Social Links',
            subtitle: 'Add on social accounts and inform yourself',
            insta: 'Instagram',
            facebook: 'Facebook',
            twitter: 'Twitter',
            youtube: 'Youtube',
            discord: 'Discord'
        },
        profileUpdateBtn: 'Update profile information',
        undefinedURL: '주소 형식이 맞지 않아요.',
        accounts: 'account',
        indv: {
            email: 'Email address',
            password: 'Password',
            passwordChange: 'Change',
            changedNickname: 'It has been changed.',
            nowPwdInput: 'Current Password *',
            willChangePwdInput: 'New Password *',
            willChangePwdInputCheck: 'Confirm Password *',
            cancel: 'Cancel',
            change: 'Change'
        }

    },
    basket: {
        title: 'Cart',
        total: 'Total ',
        count: '',
        allDelete: 'Remove all',
        buyNft1: 'Buy ',
        buyNft2: ' NFT',
        buyAll: 'Only NFT of the same currency can be purchased at one time.',
        empty: {
            text1: 'Add NFT to your cart.',
            text2: ''
        },
        cardComp: {
            saleImpossible: 'Currently not available',
            remove: 'Remove'
        }
    },
    paymentComplete: {
        success: {
            title: 'NFT purchase complete',
            carouselComp: {
                pc: {
                    total: 'total',
                    count: '',
                    title: 'You have completed a ',
                    subtitle1: 'NFT purchase.',
                    subtitle2: '',
                    subtitle3: '',
                },
                mobile: {
                    total: 'Total',
                    subtitle1: '',
                    subtitle2: 'You have completed your purchase of NFTs',
                }

            },
            moreShoppingBtn: 'Shop More',
            goToListBtn: 'Purchase list'

        },
        multiSuccess: {
            success: {
                title: 'NFT purchase complete',
                someSuccess: 'Some NFT purchases completed',
                mobileSomeSuccess: 'Some NFT\npurchases completed\n'
            },
            fail: {
                title: 'NFT Purchase failed.',
                againPurchase: 'Do you try to purchase again?',
                whyFailPurchase: 'Why does NFT purchase fail and how to fix it?'
            },
            moreShoppingBtn: 'Shop more',
            goToListBtn: 'Purchase list',
            againPurchaseBtn: 'Purchase again'
        },
        failCarouselComp: {
            pc: {
                total: 'Total ',
                count: '',
                subtitle1: 'Failed to purchase',
                subtitle2: '',
                subtitle3: '',
                warningText: 'The NFT payment amount of the failed purchase is automatically refunded to your personal wallet after 15 minutes.'
            },
            mobile: {
                total: 'Total',
                count: '',
                title: '',
                subtitle: 'Failed to purchase',
                warningText: 'The NFT payment amount of the failed purchase is automatically refunded to your personal wallet after 15 minutes..'
            }
        },
        fail: {
            title: 'NFT Purchase failed.',
            againPurchase: 'Do you try to purchase again?',
            whyFailPurchase: 'Why does NFT purchase fail and how to fix it?',
            moreShoppingBtn: 'Shop more',
            goToListBtn: 'Purchase list',
            againPurchaseBtn: 'Purchase again'
        }
    },


    dashFilter: {
        SALE: 'For sale',
        UNSALE: 'Not for sale',
        OFFER_SEND: 'Offer request',
        OFFER_RECEIVE: 'Offer received',
        SELL: 'Sale',
        BUY: 'Purchase',
        ACCEPT: 'Offer accept',
        REJECT: 'Offer rejection',
        MODIFY: 'MODIFY',
        OFFER_CANCEL: 'Offer cancellation',
        TRANSFER: 'Purchase',
        REGISTER: 'Sale',
        CANCEL: 'Cancellation of sale',
        allReset: 'All reset'
    },
    nftStatusFilter: {
        SALE: 'For sale',
        UNSALE: 'Not for sale',
        OFFER_SEND: 'Offer request',
        OFFER_RECEIVE: 'Offer received',
        SELL: 'Sale',
        BUY: 'Purchase',
        ACCEPT: 'Offer accept',
        REJECT: 'Offer rejection',
        MODIFY: 'Price modification',
        OFFER_CANCEL: 'Offer cancellation',
        TRANSFER: 'Purchase',
        REGISTER: 'Sale listing',
        CANCEL: 'Cancellation of sale',
        OFFER: 'Offer'
    },
    historyFilter: {
        SALE: 'For sale',
        UNSALE: 'Not for sale',
        OFFER_SEND: 'Offer request',
        OFFER_RECEIVE: 'Offer received',
        SELL: 'Sale',
        BUY: 'Purchase',
        ACCEPT: 'Offer accept',
        REJECT: 'Offer rejection',
        MODIFY: 'Price modification',
        OFFER_CANCEL: 'Offer cancellation',
        TRANSFER: 'Purchase',
        REGISTER: 'Sale listing',
        CANCEL: 'Cancellation of sale',
        OFFER: 'Offer'
    },
    nftResultGroup: {
        SUCCESS: 'Success',
        FAIL: 'Fail',
        PENDING: 'Pending',
        default: ''
    },
    wallet: {
        kaikas: 'Kaikas',
        metamask: 'MetaMask'
    },
    systemPage: {
        check: {
            title: 'Checking the system',
            contents: 'The system is being checked to provide more reliable service.\n' +
                'Since the entire service is stopped,\n' +
                'so please understand the entire service.',
            date: 'Date '
        },
        connect: {
            title: 'Connection Error',
            contents: 'The page you requested cannot be accessed due to a temporary error.\n' +
                'Please log back on in a moment.\n\n' +
                'If the same problem continues to occur or if you have any questions,\n' +
                'please contact the customer service center.'
        },
        error: {
            title: 'System Error',
            contents: 'The page you requested cannot be accessed due to a temporary error.\n' +
                'Please log back on in a moment.\n\n' +
                'If the same problem continues to occur or if you have any questions,\n' +
                'please contact the customer service center.'
        },
        contactBtn: 'Contact Customer Center'
    },


    rules: {
        required: 'It is a required item.',
        emailRequired: 'Please enter a email.',
        passwordRequired: 'Please enter a password.',
        nicknameRequired: 'Please enter a nickname.',
        notExist: 'It doesn’t match account info you entered. Please check again.',
        emailFormat: 'Invalid email format',
        password: 'Enter 8 to 20 digits, including all letters, numbers, and symbols.',
        snsLink: 'The address format is not correct.',
        alreadyUse: 'The username is already in use.',
        alreadyEmail: 'The email is already registered.',
        beforeNickname: 'It\'s the same as the existing nickname.',
        contiNumberSentence: 'Sequential numbers or characters are not allowed.',
        nicknameCheck: 'Please enter between 1 and 50 characters.',
        confirmPwd: 'The passwords do not match.'
    },

}