<template>
  <div v-if="isMetamaskMobile()" class="gaze-buying-multi-nft-modal">
    <gaze-modal :visible="isOpen" @close="$emit('closeModal')" :title="$t('modal.multiNftBuy.title')">
      <template #content>
        <div class="gaze-buying-multi-nft-modal-section metamask-modal-contents">
          <div class="mobile-gaze-modal-text mobile-only">
            <div class="mobile-gaze-modal-text-desc">
             {{ $t('modal.multiNftBuy.mobile.title') }}
            </div>
          </div>
          <div class="buying-multi-wrapper">
            <div class="gaze-buying-multi-modal-title">
              <div>
                {{ $t('modal.multiNftBuy.subtitle') }} ({{ $t('modal.multiNftBuy.total') }} {{ nftList.length }}{{ $t('modal.multiNftBuy.count') }})
              </div>
            </div>
            <div class="buying-multi-info-area">
              <div class="buying-multi-info-section" v-for="(card,index) in  nftList" :key="index">

                <div class="buying-multi-info-img">
                  <nft-image-or-video-small :nft="card"></nft-image-or-video-small>
                </div>
                <div class="buying-multi-info-desc">
                  <a-tooltip :title="card.nftName">
                    <div class="buying-multi-info-name">{{ card.nftName }}</div>
                  </a-tooltip>

                  <div class="buying-multi-info-owner-section">
                    <div class="buying-multi-info-owner-wrapper">
                      <div class="buying-multi-info-owner">{{ $t('modal.multiNftBuy.owner') }}</div>
                      <div style="display: flex; align-items: center;gap: 3px;">
                        <div
                            :class="card.userProfileImg ? 'buying-multi-info-owner-img' : 'buying-multi-info-owner-img border-none'">
                          <img :src="card.userProfileImg ?card.userProfileImg : tempProfile.default" alt="">
                        </div>
                        <div class="buying-multi-info-owner-name">{{ card.userName }}</div>
                      </div>

                    </div>

                    <div class="buying-multi-info-owner-price-top-level">
                      <div class="buying-multi-info-owner-price-section">
                        <div class="buying-multi-info-owner-price">
                          <div class="buying-multi-info-owner-price-img">
                            <img :src="getCoinIcon(card.priceUnit)" alt="">
                          </div>
                          <div class="buying-multi-info-owner-price-amount">
                            {{ card.price ? card.price.toLocaleString('ko') : '-' }}
                            {{ card.priceUnit ? card.priceUnit : '-' }}
                          </div>
                        </div>
                        <div class="buying-multi-info-owner-price-won">
                          (￦{{ card.krwPrice ? card.krwPrice.toLocaleString('ko') : '-' }})
                        </div>
                      </div>
                    </div>


                  </div>


                </div>

              </div>
            </div>
            <!-- 결제 수단 선택 영영       -->
            <div class="buying-multi-result-section">
              <div class="buying-multi-result-nft-price-section">
                <div class="result-fs-16-regular">{{ $t('modal.multiNftBuy.nftPrice') }}</div>
                <div class="result-fs-18-bold">{{ paySumInfo.sum }}
                  {{ nftList.length > 0 ? nftList[0].priceUnit : 'KLAY' }}
                </div>
              </div>
              <!--          <div class="buying-multi-result-service-fee-section">-->
              <!--            <div class="result-fs-16-regular">서비스수수료 0%</div>-->
              <!--            <div class="result-fs-18-bold">0 {{nftList.length > 0? nftList[0].priceUnit:'KLAY'}}</div>-->
              <!--          </div>-->
              <div class="buying-multi-result-nft-total-price-section">
                <div class="result-fs-18-bold">{{ $t('modal.multiNftBuy.totalPrice') }}</div>
                <div>
                  <div class="result-total-price">
                    <div class="result-total-price-img">
                      <img src="@/assets/image/icons/nft-buying-modal/buying-klaytn.png" alt="">
                    </div>
                    <div class="result-fs-28-bold">{{ paySumInfo.sum }}
                      {{ nftList.length > 0 ? nftList[0].priceUnit : 'KLAY' }}
                    </div>
                  </div>

                  <div class="result-fs-16">( ￦ {{ paySumInfo.krwSum }} )</div>
                </div>
              </div>

            </div>
            <div class="buying-multi-btn-section pointer-hover" @click="nextToSign">
              <div class="buying-multi-btn">
                {{ paySumInfo.sum }} {{ nftList.length > 0 ? nftList[0].priceUnit : 'KLAY' }} {{ $t('modal.multiNftBuy.buyNow') }}
              </div>
            </div>
          </div>

        </div>
      </template>
    </gaze-modal>

  </div>
  <div v-else class="gaze-buying-multi-nft-modal">
    <gaze-modal :visible="isOpen" @close="$emit('closeModal')" :title="$t('modal.multiNftBuy.title')">
      <template #content>
        <div class="gaze-buying-multi-nft-modal-section">
          <div class="mobile-gaze-modal-text mobile-only">
            <div class="mobile-gaze-modal-text-desc">
               {{ $t('modal.multiNftBuy.mobile.title') }}
            </div>
          </div>
          <div class="buying-multi-wrapper">
            <div class="gaze-buying-multi-modal-title">
              <div>
                {{ $t('modal.multiNftBuy.subtitle') }} ( {{ $t('modal.multiNftBuy.total') }} {{ nftList.length }} {{ $t('modal.multiNftBuy.count') }})
              </div>
            </div>
            <div class="buying-multi-info-area">
              <div class="buying-multi-info-section" v-for="(card,index) in  nftList" :key="index">

                <div class="buying-multi-info-img">
                  <nft-image-or-video-small :nft="card"></nft-image-or-video-small>
                </div>
                <div class="buying-multi-info-desc">
                  <a-tooltip :title="card.nftName">
                    <div class="buying-multi-info-name">{{ card.nftName }}</div>
                  </a-tooltip>

                  <div class="buying-multi-info-owner-section">
                    <div class="buying-multi-info-owner-wrapper">
                      <div class="buying-multi-info-owner">{{ $t('modal.multiNftBuy.owner') }}</div>
                      <div style="display: flex; align-items: center;gap: 3px;">
                        <div
                            :class="card.userProfileImg ? 'buying-multi-info-owner-img' : 'buying-multi-info-owner-img border-none'">
                          <img :src="card.userProfileImg ?card.userProfileImg : tempProfile.default" alt="">
                        </div>
                        <div class="buying-multi-info-owner-name">{{ card.userName }}</div>
                      </div>

                    </div>

                    <div class="buying-multi-info-owner-price-top-level">
                      <div class="buying-multi-info-owner-price-section">
                        <div class="buying-multi-info-owner-price">
                          <div class="buying-multi-info-owner-price-img">
                            <img :src="getCoinIcon(card.priceUnit)" alt="">
                          </div>
                          <div class="buying-multi-info-owner-price-amount">
                            {{ card.price ? card.price.toLocaleString('ko') : '-' }}
                            {{ card.priceUnit ? card.priceUnit : '-' }}
                          </div>
                        </div>
                        <div class="buying-multi-info-owner-price-won">
                          (￦{{ card.krwPrice ? card.krwPrice.toLocaleString('ko') : '-' }})
                        </div>
                      </div>
                    </div>


                  </div>


                </div>

              </div>
            </div>
            <!-- 결제 수단 선택 영영       -->
            <div class="buying-multi-result-section">
              <div class="buying-multi-result-nft-price-section">
                <div class="result-fs-16-regular">{{ $t('modal.multiNftBuy.nftPrice') }}</div>
                <div class="result-fs-18-bold">{{ paySumInfo.sum }}
                  {{ nftList.length > 0 ? nftList[0].priceUnit : 'KLAY' }}
                </div>
              </div>
              <!--          <div class="buying-multi-result-service-fee-section">-->
              <!--            <div class="result-fs-16-regular">서비스수수료 0%</div>-->
              <!--            <div class="result-fs-18-bold">0 {{nftList.length > 0? nftList[0].priceUnit:'KLAY'}}</div>-->
              <!--          </div>-->
              <div class="buying-multi-result-nft-total-price-section">
                <div class="result-fs-18-bold">{{ $t('modal.multiNftBuy.totalPrice') }}</div>
                <div>
                  <div class="result-total-price">
                    <div class="result-total-price-img">
                      <img src="@/assets/image/icons/nft-buying-modal/buying-klaytn.png" alt="">
                    </div>
                    <div class="result-fs-28-bold">{{ paySumInfo.sum }}
                      {{ nftList.length > 0 ? nftList[0].priceUnit : 'KLAY' }}
                    </div>
                  </div>

                  <div class="result-fs-16">( ￦ {{ paySumInfo.krwSum }} )</div>
                </div>
              </div>

            </div>
            <div class="buying-multi-btn-section pointer-hover" @click="nextToSign">
              <div class="buying-multi-btn">
                {{ $t('modal.multiNftBuy.buyBtnText1') }}{{ paySumInfo.sum }} {{ nftList.length > 0 ? nftList[0].priceUnit : 'KLAY' }} {{ $t('modal.multiNftBuy.buyBtnText2') }}
              </div>
            </div>
          </div>

        </div>
      </template>
    </gaze-modal>

  </div>
</template>

<script>
import {defineComponent, onMounted, onBeforeUnmount, computed} from "vue";
import {getCoinIcon} from "@/script/common/constantScript";
import {useTempImg} from "@/script/temp/tempScript";
import GazeModal from "@/components/common/modal/GazeModal";
import {useModal} from "@/script/common/modalScript";
import {displayRational} from "@/utils/stringUtil";
import {Tooltip} from "ant-design-vue";
import {isKaikasMobile, isMetamaskMobile, isMobileDevice} from "@/utils/windowUtils";
import {openPopup} from "@/script/common/popupScript";
import Decimal from 'decimal.js';
import NftImageOrVideoSmall from "@/components/pages/nft/comp/NftImageOrVideoSmall";
import {fileTypeCheck} from "@/utils/fileTypeCheckUtils";

export default defineComponent({
  name: "NftMultiBuyingComp",
  components: {
    GazeModal,
    ATooltip: Tooltip,
    NftImageOrVideoSmall
  },
  emits: ["update:modelValue", 'nextToSign', 'closeModal'],
  props: {
    type: {
      default: "",
    },
    nftList: {
      default: []
    },
  },
  setup(props, context) {
    const {
      isOpen,
      toggleModal,
    } = useModal()
    toggleModal(true)

    // const nextToSign = () => {
    //   context.emit('nextToSign')
    // }]

    const nftPriceUnit = computed(() => {
      let priceUnit = '';

      props.nftList.map((item) => {
        return priceUnit = item.priceUnit
      })
      return {
        priceUnit
      }
    })


    const nextToSign = () => {
      if (nftPriceUnit.value.priceUnit === 'MATIC') {
        // 모바일, 메타마스크 인앱
        if (isMobileDevice()) {
          //'메타마스크 인앱일때'
          if (isMetamaskMobile()) {
            // 결제
            // toggleModal(true)
            context.emit('nextToSign')
          } else {
            openPopup("walletInstall", {walletName: "METAMASK"}, 'multiBuy')
          }
        } else {
          context.emit('nextToSign')
          // toggleModal(true)
        }
      } else {

        if (isMobileDevice()) {
          if (isKaikasMobile()) {
            // toggleModal(true)
            context.emit('nextToSign')
          } else {
            openPopup("walletInstall", {walletName: "KAIKAS"}, 'multiBuy')
          }
        } else {
          context.emit('nextToSign')
          // toggleModal(true)
        }
      }

    }

    const {tempProfile} = useTempImg()
    const paySumInfo = computed(() => {
      let krwSum = new Decimal(0);
      let sum = new Decimal(0);
      props.nftList.forEach(item => {
        sum = sum.add(item.price);
        krwSum = krwSum.add(item.krwPrice);
      })
      sum = sum.toNumber()
      krwSum = krwSum.toNumber()
      return {
        sum: displayRational(sum.toString()),
        krwSum: displayRational(krwSum.toString())
      }
    })

    return {
      isOpen,
      paySumInfo,
      getCoinIcon,
      nextToSign,
      tempProfile,
      isMetamaskMobile
    }
  }
})
</script>

<style scoped>

</style>