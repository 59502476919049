import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'LoginView',
        component: () => import('@/components/pages/login/LoginView.vue'),
        meta: {
            loggedIn: false,
        },
    },
];

export default routes