import {onMounted, onUnmounted} from "vue";

export const getLocation = () => {
    return window.location;
}

export const toggleBodyScroll = (isScroll: boolean, scrollY?: number) => {
    if (isScroll) {
        document.body.style.position = "unset"
        if (typeof scrollY === "number") window.scrollTo({ top: scrollY })
    } else {
        if (typeof scrollY === "number") document.body.style.bottom = `${scrollY}px`
        document.body.style.position = "fixed"
    }
}