import apiCaller from "./index";
import {LoginForm, RegisterForm} from "@/models/authModels";

export async function loginApi(loginForm: LoginForm) {
    loginForm.email = loginForm.email.trim()
    const response = await apiCaller.post(
        '/users/signin/email',
        { body: loginForm },
        {}
    );
    return response.data;
}

export async function registerApi(registerForm :RegisterForm,termsMarketing:any){
    const requsetForm = {
        email: registerForm.email.trim(),
        pwd: registerForm.pwd,
        name: registerForm.name,
        nickname:registerForm.nickname,
        lang:registerForm.lang,
        mrktPolicyAgreed:termsMarketing
    }

    const response = await apiCaller.post(
        '/users/signup/email',
        { body: requsetForm },
        {}
    );
    return response.data;
}

export async function registerSendBackEmailApi(requset :any){
    const response = await apiCaller.post(
        '/users/resend/email',
        { body: requset },
        {}
    );
    return response.data;
}


export async function getUserNickNameCheckApi(name:any) {
    const response = await apiCaller.get("users/name/check",
        {
            query:{
                name:name
            }
        }, {});
    return response.data;
}
export async function logoutApi() {
    const response = await apiCaller.delete("/users/logout", {}, {});
    return response.data;
}