import { isEmpty } from 'lodash'
import { Payload } from '@/models/utils'

export function requestUrl(url: string, payload: Payload): string {
    if(isEmpty(payload)) payload = {};

    let u = url;
    if(!isEmpty(payload.params)) {
        Object.keys( payload.params ).forEach( o => {
            u = u.replace(":"+o, payload.params[o]);
        });
    }

    let q = "";
    if(!isEmpty(payload.query)) {
        q = queryString(payload.query);
    }
    return u + q;
}

export function queryString(queryObject: any): string {
    if(isEmpty(queryObject)) return "";

    let queryString = "?";
    Object.keys( queryObject ).forEach( o => {
        if( queryObject[ o ] !==null) {
            if (Array.isArray(queryObject[o])) {
                queryObject[o].forEach((value: string) => {
                    queryString += o + "=" + value + "&";
                })
            } else {
                queryString += o + "=" + queryObject[ o ] + "&";
            }
        }
    });
    return queryString;
}