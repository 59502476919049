<template>

  <div class="wallet-install-comp" >
    <div class="text-area desktop-only">
      <div class="text-title desktop-only">{{ curWallet.korean==='카이카스' ? $t('common.kaikas'):$t('common.metamask')  }}{{$t('modal.walletInstallModal.pc.title')}}</div>
      <div class="text-title mobile-only">{{$t('modal.walletInstallModal.mobile.desc1')}} {{$t('modal.walletInstallModal.pc.english.text1')}}{{ curWallet.korean==='카이카스' ? $t('common.kaikas'):$t('common.metamask') }} {{$t('modal.walletInstallModal.mobile.desc2')}}{{$t('modal.walletInstallModal.pc.english.text2')}}</div>
      <div class="i18n-pre-wrap">
        {{ curWallet.korean==='카이카스' ? $t('common.kaikas'):$t('common.metamask') }} {{$t('modal.walletInstallModal.pc.desc1')}}{{$t('modal.walletInstallModal.pc.english.text3')}}<br>
        {{$t('modal.walletInstallModal.pc.desc2')}}{{$t('modal.walletInstallModal.pc.english.text4')}}
      </div>
<!--      Metamask wallet has not been installed yet.-->
<!--      Metamask Chrome extension installation is required.-->
<!--      Please refresh the page after installation.-->
    </div>
    <div class="text-area mobile-only">
      <div class="text-title">{{$t('modal.walletInstallModal.mobile.en.contents.title')}}{{ curWallet.english }} {{$t('modal.walletInstallModal.mobile.contents.title')}}</div>
      <div class="i18n-pre-wrap">
        {{$t('modal.walletInstallModal.mobile.en.contents.text1')}}{{$t('modal.walletInstallModal.mobile.en.contents.text2')}} {{ curWallet.english }} {{$t('modal.walletInstallModal.mobile.contents.text2')}}<br/>
        {{$t('modal.walletInstallModal.mobile.en.contents.text3')}}{{ curWallet.english }}{{$t('modal.walletInstallModal.mobile.contents.text3')}}{{$t('modal.walletInstallModal.mobile.en.contents.question')}}
      </div>
    </div>
    <div class="button-row">
      <button class="gaze-button-sec" @click="$emit('canceled')">{{$t('modal.walletInstallModal.mobile.cancel')}}</button>
      <button class="gaze-button-p1 desktop-only" @click="linkInstall">{{$t('modal.walletInstallModal.mobile.install')}}</button>
      <button class="gaze-button-p1 mobile-only" @click="mobileLinkInstall">{{$t('modal.walletInstallModal.mobile.move')}}</button>

    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, ref} from "vue";
import {WALLET_GROUP} from "@/config/constants";
import {store} from "@/store";

export default defineComponent({
  name: "WalletInstallComp",
  emits: ["canceled"],
  props: ["wallet"],
  setup(props, context) {
    const curWallet = computed(() => {
      return WALLET_GROUP[props.wallet];
    })
    const walletType = computed(() => {
      return store.state.open.popup.type
    })

    const nftId = computed(() => {
      return store.state.open.popup.nftId
    })

    const linkInstall = () => {
      window.open(curWallet.value.install, "_blank")
      context.emit("canceled")
    }
    const mobileLinkInstall = () => {
      if (walletType.value === 'wallet') {
        window.open(curWallet.value.wallet, "_blank")
      } else if (walletType.value === 'register') {
        window.open(curWallet.value.register + `${nftId.value}`, "_blank")
      } else if (walletType.value === 'multiBuy') {
        window.open(curWallet.value.multiBuy, "_blank")
      } else if (walletType.value === 'singleBuy') {
        window.open(curWallet.value.singleBuy + `${nftId.value}`, "_blank")
      } else if (walletType.value === 'update') {
        window.open(curWallet.value.update + `${nftId.value}`, "_blank")
      } else if (walletType.value === 'offerCancel') {
        window.open(curWallet.value.offerCancel, "_blank")
      } else if (walletType.value === 'offerAccept') {
        window.open(curWallet.value.offerAccept, "_blank")
      } else if (walletType.value === 'offer') {
        window.open(curWallet.value.offer + `${nftId.value}`, "_blank")
      } else if (walletType.value === 'offerReject') {
        window.open(curWallet.value.OfferReject, "_blank")
      } else {
        window.open(curWallet.value.mobile, "_blank")
      }
      context.emit("canceled")
    }

    return {
      curWallet,
      linkInstall,
      mobileLinkInstall,
    }
  }

})
</script>

<style scoped>

</style>