import apiCaller from "./index";
import {PostWalletRequest, WalletId} from "@/models/request/walletRequest";
import {GetCltnChrtListResponse} from "@/models/response/cltnResponse";

export async function postWalletApi(wallet: PostWalletRequest) {

    const response = await apiCaller.post("/wallets", {body: wallet}, {});
    return response.data;
}

export async function getAllCltnApi(request: any) {
    const response = await apiCaller.get("/cltns", {
        query: request
    }, {});
    return response.data;
}


export async function getHeaderShopCltnApi() {
    const response = await apiCaller.get("/cltns/shop", {
    }, {});
    return response.data;
}

export async function getCltnDetailApi(id: any, request: any) {
    const response = await apiCaller.get("/cltns/:id", {
        params: {
            id: id,
            page: request.page,
            size: request.size,
            priceUnit: request.priceUnit,
            displayName: request.displayName,
            tokenStnd: request.tokenStnd,
            order: request.order
        }
    }, {});
    return response.data;
}


export async function postCltnItrtApi(cltnId: any) {

    const response = await apiCaller.post(
        "/cltns/:cltnId/itrt",
        {
            params: {
                cltnId: cltnId
            }
        },
        {});
    return response.data;
}

export async function deleteCltnItrtApi(cltnId: any) {

    const response = await apiCaller.delete(
        "/cltns/:cltnId/itrt",
        {
            params: {
                cltnId: cltnId
            }
        },
        {});
    return response.data;
}

export async function getCltnChrtListApi(cltnId: any): Promise<GetCltnChrtListResponse> {
    const response = await apiCaller.get(
        "/cltns/:cltnId/dcpt",
        {
            params: {
                cltnId
            }
        },
        {});
    return response.data;
}