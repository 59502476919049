
import {defineComponent, ref, computed, createVNode, onBeforeUnmount, onMounted, watch} from 'vue';
import {useMobileSideBar} from '@/script/layout/navBarScript';
import mobileSideBarMenu from '@/components/layout/mobile/sidebar/mobileSideBarMenu';
import MobileSideBarNftListDetailComp from '@/components/layout/mobile/sidebar/MobileSideBarNftListDetailComp.vue';
import MobileSideBarLoggedMenu from '@/components/layout/mobile/sidebar/MobileSideBarLoggedMenu.vue';
import {useStore} from '@/store';
import {useLogin} from '@/script/login/loginScript';
import {useRegister} from '@/script/register/registerScript';
import {useBasket} from '@/script/basket/basketScript';
import MobileSearchBar from './sidebar/MobileSearchBar.vue';
import router from "@/router";

export default defineComponent({
  name: 'MobileSideBar',
  components: {
    MobileSideBarNftListDetailComp,
    MobileSideBarLoggedMenu,
    MobileSearchBar,
  },

  setup() {
    const {
      isOpen,
      isShoppingOpen,
      toggleSidebar,
      toggleSidebarDetail,
      isSomethingOpen,
      isSearchOpen,
    } = useMobileSideBar();
    const store = useStore();
    const {moveLoginPage} = useLogin();
    const {moveRegisterPage} = useRegister();
    const {toggleBasket, checkedBasketList} = useBasket();
    const mobileLoginPage = async () => {
      await moveLoginPage();
      await toggleSidebar();
    };
    const mobileRegisterPage = async () => {
      await moveRegisterPage();
      await toggleSidebar();
    };
    const mobileHomePage = () => {
      toggleSidebar();
      router.push({name: "Home"})
    }
    const isLogin = computed(() => {
      return store.state.auth.isLogin;
    });

    watch(isOpen, () => {
      const body: any = document.body;
      const mobileSideBar: any = document.body.querySelector<HTMLElement>(
        '.mobile-side-bar-section'
      );
      if (isOpen.value) {
        body.style.overflowY = 'hidden';
        mobileSideBar.style.overflowY = 'scroll';
      } else {
        body.style.overflowY = 'scroll';
      }
    });

    return {
      isOpen,
      isShoppingOpen,
      toggleSidebar,
      toggleSidebarDetail,
      mobileSideBarMenu,
      isLogin,
      mobileLoginPage,
      mobileRegisterPage,
      mobileHomePage,
      toggleBasket,
      checkedBasketList,
      isSomethingOpen,
      isSearchOpen,
    };
  },
});
