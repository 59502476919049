
import {computed, defineComponent, nextTick, ref} from "vue";
import {useBasket} from "@/script/basket/basketScript";
import {toLocalString} from "@/utils/unitUtils";
import NftImageOrVideoSmall from "@/components/pages/nft/comp/NftImageOrVideoSmall.vue";

export default defineComponent({
  name: "BasketItemComp",
  components: {
    NftImageOrVideoSmall,
  },
  props: {
    nft: {
      default: {}
    },
    disabled: {
      default: false
    }
  },

  setup(props) {
    const className = computed(() => {
      if (props.disabled) return "cart-item-comp cart-item-disabled"
      return "cart-item-comp"
    })
    const {
      deleteBasketItem,
    } = useBasket()


    return {
      className,
      deleteBasketItem,
      toLocalString,
    }
  }
})
