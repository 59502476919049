import { message } from "ant-design-vue";


export default (e: any) => {
    console.log(e.response)
    if (e.response.data) {
        const data = e.response.data;
        message.error(`${data.errorCode}: ${data.message}`)
    } else {
        message.error('알 수 없는 오류가 발생했습니다')
    }
}
