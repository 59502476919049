import { Module } from 'vuex'
import { RootState } from '../index'
export interface moduleBState {
    count: number;
}

export const moduleB: Module<moduleBState, RootState> = {
    namespaced: true,
    state: () => ({
        count: 42,
    }),
    mutations: {},
    actions: {},
    getters: {},
}