import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/cltn',
        name: 'CltnListView',
        component: () => import('@/components/pages/collection/CltnListView.vue'),
        meta: {
            layout: "black-header",
        },
    },

    {
        path: "/cltn/detail/:id",
        name: "CltnDetailView",
        component: () =>
            import("@/components/pages/collection/CltnDetailView.vue"),
    },
];

export default routes