import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nft_multi_buying_modal_comp = _resolveComponent("nft-multi-buying-modal-comp")!
  const _component_nft_offer_sign_comp = _resolveComponent("nft-offer-sign-comp")!
  const _component_nft_confirm_modal_comp = _resolveComponent("nft-confirm-modal-comp")!
  const _component_gaze_pending_modal = _resolveComponent("gaze-pending-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.priceOpen)
      ? (_openBlock(), _createBlock(_component_nft_multi_buying_modal_comp, {
          key: 0,
          "nft-list": _ctx.payList.nftList,
          onNextToSign: _ctx.nextToSign,
          onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglePrice(false)))
        }, null, 8, ["nft-list", "onNextToSign"]))
      : _createCommentVNode("", true),
    (_ctx.signOpen)
      ? (_openBlock(), _createBlock(_component_nft_offer_sign_comp, {
          key: 1,
          title: _ctx.$t('modal.signModal.buy.title'),
          onCloseModal: _ctx.closeSign,
          onSignTrst: _ctx.signTrstMultiBuyingApprove
        }, null, 8, ["title", "onCloseModal", "onSignTrst"]))
      : _createCommentVNode("", true),
    (_ctx.confirmOpen)
      ? (_openBlock(), _createBlock(_component_nft_confirm_modal_comp, {
          key: 2,
          success: _ctx.signProps,
          "custom-error": _ctx.customError,
          "onUpdate:custom-error": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customError) = $event)),
          type: 'buy',
          onFail: _ctx.closeSignModal
        }, null, 8, ["success", "custom-error", "onFail"]))
      : _createCommentVNode("", true),
    (_ctx.pendingOpen)
      ? (_openBlock(), _createBlock(_component_gaze_pending_modal, {
          key: 3,
          visible: _ctx.pendingOpen,
          title: _ctx.$t('modal.signModal.buy.pendingTitle'),
          contents: _ctx.$t('modal.signModal.buy.pendingContents')
        }, null, 8, ["visible", "title", "contents"]))
      : _createCommentVNode("", true)
  ], 64))
}