import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mobile-logged-menu-section" }
const _hoisted_2 = { class: "logged-in-user-info" }
const _hoisted_3 = { class: "user-profile-img" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "user-info-name" }
const _hoisted_6 = { class: "user-info-items-section pointer-hover" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.userProfile?_ctx.userProfile :_ctx.tempProfile.black,
          alt: ""
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.userName), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: "user-info-item",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.moveRouter('MyPageView')))
      }, _toDisplayString(_ctx.$t('common.header.mobile.mobileLoginSection.myNft')), 1),
      _createElementVNode("div", {
        class: "user-info-item",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.moveRouter('Dashboard')))
      }, _toDisplayString(_ctx.$t('common.header.mobile.mobileLoginSection.dashboard')), 1),
      _createElementVNode("div", {
        class: "user-info-item",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.moveRouter('WalletManageView')))
      }, _toDisplayString(_ctx.$t('common.header.mobile.mobileLoginSection.paymentManagement')), 1),
      _createElementVNode("div", {
        class: "user-info-item",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.moveRouter('UserConfig')))
      }, _toDisplayString(_ctx.$t('common.header.mobile.mobileLoginSection.accountSetting')), 1)
    ]),
    _createElementVNode("div", {
      class: "user-info-logout-btn pointer-hover",
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.mobileLogout && _ctx.mobileLogout(...args)))
    }, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.header.mobile.mobileLoginSection.logout')), 1)
    ])
  ]))
}