<template>
  <div class="cart-empty-comp">
    <img src="@/assets/image/logo/bubble-logo.png" class="gaze-logo">
    <div class="cart-suggest">{{$t('basket.empty.text1')}}<br class="mobile-only"> {{$t('basket.empty.text2')}}</div>
    <div class="button-area">
      <more-contents-button content="nft" @click="toggleBasket(false)"/>
    </div>
  </div>
</template>

<script>
import MoreContentsButton from "@/components/common/button/MoreContentsButton";
import {useBasket} from "@/script/basket/basketScript";

export default {
  name: "BasketEmptyComp",
  components: {
    MoreContentsButton,
  },
  setup() {
    const {
      toggleBasket
    } = useBasket();
    return {
      toggleBasket,
    }
  }
}
</script>

<style scoped>

</style>