import apiCaller from "@/api/index";
import {openPopup} from "@/script/common/popupScript";
import {computed} from "vue";

export async function getBasketItemListApi(request: any) {
    const response = await apiCaller.get(
        "/users/baskets",
        {query: {page: request.page, size: request.size}},
        {});
    return response.data;
}

export async function registerBasketItemApi(nftId: string) {
    const response = await apiCaller.post(
        "/users/baskets",
        {body: {nftIdList: nftId}},
        {});
    if (response.data.failList.length) {
        const nftNameList = computed(()=>{
            return response.data.failList.map((item:any)=>{
                return item.name
            })
        })
        openPopup("warningBasket", {message: 'modal.confirmModal.openModal.notYourOwnNFT',name:nftNameList.value})
    }
    return response.data;
}

export async function deleteBasketItemApi(nftId: string) {
    const response = await apiCaller.delete(
        "/users/baskets/:nftId",
        {params: {nftId}},
        {});
    return response.data;
}

export async function deleteAllBasketItemApi() {
    const response = await apiCaller.delete(
        "/users/baskets",
        {},
        {});
    return response.data;
}