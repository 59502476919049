import {Rule} from "@/models/utils";
import {useI18n} from 'vue-i18n';
import {reactive} from 'vue';

export function emailRequiredRule(): Rule {
    const {t} = useI18n();
    return reactive({
        required: true,
        message: t('rules.emailRequired'),
        trigger: "blur",
    });
}

export function passwordRequiredRule(): Rule {
    const {t} = useI18n();
    return reactive({
        required: true,
        message: t('rules.passwordRequired'),
        trigger: "blur",
    });
}

export function notExist(): Rule {
    const {t} = useI18n();
    return reactive({
        required: true,
        message: t('rules.notExist'),
        trigger: "blur",
    });
}

export const REQUIRED: Rule = {
    required: true,
    message: '필수 항목입니다',
    trigger: "blur",
};
export const REQUIRED_CHANGE: Rule = {
    required: true,
    message: "필수 항목입니다",
    trigger: "change",
};
export const requireRule = (t: any) => {
    return {
        required: true,
        message: t("rules.required"),
        trigger: "change",
    }
};
export const COMPANYREQUIRED: Rule = {
    required: true,
    message: "필수 항목입니다",
    trigger: "change",
};

export function emailRule(): Rule {
    const {t} = useI18n();
    return reactive({
        pattern:
            "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?",
        message: t('rules.emailFormat'),
        trigger: "blur",
    });
}

export const EMAIL: Rule = {
    pattern:
        "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?",
    message: "이메일 형식에 맞지 않습니다",
    trigger: "blur",
};
export const emailCheck = (t: any) => {
    return {
        pattern:
            "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?",
        message: t("rules.emailFormat"),
        trigger: "blur",
    }
};
export const PHONE: Rule = {
    pattern: "^[0-9]{10,11}$",
    message: "핸드폰 번호 형식에 맞지 않습니다",
    trigger: "blur",
};

export const REG_NUM: Rule = {
    pattern: /^[0-9]{13,13}$/,
    message: "형식에 맞지 않습니다",
    trigger: "blur",
};

export const ONLYENG: Rule = {
    pattern: /^[a-zA-Z\s]+$/,
    message: "영문만 입력 가능합니다",
    trigger: "blur",
};

export const PWD: Rule = {
    pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
    message: "영문,숫자,기호(!@#$%^&*()+=)를 모두 포함(8~20자리)를 입력해주세요.",
    trigger: "blur",
};
export const pwdCheck = (t: any) => {
    return {
        pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
        message: t("rules.password"),
        trigger: "blur",
    }
};

export const nicknameRequiredCheck = (t: any) => {
    return {
        required: true,
        message: t("rules.nicknameRequired"),
        trigger: "blur",
    }
};

export const NICKNAME: Rule = {
    pattern: /^.{1,50}$/,
    message: "1글자 이상 50글자 이하로 입력해주세요",
    trigger: "blur",
};
export const nicknameCheck = (t: any) => {
    return {
        pattern: /^.{1,50}$/,
        message: t("rules.nicknameCheck"),
        trigger: "blur",
    }
};

export const FILE_REQUIRED: Rule = {
    validator: async (_rule: Rule, value: Array<File>) => {
        if (!value.length) {
            return Promise.reject("필수 항목입니다");
        }
    },
};


// export const FILE_REQUIRED: Rule = {
//     validator: async (_rule: Rule, value: Array<File>) => {
//         if (!value.length) {
//             return Promise.reject("필수 항목입니다");
//         }
//     },
// };