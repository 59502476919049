import apiCaller from "./index";
import {PostWalletRequest, WalletId} from "@/models/request/walletRequest";

export async function postWalletApi(wallet: PostWalletRequest) {

    const response = await apiCaller.post("/wallets", {body: wallet}, {});
    return response.data;
}

export async function getAllNftsApi(request: any) {
    const response = await apiCaller.get("/nfts", {query: request}, {});
    return response.data;
}

export async function deleteWalletApi(walletId: WalletId) {
    const wallet_id = walletId.wallet_id
    // const network = walletAddress.network
    const response = await apiCaller.delete("/wallets/:wallet_id?network=:network",
        {
            params: {wallet_id: wallet_id, network: walletId.network},
            // body: { walletAddress },
        }, {});
    return response.data;
}

export async function getNftDetailApi(nftId: string) {
    const response = await apiCaller.get("/nfts/:nftId",
        {
            params: {nftId},
        }, {});
    return response.data
}

export async function getNftHistoryApi(nftId: string, page: number, size: number, statusList: string[]) {
    const response = await apiCaller.get("/nfts/:nftId/trading-histories",
        {
            params: {nftId},
            query: {
                page,
                size,
                type: statusList,
            }
        }, {});
    return response.data
}

export async function registerNftItrtApi(nftId: string) {
    const response = await apiCaller.post("/nfts/:nftId/itrt",
        {
            params: {nftId},
        }, {});
    return response.data
}

export async function deleteNftItrtApi(nftId: string) {
    const response = await apiCaller.delete("/nfts/:nftId/itrt",
        {
            params: {nftId},
        }, {});
    return response.data
}


// 모달 예상금액 api klay -> won
export async function getNftInfoPricesApi(nftId: string, request: any) {
    const response = await apiCaller.get("/nfts/:nftId/infos/prices",
        {
            params: {nftId},
            query: {
                price: request
            }
        }, {});
    return response.data
}

// 판매등록
export async function getNftApproveRequestApi(nftId: any) {
    const response = await apiCaller.get("/nfts/:nftId/approve/request",
        {
            params: {nftId},
        }, {});
    return response.data
}

// 판매등록
export async function postNftRegistApi(nftId: any, value: any, expiredDate: any,offerYn:any) {
    const response = await apiCaller.post("/nfts/:nftId/regist",
        {
            params: {nftId},
            body: {
                value: value,
                expiredDate: expiredDate,
                offerYn:offerYn,
            }

        }, {});
    return response.data
}

// 판매등록 수정
export async function putNftRegistUpdateApi(nftId: any, price: any, expiredDate: any,offerYn:any) {
    const requestBody = {
        value: price,
        expiredDate: expiredDate,
        offerYn:offerYn
    }
    const response = await apiCaller.put("/nfts/:nftId/regist",
        {
            params: {nftId},
            body: requestBody

        }, {});
    return response.data
}
// 판매취소
export async function postNftRegistCancelApi(nftId: any) {
    const response = await apiCaller.post("/nfts/:nftId/regist/cancel",
        {
            params: {nftId},

        }, {});
    return response.data
}

export async function getNftInfoApi(nftId: any) {
    const response = await apiCaller.get("/nfts/:nftId/infos",
        {
            params: {
                nftId
            }
        }, {});
    return response.data
}

// nft 판매등록정보
export async function getNftBuyInfosApi(nftId: any) {

    const response = await apiCaller.get("/nfts/:nftId/buy/infos",
        {
            params: {
                nftId:nftId
            },
        }, {});
    return response.data
}

// nft 구매 request
export async function getNftBuyRequestApi(nftIdString: any,address:any) {

    const response = await apiCaller.get("/nfts/buy/request",
        {
            query: {
                nftIdString:nftIdString,
                address:address
            },
        }, {});
    return response.data
}

// nft 단건 구매
export async function postNftBuyApi(nftId: any,fromAddress:any) {
    const requestBody = {
        fromAddress:fromAddress
    }
    const response = await apiCaller.post("/nfts/:nftId/buy",
        {
            params: {nftId},
            body: requestBody
        }, {});
    return response.data
}

// nft 다건 구매
export async function postNftMultiBuyApi( nftIdList: any,fromAddress:any) {
    const requestBody = {
        fromAddress:fromAddress,
        nftIdList:nftIdList
    }
    const response = await apiCaller.post("/nfts/buy",
        {
            body: requestBody
        }, {});
    return response.data
}

// NFT Transfer_log
export async function getNftRegistPollingApi(nthId:any) {
    const response = await apiCaller.get("/nfts/regist/polling",
        {
       query:{
           nthId:nthId
       }
        }, {});
    return response.data
}

// 서명 거부
export async function postNftTrstsRejectApi(nthId:any) {
    const response = await apiCaller.post("/nfts/trsts/reject",
        {
            query:{
                nthId:nthId
            }
        }, {});
    return response.data
}