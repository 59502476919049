import {computed} from "vue";
import {store, useStore} from "@/store";
import {getIsLoginInfoApi} from "@/api/userInfoApi";
import {logoutApi} from "@/api/authApis";
import {useRouter} from "vue-router";

export const useUserLogout =  () => {
    const router = useRouter()
    const store = useStore()

    const logout = async () =>{
        await logoutApi()
        await store.dispatch('auth/logout')
        await router.push({name:'Home'})
    }

    return {
        logout
    };
};