<template>
  <div class="gaze-onboarding-modal">
    <gaze-modal :visible="isOpen" @close="$emit('closeModal')" title="NFT 결제 방법" :priceUnit="priceUnit">
      <template #content>
        <div class="gaze-onboarding-contents-wrapper">
          <div v-show="priceUnit==='KLAY'" >
            <div class="onboarding-wallet-section">
              <img class="wallet-img" src="@/assets/image/icons/onboarding-modal/kaikas/kaikas-icon.png" alt="">
              <div class="wallet-text">
                카이카스 지갑 앱에서 결제하기
              </div>
            </div>
            <div v-for="(step , index) in onboardingKlayList" :key="index">
              <div class="onboarding-step-section">
                <div class="step-title">
                  {{ step.title }}
                </div>
                <img class="step-img" :src="step.img" alt="">
              </div>
            </div>
          </div>

          <div v-show="priceUnit==='MATIC'">
            <div class="">
              <img src="@/assets/image/icons/onboarding-modal/metamask/metamask-icon.png" alt="">
              <div>
                메타마스크 지갑 앱에서 결제하기
              </div>
            </div>
            <div v-show="priceUnit==='MATIC'" v-for="(step , index2) in onboardingMaticList" :key="index2">
              <div class="onboarding-step-section">
                <div class="step-title">
                  {{ step.title }}
                </div>
                <img class="step-img" :src="step.img" alt="">
              </div>
            </div>
          </div>
        </div>
      </template>
    </gaze-modal>

  </div>
</template>

<script>
import {defineComponent, onMounted, onBeforeUnmount, computed} from "vue";
import GazeModal from "@/components/common/modal/GazeModal";
import {useModal} from "@/script/common/modalScript";


export default defineComponent({
  name: "NftMultiBuyingComp",
  components: {
    GazeModal,
  },
  emits: ["update:modelValue", 'nextToSign', 'closeModal'],
  props: {
    type: {
      default: "",
    },
    nftList: {
      default: []
    },
    priceUnit: {
      default: ""
    },
  },
  setup(props, context) {
    const {
      isOpen,
      toggleModal,
    } = useModal()
    toggleModal(true)

    const onboardingKlayList = [
      {
        step: 1,
        title: 'Step1. 구매하기 버튼 클릭',
        img: require('@/assets/image/icons/onboarding-modal/metamask/step1.png')
      },
      {
        step: 2,
        title: 'Step2. 계정 로그인',
        img: require('@/assets/image/icons/onboarding-modal/metamask/step1.png')
      },
      {
        step: 3,
        title: 'Step3. NFT 결제 진행',
        img: require('@/assets/image/icons/onboarding-modal/metamask/step1.png')
      },
    ]

    const onboardingMaticList = [
      {
        step: 1,
        title: 'Step1. 폴리곤 메인넷 추가 후 브라우저 탭 선택',
        img: require('@/assets/image/icons/onboarding-modal/metamask/step1.png')
      },
      {
        step: 2,
        title: 'Step2. gaze 마켓으로 이동',
        img: require('@/assets/image/icons/onboarding-modal/metamask/step1.png')
      },
      {
        step: 3,
        title: 'Step3. 메뉴 화면 선택',
        img: require('@/assets/image/icons/onboarding-modal/metamask/step1.png')
      },
      {
        step: 4,
        title: 'Step4. 로그인 화면으로 이동',
        img: require('@/assets/image/icons/onboarding-modal/metamask/step1.png')
      },

      {
        step: 5,
        title: 'Step5. 계정 로그인',
        img: require('@/assets/image/icons/onboarding-modal/metamask/step1.png')
      },

      {
        step: 6,
        title: 'Step6. 구매 상품 다시 선택',
        img: require('@/assets/image/icons/onboarding-modal/metamask/step1.png')
      },
      {
        step: 7,
        title: 'Step7. 결제하기 진행',
        img: require('@/assets/image/icons/onboarding-modal/metamask/step1.png')
      },
    ]

    return {
      isOpen,
      onboardingKlayList,
      onboardingMaticList
    }
  }
})
</script>

<style scoped>

</style>