import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/contact',
        name: 'ContactView',
        component: () => import('@/components/pages/contact/ContactView.vue'),

    },


];

export default routes