import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/find/password',
        name: 'FindPasswordView',
        component: () => import('@/components/pages/find/password/FindPasswordView.vue'),


    },
    {
        path: '/find/password/email',
        name: 'FindPasswordEmailView',
        component: () => import('@/components/pages/find/password/FindPasswordEmailView.vue')
    },
    {
        path: '/find/password/wait',
        name: 'FindPasswordWaitView',
        component: () => import('@/components/pages/find/password/FindPasswordWaitView.vue')
    },
    {
        path: '/find/password/success',
        name: 'FindPasswordMobileSuccessView',
        component: () => import('@/components/pages/find/mobile/FindPasswordMobileSuccessView.vue')
    },
];

export default routes