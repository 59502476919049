import messageError from "../../error/messageError";
import {setMetaMaskInitEvent} from "@/script/metamask/MetamaskEventScript";
import {store} from "@/store";
import {openPopup} from "@/script/common/popupScript";
import {createVNode, reactive} from "vue";
import MetaMaskSDK from '@metamask/sdk';
import {Modal} from "ant-design-vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {isMetamaskMobile, isMobileDevice} from "@/utils/windowUtils";
import detectEthereumProvider from '@metamask/detect-provider'
import {postNftTrstsRejectApi} from "@/api/nftApis";

export const loadMetamaskWallet = async () => {
    if (!isMobileDevice()) {
        try {
            const options:any = {
                injectProvider: false,
                communicationLayerPreference: 'webrtc',
            };
            const MMSDK = new MetaMaskSDK(options);
            const walletProvider = await MMSDK.getProvider()
            // console.log('walletProvider',walletProvider)
            // const walletProvider = (window as any).ethereum.providerMap ?
            //     (window as any).ethereum.providerMap.get("MetaMask") : (window as any).ethereum
            if (walletProvider.isMetaMask) {
                walletProvider.request({
                    method: "eth_requestAccounts",
                }).then().catch((error:any)=>{
                    if(error.code===-32002){
                        openPopup("warning", {message: "메타마스크 잠금해제가 필요합니다"})
                    }
                })

                // if(walletProvider._state.initialized===false || walletProvider._state.isConnected===false || walletProvider._state.isUnlocked===false){
                //     // walletProvider.enable()
                //     throw {
                //         code: -32002,
                //     }
                // }
            }
            if (!store.state.wallet.metamask.isEnabled) {
                setMetaMaskInitEvent(walletProvider)
            }

            store.commit("wallet/FETCH_METAMASK_INFO",
                {isEnabled: true, address: walletProvider.selectedAddress});

            return walletProvider
        } catch (e: any) {
            if (e.code === -32002) {
                openPopup("warning", {message: "메타마스크 잠금해제가 필요합니다"})
            }
            throw e
        }
    } else {
        const options:any = {
            injectProvider: false,
            communicationLayerPreference: 'webrtc',
        };
        const MMSDK = new MetaMaskSDK(options);
        const walletProvider = await MMSDK.getProvider()
        if (walletProvider) {
            // Your code to retrieve the wallet address goes here
            if (isMetamaskMobile()) {
                const accounts = await walletProvider.request({ method: "eth_requestAccounts" });
                const currentAccount = accounts[0];
                store.commit("wallet/FETCH_METAMASK_INFO", {isEnabled: true, address: currentAccount});
            } else {
                openPopup("walletInstall", {walletName: "METAMASK"},'wallet')
            }
        } else {
            console.error("Please install Metamask to use this feature");
        }
        return walletProvider
    }

};

export const useMetamaskScript = () => {

    const redirectToDownloadMetamask = () => {
        window.location.href = "https://metamask.io/";
    };

    const getAccounts = async () => {
        const wallet = await loadMetamaskWallet();
        const responseWallet =await wallet.request({
            method: "eth_requestAccounts",
        });
        return responseWallet
    }

    const checkBalance = async (address?: string) => {
        try {
            const wallet = await loadMetamaskWallet();
            const response = await wallet.request({
                method: "eth_getBalance",
                params: [address, "latest"],
            });
            return parseInt(response) / Math.pow(10, 18);
        } catch (err) {
            messageError(err);
        }
    };

    const sendTransactions = async (abiAddress: any, amount: number,nthId:string) => {
        try {
            const wallet = await loadMetamaskWallet();
            // const transactionParameters = {
            //     // gasPrice: "0x09184e72a000", // customizable by user during MetaMask confirmation.
            //     gas: "50000", // customizable by user during MetaMask confirmation.
            //     to: abiAddress.address, // Required except during contract publications.
            //     from: (window as any).ethereum.selectedAddress, // must match user's active address.
            //     value: Number(amount * Math.pow(10, 18)).toString(16), // Only required to send ether to the recipient from the initiating external account.
            //     data: abiAddress.abi
            // };

            const transactionParameters = {
                // gasPrice: "0x09184e72a000", // customizable by user during MetaMask confirmation.
                gas: "200000", // customizable by user during MetaMask confirmation.
                to: abiAddress.address, // Required except during contract publications.
                from: wallet.selectedAddress, // must match user's active address.
                value: Number(amount * Math.pow(10, 18)).toString(16), // Only required to send ether to the recipient from the initiating external account.
                data: abiAddress.abi
            };
            const response = await wallet.request({
                method: "eth_sendTransaction",
                params: [transactionParameters],
            });
            return response
        } catch (err: any) {
            // messageError(err);
            // message.error(err.response.message)
            console.log('sendTransactions', err)
            if(err.code ===4001) await postNftTrstsRejectApi(nthId)
            throw err
            // throw {response: 'sendTransaction에 실패했습니다.'}
        }
    };

    const getTrstReceipt = async (response: any) => {
        const wallet = await loadMetamaskWallet();
        const getTrstReceipt = await wallet.request({
            method: "eth_getTransactionReceipt",
            params: [response],
        });
        return getTrstReceipt
    }

    return {
        loadMetamaskWallet,
        checkBalance,
        getAccounts,
        sendTransactions,
        redirectToDownloadMetamask,
        getTrstReceipt
    };
};
