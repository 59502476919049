import {ref, reactive, UnwrapRef, SetupContext, EmitsOptions, computed, createVNode} from 'vue';
import {LoginForm} from '@/models/authModels';
import {
    EMAIL,
    FILE_REQUIRED,
    PHONE,
    PWD,
    REG_NUM,
    REQUIRED,
    emailRequiredRule,
    passwordRequiredRule,
    notExist,
    emailRule
} from '@/utils/rules';
import {useStore} from '@/store';
import {loginApi} from '@/api/authApis';
import {useRouter} from 'vue-router';
import {Modal, notification} from 'ant-design-vue';
import {getIsLoginInfoApi, getUserProfileApi} from '@/api/userInfoApi';
import {store} from '@/store';
import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {loginAlert} from "@/script/common/popupScript";
import { useI18n } from 'vue-i18n';

export const useLogin = () => {
    const router = useRouter();
    const store = useStore();
    const myData = ref();
    const fetchMyData = async () => {
        myData.value = await getUserProfileApi();
    };
    const loginNickName: any = computed(() => {
        return store.state.auth.isLogin;
    });
    const loginForm: UnwrapRef<LoginForm> = reactive({
        email: '',
        pwd: '',
        validMsg: '',
    });
    const errorMsgFeildEmail = async (_rule: any) => {
        if (errMsg.value) {
            return Promise.reject();
        }
    };
    const {t}=useI18n()
    const errorMsgFeildInvalid = async (_rule: any) => {
        if (errMsg.value) {
            return Promise.reject(t('rules.notExist'));
        }
    };
    const errorMsgFeild = async (_rule: any) => {
        if (errMsg.value) {
            return Promise.reject(`${errMsg.value}`);
        }
    };
    const formRef = ref();
    const loading = ref(false);
    const errMsg = ref('');
    const isError = ref(false);
    const {locale}=useI18n()
    const selectedLang =computed(()=>{
        return store.state.auth.lang
    })
    const onFinish = async (values: any,url?:any) => {
        try {
            loading.value = true;
            const loginRes = await loginApi(values);
            await store.dispatch('auth/login', loginRes);
            localStorage.setItem('setLang',loginRes.lang)
            if(selectedLang.value){
                locale.value  = selectedLang.value
            }
            if(url.length ===1){
                await router.go(-1)
            }else{
                await router.push(url[url.length-1])
            }

        } catch (error: any) {
            errMsg.value = error.response.data.message;
            formRef.value.validate();
            isError.value = true;
            errMsg.value = '';
        } finally {
            loading.value = false;
        }

        // await router.push({name:'Home'})
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        return;
    };

    const emailRequired = emailRequiredRule();
    const passwordRequired = passwordRequiredRule();
    const rules:any = reactive({
        email: [{validator: errorMsgFeildEmail}, emailRequired, emailRule()],
        pwd: [{validator: errorMsgFeildEmail}, passwordRequired],
        validMsg: [{validator: errorMsgFeildInvalid}],
    });

    const loginCheck = computed(() => {
        return store.state.auth.isLogin;
    });
    const onlyLoginUser = () => {
        if(loginAlert()){
            return false;
        }
    };

    const moveLoginPage = async () => {
        await router.push({name: 'LoginView'})
    }

    return {
        loginForm,
        rules,
        formRef,
        onFinish,
        onFinishFailed,
        loading,
        myData,
        fetchMyData,
        loginNickName,
        loginCheck,
        onlyLoginUser,
        isError,
        moveLoginPage
    };
};

export const useUserLoginInfo = async () => {
    const userName = computed(() => store.state.auth.name);
    const myDataResponse: any = await getIsLoginInfoApi();

    await store.dispatch('auth/loggedIn', myDataResponse);

    return {
        userName,
        myDataResponse,
    };
};
