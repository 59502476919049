<template>
  <gaze-modal
      :visible="true"
      class="warning-popup"
      :is-header="false"
  >
    <template #content="{toggleModal}">
      <div class="content-area">
        <div class="warning-message">{{ $t(message) }}</div>
        <button
            v-if="checkPublicYn!=='publicYn'"
            class="gaze-button-sec confirm-button"
            @click="toggleModal(false); closePopup();"
        >{{$t('modal.confirmModal.check')}}</button>
        <button
            v-else
            class="gaze-button-sec confirm-button"
            @click="toggleModal(false); closePopup(); isPublicYn();"
        >{{$t('modal.confirmModal.check')}}</button>
      </div>
    </template>
  </gaze-modal>
</template>

<script>
import {computed, defineComponent, ref} from "vue";
import GazeModal from "@/components/common/modal/GazeModal";
import {store} from "@/store";
import { closePopup } from "@/script/common/popupScript";
import router from "@/router";

export default defineComponent({
  name: "WarningPopup",
  emits: ["canceled"],
  props: ["wallet"],
  components: {
    GazeModal,
  },
  setup(props, context) {
    const message = computed(() => {
      return store.state.open.popup.warning.message
    })

    const checkPublicYn = computed(()=>{
      return store.state.open.popup.warning.type
    })

    const isPublicYn =()=>{
      router.back()
    }

    return {
      message,
      closePopup,
      checkPublicYn,
      isPublicYn
    }
  }

})
</script>

<style scoped>

</style>