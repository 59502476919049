import {computed, reactive, ref, watch} from "vue";
import {getAllNftsApi} from "@/api/nftApis";
import {useStore} from "@/store";
import {getHomeSearchApi} from "@/api/homeApi";
import {useI18n} from "vue-i18n";

export const useHeaderSearch = () => {
    const loading = ref()
    const store = useStore()
    const searchKeyword = reactive({
        keyword:''
    })



    const searchValue = ref()
    const searchNftList = computed(()=>{
        return store.state.search.nftList
    })
    const searchCltnList = computed(()=>{
        return store.state.search.cltnList
    })
    const searchMemberList = computed(()=>{
        return store.state.search.memberList
    })

    const searchNftListLength = ref()
    const searchCltnListLength = ref()
    const searchMemberListLength = ref()

    const fetchHomeSearchList = async (request: any) => {
        loading.value = true;
        if(request===''){
            return
        }

        const res = await getHomeSearchApi(request);
        if (res) {
            await store.dispatch("search/applySearchKeyword", request);
            await store.dispatch("search/applySearchNftList", res.nftList);
            await store.dispatch("search/applySearchCltnList", res.cltnList);
            await store.dispatch("search/applySearchMemberList", res.userList);
            searchNftListLength.value = res.nftTotal
            searchCltnListLength.value = res.cltnTotal
            searchMemberListLength.value = res.userTotal
        }

        loading.value = false;
    };

    const resetSearchKeyword = async () =>{

        searchKeyword.keyword = ''
        await store.dispatch("search/deleteSearchKeyword");

        // await fetchHomeSearchList(searchKeyword.keyword)
    }



    return {
        fetchHomeSearchList,
        searchValue,
        searchKeyword,
        resetSearchKeyword,
        searchNftList,
        searchCltnList,
        searchMemberList,
        searchNftListLength,
        searchCltnListLength,
        searchMemberListLength
    }
}

export const useHeaderFilter = () => {
    const loading = ref()
    const store = useStore()
    const searchKeyword = reactive({
        keyword:''
    })



    const searchValue = ref()
    const searchNftList = computed(()=>{
        return store.state.search.nftList
    })
    const searchCltnList = computed(()=>{
        return store.state.search.cltnList
    })
    const searchMemberList = computed(()=>{
        return store.state.search.memberList
    })
    const userProfileImg = computed(()=>{
        return store.state.auth.userProfileImg
    })
    const searchNftListLength = ref()
    const searchCltnListLength = ref()
    const searchMemberListLength = ref()





    const fetchHomeSearchList = async (request: any) => {
        loading.value = true;
        if(request===''){
            return
        }

        const res = await getHomeSearchApi(request);
        if (res) {
            await store.dispatch("search/applySearchKeyword", request);
            await store.dispatch("search/applySearchNftList", res.nftList);
            await store.dispatch("search/applySearchCltnList", res.cltnList);
            await store.dispatch("search/applySearchMemberList", res.userList);
            searchNftListLength.value = res.nftTotal
            searchCltnListLength.value = res.cltnTotal
            searchMemberListLength.value = res.userTotal
        }

        loading.value = false;
    };

    const resetSearchKeyword = async () =>{

        searchKeyword.keyword = ''
        await store.dispatch("search/deleteSearchKeyword");

        // await fetchHomeSearchList(searchKeyword.keyword)
    }


    const nftShoppingHoverMenu:any = [
        {
            icon: require('@/assets/image/icons/header/shopping-hover/sub-cards.png'),
            title: 'common.header.shoppingHover.category.curation',
            tag:'GC',
            index: 0,
        },
        {
            icon: require('@/assets/image/icons/header/shopping-hover/coin.svg'),
            title: 'common.header.shoppingHover.category.underTen',
            tag:'MAXPRICEWON',
            index: 2,
        },
        {
            icon: require('@/assets/image/icons/header/shopping-hover/digital-art.svg'),
            title: 'common.header.shoppingHover.category.digitalArt',
            tag:'PICTURE',
            index: 5,
        },
        {
            icon: require('@/assets/image/icons/header/shopping-hover/ticket.svg'),
            title: 'common.header.shoppingHover.category.memberTicket',
            tag:'TICKET',
            index: 4,
        },
        {
            icon: require('@/assets/image/icons/header/shopping-hover/wine.svg'),
            title: 'common.header.shoppingHover.category.wine',
            tag:'WINE',
            index: 3,
        },
        {
            icon: require('@/assets/image/icons/header/shopping-hover/finance.png'),
            title: 'common.header.shoppingHover.category.finance',
            tag:'FINANCE',
            index: 7,
        },
    ]



    return {
        fetchHomeSearchList,
        searchValue,
        searchKeyword,
        resetSearchKeyword,
        searchNftList,
        searchCltnList,
        searchMemberList,
        searchNftListLength,
        searchCltnListLength,
        searchMemberListLength,
        nftShoppingHoverMenu,
        userProfileImg
    }
}