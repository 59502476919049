
import {defineComponent} from 'vue';
import {useStore} from '@/store';
import HeaderOnlyLogo from './HeaderOnlyLogo.vue';
export default defineComponent({
  name: 'CommonLayout',
  components: {
    HeaderOnlyLogo,
  },
  setup() {
    const store = useStore();

    return {};
  },
});
