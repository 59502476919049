import {ref} from 'vue';
import {toggleBodyScroll} from '@/script/common/windowScript';
import {store} from '@/store';

export const toggleBackScroll = (isScroll: boolean) => {
  const body = document.body;
  if (isScroll) {
    const interval = setInterval(() => {
      if (body.querySelectorAll('.gaze-modal')?.length === 0) {
        toggleBodyScroll(true, store.state.open.modal.scrollY);
        clearInterval(interval);
      }
    }, 10);
    setTimeout(() => {
      clearInterval(interval);
    }, 1000);
  } else {
    if (body.style.position !== 'fixed') {
      store.commit('open/CONFIG_MODAL_SCROLL_Y', window.scrollY);
      toggleBodyScroll(false, store.state.open.modal.scrollY);
    }
  }
};

export const useModal = () => {
  const isOpen = ref(false);
  const toggleModal = (value: boolean) => {
    isOpen.value = value;
    if (value) {
      toggleBackScroll(false);
    } else {
      toggleBackScroll(true);
    }
  };
  return {
    toggleModal,
    isOpen,
    toggleBackScroll,
  };
};
export const usePendingModal = () => {
  const pendingType = {
    title: '결제 지연 안내',
    contents: '결제',
  };
  const getPendingType = (types: any) => {
    if (types === 'sale') {
      pendingType.title = '결제 지연 안내';
      pendingType.contents = '결제';
    }

    if (types === 'sign') {
      pendingType.title = '서명 지연 안내';
      pendingType.contents = '서명';
    }
    return pendingType;
  };
  return {
    pendingType,
    getPendingType,
  };
};

export const useWalletModal = () => {
  const body = document.body;
  console.log(
    "body.querySelectorAll('.ant-scrolling-effect')?.length",
    body.querySelectorAll('.ant-scrolling-effect')?.length
  );
  if (body.querySelectorAll('.ant-scrolling-effect')?.length !== 0) {
    body.style.overflow = 'unset !important';
  }
};
