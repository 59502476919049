import Caver from "caver-js";
import {store} from "@/store";
import { setKaikasInitEvent } from "@/script/klaytn/KaiaksEventScript";
import { openPopup } from "@/script/common/popupScript";
import {getWalletApi} from "@/api/walletApi";
import {Modal} from "ant-design-vue";
import {createVNode} from "vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {postNftTrstsRejectApi} from "@/api/nftApis";

export const loadWallet = async () => {
    const kaikasWallet = (window as any)["klaytn"];
    if (!kaikasWallet) {
        openPopup("walletInstall", { walletName: "KAIKAS" },'wallet')
        throw {
            code: -1,
            message: 'Kaikas가 설치되지 않았습니다. kaikas 크롬 확장프로그램을 설치가 필요합니다. 설치 후 새로고침 부탁드립니다.'
        }
    }
    const walletList = await kaikasWallet.enable();
    if (!store.state.wallet.kaikas.isEnabled) {
        setKaikasInitEvent(kaikasWallet)
    }
    store.commit("wallet/FETCH_KAIKAS_INFO", {isEnabled: true, address: walletList[0]});
    // const kaikasWalletList = await getWalletApi()
    // const filterdeWallet = kaikasWalletList.data.filter((item:any)=>item.walletChain==='KAIKAS')
    // const setAddress = filterdeWallet.map((address:any)=>{
    //     return address.walletAddress
    // })
    // if(setAddress.indexOf(walletList[0]) >-1){
    //     return kaikasWallet
    // }else{
    //     throw {
    //         isError: true,
    //         title: '등록된 지갑주소가 없습니다.',
    //         content: '지갑주소를 확인해주세요.',
    //         onOk() {
    //             // toggleConfirm(false)
    //         },
    //     }
    // }
    return kaikasWallet
};


export const useKlaytn = () => {
    const getAddress = () => {
        return store.state.wallet.kaikas.address
    }
    const getCaver = (kaiaks: any) => {
        return new Caver(kaiaks);
    }
    const signTransaction = async (abiAddress: any,price: number,nthId:string) => {
        const kaikasWallet = await loadWallet();
        const caver:any = getCaver(kaikasWallet);
        try{
            const result = await caver.rpc.klay.sendTransaction({
                type: 'SMART_CONTRACT_EXECUTION',
                from: getAddress(),
                to: abiAddress.address,
                gas: "400000",
                value: caver.utils.convertToPeb(price.toString() , 'KLAY'),
                data: abiAddress.abi
            })
            return result
        }
        catch (e:any) {
            alert( e.code )
            if (e?.status !== "0x1") {
                throw {
                    response: {
                        message: "modal.warningPopup.signTerminated"
                    }
                }
            }
            if (e.code === -32603){
                await postNftTrstsRejectApi(nthId)
                throw {response:{message:'modal.warningPopup.signDiscontinued'}}
            }
            throw {response:'sendTransaction에 실패했습니다.'}
        }
    }

    const getAccounts = async () => {
        const kaikas = await loadWallet();
        const caver = getCaver(kaikas);
        const response = await caver.rpc.klay.getAccounts((err, result) => {
            if (err) throw err;
            if (result) return result;
        })
        return response.toString();
    };
    const getBalance = async (address: string) => {
        try {
            // const kaikas = await loadKaikas();
            // const caver = getCaver(kaikas);
            // if (!(window as any["klaytn"])) {
            //     return "카이카스 확장프로그램 설치 필요"
            // } else {
            //     console.log(await caver.rpc.klay.accountCreated(address))
            //
            //     const result = Number(
            //         caver.utils.fromPeb(
            //             await caver.rpc.klay.getBalance(address),
            //             "KLAY"
            //         )
            //     );
            //     return result > 0 ? result : 0;
            // }
            return 0;

        } catch(e) {
            console.log(e)
            return "카이카스 확장프로그램 설치 필요"
        }
    };
    return {
        loadWallet,
        signTransaction,
        getAccounts,
        getBalance,
        getCaver
    };
};