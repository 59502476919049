
import {defineComponent, ref, computed, createVNode, onMounted, onBeforeUnmount} from 'vue';
import NavBar from './bar/NavBar.vue';
import HeaderMenuBar from '@/components/layout/bar/HeaderMenuBar.vue';
import {useStore} from '@/store';
import {useRoute, useRouter} from 'vue-router';
import {useMobileSideBar, useMobileNav, useMobileUserMenu} from '@/script/layout/navBarScript';
import LoggedInHoverMenuBar from '@/components/layout/bar/LoggedInHoverMenuBar.vue';
import GazeInput from '@/components/common/input/GazeInput.vue';

import {notification, Modal} from 'ant-design-vue';
import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import useDetectOutsideClick from '@/utils/outSideClick';
import {useBasket} from '@/script/basket/basketScript';
import {useHeaderSearch} from '@/script/common/headerSearchScript';
import {debounce, throttle} from 'lodash';

export default defineComponent({
  name: 'HeaderView',
  components: {
    // NavBar,
    // HeaderMenuBar
    // LoggedInHoverMenuBar,
    // GazeInput
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const test = ref();
    const route = useRoute();
    const testBu = () => {
      test.value = '';
    };
    const {
      searchValue,
      searchKeyword,
      fetchHomeSearchList,
      resetSearchKeyword,
      searchNftList,
      searchCltnList,
      searchMemberList,
      searchNftListLength,
      searchCltnListLength,
      searchMemberListLength,
    } = useHeaderSearch();

    const searchByKeyword = debounce(() => {
      fetchHomeSearchList(searchKeyword.keyword);
    }, 100);
    const globalSearchKeyword = computed(() => {
      return store.state.search.keyword;
    });
    const moveNftList = async (url: any) => {
      await router.push({
        name: 'NftListView',
        query: {name: url, keyword: globalSearchKeyword.value},
      });
      // window.location.reload()
    };

    const goHome = () => {
      window.location.href = '';
    };
    const closeModal = () => {
      menuOpen.value = false;
      window.location.reload();
    };
    const closeMenu = () => {
      dropdown.active.value = false;
    };
    const useIsLogin = computed(() => store.state.auth.isLogin);
    const menuOpen = ref(false);
    const isOpenNftShoppingHoverMenu = ref(false);
    const nftShoppingHoverMenu = [
      {
        icon: require('@/assets/image/icons/header/shopping-hover/sub-cards.png'),
        title: 'gaze 큐레이션',
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/wine.svg'),
        title: 'PFP'
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/digital-art.svg'),
        title: '사진',
      },

      {
        icon: require('@/assets/image/icons/header/shopping-hover/ticket.svg'),
        title: '티켓/패스',
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/coin.svg'),
        title: '10만원 이하',
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/3d.svg'),
        title: '3D',
      },
    ];
    const nftWallets = [
      {
        icon: require('@/assets/image/icons/header/shopping-hover/3d.svg'),
        title: 'KLAY',
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/3d.svg'),
        title: 'MATIC',
      },
    ];

    const cltnImg = [
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png'),
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png'),
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png'),
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png'),
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png'),
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png'),
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png'),
      },
      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png'),
      },

      {
        icon: require('@/assets/image/icons/header/shopping-hover/Ellipse35.png'),
      },
    ];
    const isMenuOpen = ref(false);

    const {isOpen, toggleSidebar} = useMobileSideBar();

    const userName = computed(() => store.state.auth.name);

    const dropdown = {
      active: ref(false),
      close: () => {
        dropdown.active.value = false;
      },
    };

    const {toggleBasket} = useBasket();
    const searchRef = ref();
    const openSearchBar = {
      active: ref(false),
      close: () => {
        openSearchBar.active.value = false;
      },
    };

    onBeforeUnmount(() => {
      document.removeEventListener('click', dropdown.close);
      document.removeEventListener('click', openSearchBar.close);
    });

    onMounted(() => {
      document.addEventListener('click', dropdown.close);
      document.addEventListener('click', openSearchBar.close);
    });

    const tempThumimg = {
      icon: require('@/assets/image/thumb.png'),
    };

    const moveDetailPage = async (type: any, id: any) => {
      if (type === 'nft') {
        await router.push(`/nft/${id}`);
        openSearchBar.active.value = false;
      }
      if (type === 'cltn') {
        await router.push(`/cltn/detail/${id}`);
        openSearchBar.active.value = false;
      }

      if (type === 'member') {
        await router.push(`/member/detail/${id}`);
        openSearchBar.active.value = false;
      }
    };

    return {
      isOpen,
      toggleSidebar,
      userName,
      router,
      isMenuOpen,
      nftShoppingHoverMenu,
      nftWallets,
      isOpenNftShoppingHoverMenu,
      cltnImg,
      useIsLogin,
      menuOpen,
      closeModal,
      dropdown,
      closeMenu,
      toggleBasket,
      openSearchBar,
      searchRef,
      searchValue,
      searchKeyword,
      searchByKeyword,
      resetSearchKeyword,
      test,
      testBu,
      searchNftList,
      searchCltnList,
      searchMemberList,
      tempThumimg,
      searchNftListLength,
      searchCltnListLength,
      searchMemberListLength,
      moveNftList,
      moveDetailPage,
    };
  },
});
