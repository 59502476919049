import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_svg = _resolveComponent("base-svg")!

  return (_openBlock(), _createBlock(_component_base_svg, {
    width: _ctx.width,
    height: _ctx.height,
    fill: _ctx.fill,
    viewBox: `0 0 32 32`
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M6.41394 7C5.63304 7 5 7.63304 5 8.41394C5 9.19484 5.63304 9.82789 6.41394 9.82789H25.5861C26.367 9.82789 27 9.19484 27 8.41394C27 7.63304 26.367 7 25.5861 7H6.41394ZM6.41394 14.3037C5.63304 14.3037 5 14.9367 5 15.7176C5 16.4985 5.63304 17.1315 6.41394 17.1315H25.5861C26.367 17.1315 27 16.4985 27 15.7176C27 14.9367 26.367 14.3037 25.5861 14.3037H6.41394ZM6.41394 21.6073C5.63304 21.6073 5 22.2404 5 23.0213C5 23.8022 5.63304 24.4352 6.41394 24.4352H25.5861C26.367 24.4352 27 23.8022 27 23.0213C27 22.2404 26.367 21.6073 25.5861 21.6073H6.41394Z",
        fill: _ctx.fill
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["width", "height", "fill"]))
}