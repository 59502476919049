import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [

    {
        path: "/mypage",
        name: "MyPageView",
        component: () =>
            import("@/components/pages/mypage/MyPageView.vue"),
        meta: {
            auth: true,
        },
    },
    {
        path: "/mypage/user_config",
        name: "UserConfig",
        component: () =>
            import("@/components/pages/mypage/user_config/UserConfigView.vue"),
        meta: {
            auth: true,
        },
    },
    {
        path: "/mypage/dashboard",
        name: "Dashboard",
        component: () =>
            import("@/components/pages/mypage/dashboard/DashboardView.vue"),
        meta: {
            auth: true,
        },
    },
];

export default routes