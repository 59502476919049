import {store} from "@/store";
import {PopupPayload} from "@/models/commonModels";
import {useLogin} from "@/script/login/loginScript";
import {computed} from "vue";
import router from "@/router";

export const openPopup = (name: string, payload: PopupPayload, type?:string,nftId?:string) => {
    store.commit("open/OPEN_POPUP", { name, payload,type,nftId });
}
export const closePopup = () => {
    store.commit("open/CLOSE_POPUP");
}

export const loginAlert = () => {
    const loginCheck = computed(() => {
        return store.state.auth.isLogin;
    });
    if(loginCheck.value!==true){
        openPopup('loginAlert', { title: 'modal.loginAlertPopup.title', contents: 'modal.loginAlertPopup.contents'})
        return true
    }
}