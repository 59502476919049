export const LOGIN_TOKEN = 'GRTTKN';

export const K_UNIT_ARRAY = [
    {
        stnd: 1000000000000,
        unit: "T"
    },
    {
        stnd: 1000000000,
        unit: "B"
    },
    {
        stnd: 1000000,
        unit: "M"
    },
    {
        stnd: 1000,
        unit: "K"
    },
];

export const MILISEC_TIME = {
    second: 1000,
    minute: 60 * 1000,
    hour: 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
}

export const SNS_GROUP = {
    facebook: {
        name: "userConfig.connectSns.facebook",
        linkIcon: require("@/assets/image/icons/sns/link/facebook.svg"),
        defaultUrl: "https://www.facebook.com/",
        userPattern: "(?:https?:\\/\\/)?(?:www\\.)?(mbasic.facebook|m\\.facebook|facebook|fb)\\.(com|me)\\/(?:(?:\\w\\.)*#!\\/)?(?:pages\\/)?(?:[\\w\\-\\.]*\\/)*([\\w\\-\\.]*)",
        shareLink: "http://www.facebook.com/sharer/sharer.php"
    },
    instagram: {
        name: "userConfig.connectSns.insta",
        linkIcon: require("@/assets/image/icons/sns/link/insta.svg"),
        defaultUrl: "https://www.instagram.com/",
        userPattern: "(?:https?:\\/\\/)?(?:www\\.)?(instagram)\\.(com|me)\\/(?:(?:\\w\\.)*#!\\/)?(?:pages\\/)?(?:[\\w\\-\\.]*\\/)*([\\w\\-\\.]*)",
    },
    twitter: {
        name: "userConfig.connectSns.twitter",
        linkIcon: require("@/assets/image/icons/sns/link/twitter.svg"),
        defaultUrl: "https://www.twitter.com/",
        userPattern: "(?:https?:\\/\\/)?(?:www\\.)?(twitter)\\.(com|me)\\/(?:(?:\\w\\.)*#!\\/)?(?:pages\\/)?(?:[\\w\\-\\.]*\\/)*([\\w\\-\\.]*)",
        shareLink: "https://twitter.com/intent/tweet",
    },
    discord: {
        name: "userConfig.connectSns.discord",
        linkIcon: require("@/assets/image/icons/sns/link/discord.svg"),
        defaultUrl: "https://www.discord.gg/",
        userPattern: "(?:https?:\\/\\/)?(?:www\\.)?(discord)\\.(com|me|gg)\\/(?:(?:\\w\\.)*#!\\/)?(?:pages\\/)?(?:[\\w\\-\\.]*\\/)*([\\w\\-\\.]*)",
    },
    youtube: {
        name: "userConfig.connectSns.youtube",
        linkIcon: require("@/assets/image/icons/sns/link/youtube.svg"),
        defaultUrl: "https://www.youtube.com/",
        userPattern: "(?:https?:\\/\\/)?(?:www\\.)?(youtube)\\.(com|me)\\/(?:(?:\\w\\.)*#!\\/)?(?:pages\\/)?(?:[\\w\\-\\.]*\\/)*([\\w\\-\\.]*)",
    },
};

export type snsType = keyof typeof SNS_GROUP;

export const COIN_GROUP = {
    KLAY: {
        icon: require("@/assets/image/icons/coin/klay.svg"),
        whiteIcon: require("@/assets/image/icons/coin/klay-white.svg")
    },
    MATIC: {
        icon: require("@/assets/image/icons/coin/matic.svg"),
        whiteIcon: require("@/assets/image/icons/coin/matic-white.svg")
    },
    default: {
        icon: require("@/assets/image/icons/notice.svg"),
        whiteIcon: "",
    }
}
const metaMaskAddress = process.env.VUE_APP_OPEN_DEEP_LINK_MATIC
const kaikasAddress = process.env.VUE_APP_OPEN_DEEP_LINK_KLAY
export const WALLET_GROUP = {
    METAMASK: {
        icon: require("@/assets/image/icons/wallet/metamask.svg"),
        whiteIcon: require("@/assets/image/icons/wallet/metamask-white.svg"),
        color: "#E98A32",
        korean: "메타마스크",
        english: 'Metamask',
        units: ["MATIC"],
        install: "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
        mobile: `${metaMaskAddress}/`,
        wallet: `${metaMaskAddress}/wallet/manage/`,
        register: `${metaMaskAddress}/login?target=/nft/`,
        singleBuy:`${metaMaskAddress}/login?target=/nft/`,
        multiBuy:`${metaMaskAddress}/login/`,
        update:`${metaMaskAddress}/login?target=/nft/`,
        cancel:`${metaMaskAddress}/login?target=/nft/`,
        offer:`${metaMaskAddress}/login?target=/nft/`,
        offerCancel:`${metaMaskAddress}/login?target=/nft/`,
        offerAccept:`${metaMaskAddress}/login?target=/mypage/dashboard/`,
        OfferReject:`${metaMaskAddress}/login?target=/mypage/dashboard/`,
    },
    KAIKAS: {
        icon: require("@/assets/image/icons/wallet/kaikas.svg"),
        whiteIcon: require("@/assets/image/icons/wallet/kaikas-white.svg"),
        color: "#3E63C3",
        korean: "카이카스",
        english: 'Kaikas',
        units: ["KLAY"],
        install: "https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi",
        mobile: `${kaikasAddress}/`,
        wallet: `${kaikasAddress}/wallet/manage/`,
        register: `${kaikasAddress}/login?target=/nft/`,
        singleBuy:`${kaikasAddress}/login?target=/nft/`,
        multiBuy:`${kaikasAddress}/login/`,
        update:`${kaikasAddress}/login?target=/nft/`,
        cancel:`${kaikasAddress}/login?target=/nft/`,
        offer:`${kaikasAddress}/login?target=/nft/`,
        offerCancel:`${kaikasAddress}/login?target=/nft/`,
        offerAccept:`${kaikasAddress}/login?target=/mypage/dashboard/`,
        OfferReject:`${kaikasAddress}/login?target=/mypage/dashboard/`,
    },
    default: {
        icon: "",
        color: "",
        korean: "",
        install: "",
        units: [],
    }
}

export const DASHBOARD_FILTER_GROUP = {
    SALE: {
        name: "dashFilter.SALE",
        icon: "for-sale",
        value: "SALE",
    },
    UNSALE: {
        name: "dashFilter.UNSALE",
        icon: "not-sale",
        value: "UNSALE"
    },
    OFFER_SEND: {
        name: "dashFilter.OFFER_SEND",
        icon: "hand",
        value: "OFFER_SEND"
    },
    OFFER_RECEIVE: {
        name: "dashFilter.OFFER_RECEIVE",
        icon: "ok-hand",
        value: "OFFER_RECEIVE"
    },
    SELL: {
        name: "dashFilter.SELL",
        icon: "sell",
        value: "SELL"
    },
    BUY: {
        name: "dashFilter.BUY",
        icon: "credit",
        value: "BUY"
    },
    ACCEPT: {
        name: "dashFilter.ACCEPT",
        icon: "ok-hand",
        value: "ACCEPT"
    },
    REJECT: {
        name: "dashFilter.REJECT",
        icon: "no-hand",
        value: "REJECT"
    },
    MODIFY: {
        name: "dashFilter.MODIFY",
        icon: "credit",
        value: "MODIFY"
    },
    OFFER_CANCEL: {
        name: "dashFilter.OFFER_CANCEL",
        icon: "delete-stroke",
        value: "OFFER_CANCEL"
    },

    TRANSFER: {
        name: "dashFilter.TRANSFER",
        icon: "credit",
        value: "TRANSFER"
    },
    REGISTER: {
        name: "dashFilter.REGISTER",
        icon: "sell",
        value: "REGISTER"
    },
    CANCEL: {
        name: "dashFilter.CANCEL",
        icon: "delete-stroke",
        value: "CANCEL"
    },
}

export const NFT_HISTORY_TYPE = {
    BUY: {
        name: "NFT 구매",
        url: require("@/assets/image/icons/timeline/history-buy.svg")
    },
    FIRST: {
        name: "최초 등록",
        url: require("@/assets/image/icons/timeline/history-first.svg")
    },
    REGISTER: {
        name: "판매 등록",
        url: require("@/assets/image/icons/timeline/history-register.svg"),
    },
    OFFER: {
        name: "가격 제안",
        url: require("@/assets/image/icons/timeline/history-suggest.svg"),
    },
    TRANSFER: {
        name: "NFT 지갑 전송",
        url: require("@/assets/image/icons/timeline/history-transfer.svg"),
    },
    ACCEPT: {
        name: "제안 수락",
        url: require("@/assets/image/icons/timeline/history-suggest-accept.svg"),
    },
   REJECT: {
        name: "제안 거절",
        url: require("@/assets/image/icons/timeline/history-suggest-reject.svg"),
    },
    OFFER_CANCEL: {
        name: "제안 취소",
        url: require("@/assets/image/icons/timeline/history-cancel.svg"),
    },
    CANCEL: {
        name: "판매 취소",
        url: require("@/assets/image/icons/timeline/history-cancel.svg"),
    },
    default: {
        name: "알 수 없음",
        url: require("@/assets/image/icons/timeline/history-suggest.svg"),
    }
};
export type nftHistoryType = keyof typeof NFT_HISTORY_TYPE;


export const NFT_STATUS_GROUP = {
    SALE: {
        name: "nftStatusFilter.SALE",
        result:'',
        icon: "for-sale",
        value: "SALE",
    },
    UNSALE: {
        name: "nftStatusFilter.UNSALE",
        result:'',
        icon: "not-sale",
        value: "UNSALE"
    },
    SELL: {
        name: "nftStatusFilter.SELL",
        result:'',
        icon: "sell",
        value: "SELL"
    },
    BUY: {
        name: "nftStatusFilter.BUY",
        result:'',
        icon: "credit",
        value: "BUY"
    },
    OFFER_SEND: {
        name: "nftStatusFilter.OFFER_SEND",
        result:'',
        icon: "hand",
        value: "OFFER_SEND"
    },
    OFFER_RECEIVE: {
        name: "nftStatusFilter.OFFER_RECEIVE",
        result:'',
        icon: "ok-hand",
        value: "OFFER_RECEIVE"
    },
    OFFER: {
        name: "nftStatusFilter.OFFER",
        result:'',
        icon: "hand",
        value: "OFFER"
    },
    ACCEPT: {
        name: "nftStatusFilter.ACCEPT",
        result:'',
        icon: "ok-hand",
        value: "ACCEPT"
    },
    REJECT: {
        name: "nftStatusFilter.REJECT",
        result:'',
        icon: "no-hand",
        value: "REJECT"
    },
    OFFER_CANCEL: {
        name: "nftStatusFilter.OFFER_CANCEL",
        result:'',
        icon: "delete-stroke",
        value: "OFFER_CANCEL"
    },
    TRANSFER: {
        name: "nftStatusFilter.TRANSFER",
        result:'',
        icon: "transfer",
        value: "TRANSFER"
    },
    REGISTER: {
        name: "nftStatusFilter.REGISTER",
        result:'',
        icon: "credit",
        value: "REGISTER"
    },
    MODIFY: {
        name: "nftStatusFilter.MODIFY",
        result:'',
        icon: "modify",
        value: "MODIFY"
    },
    CANCEL: {
        name: "nftStatusFilter.CANCEL",
        result:'',
        icon: "delete-stroke",
        value: "CANCEL"
    },
    default: {
        name: "",
        result:'',
        icon: "",
        value: ""
    }
}
export const NFT_RESULT_GROUP = {
    SUCCESS: {
        name: "nftResultGroup.SUCCESS",
        icon: "credit",
        value: "SUCCESS"
    },
    FAIL: {
        name: "nftResultGroup.FAIL",
        icon: "transfer-fail",
        value: "FAIL"
    },
    PENDING: {
        name: "nftResultGroup.PENDING",
        icon: "transfer-pending",
        value: "PENDING"
    },
    default: {
        name: "",
        icon: "",
        value: ""
    }
}

export const NFT_SIGN_RESULT_GROUP = {
    SUCCESS: {
        name: "서명 성공",
        icon: "sign-success",
        value: "SUCCESS"
    },
    FAIL: {
        name: "서명 실패",
        icon: "sign-fail",
        value: "FAIL"
    },
    PENDING: {
        name: "서명 대기중",
        icon: "sign-pending",
        value: "PENDING"
    },
    default: {
        name: "",
        icon: "",
        value: ""
    }
}
