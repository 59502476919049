import {reactive, ref, computed} from 'vue';
import navBarMenu from '@/components/layout/bar/navBarMenu';
import headerMarketMenu from '@/components/layout/bar/headerMarketMenu';
import {useRouter} from 'vue-router';
import {useStore} from '@/store';
import {NavMenuItem} from '@/models/commonModels';

export const useNavbar = () => {
  const store = useStore();
  const router = useRouter();

  const isOpen = computed(() => store.state.open.navbar.isOpen);
  const headerElement = ref();
  const navElement = ref();
  const navBarElement = ref();
  const toggleNav = (status: boolean) => {
    if (status) {
      const style = getComputedStyle(navElement.value);
      navElement.value.style.width = `${headerElement.value.offsetWidth + 50}px`;
    }
    store.commit('open/CONFIG_NAVBAR', status);
  };
  const routeNavbar = (routeName: string) => {
    router.push({name: routeName});
    store.commit('open/CONFIG_NAVBAR', false);
  };

  return {
    isOpen,
    toggleNav,
    routeNavbar,
    headerElement,
    navElement,
    navBarElement,
    navBarMenu,
  };
};

export const useMobileSideBar = () => {
  const store = useStore();
  const router = useRouter();

  const isOpen = computed(() => {
    return store.state.open.mobileSidebar.isOpen;
  });
  const isShoppingOpen = computed(() => {
    return store.state.open.mobileSidebar.isShoppingOpen;
  });

  const isSearchOpen = computed(() => store.state.open.mobileSearch.isOpen);
  const isSomethingOpen = computed(() => {
    if (isSearchOpen.value || isShoppingOpen.value) {
      return true;
    } else {
      return false;
    }
  });
  const toggleSidebar = () => {
    store.commit('open/TOGGLE_MOBILE_SIDEBAR');
    // store.commit('open/TOGGLE_MOBILE_SIDEBAR_SHOPPING')
  };

  const toggleSidebarDetail = (detail: any) => {
    if (detail === 'nftShopping') {
      store.commit('open/TOGGLE_MOBILE_SIDEBAR_SHOPPING');
    } else if (detail === 'nftSearch') {
      store.commit('open/TOGGLE_MOBILE_SIDEBAR_SEARCH');
    }
  };
  const mobileIconRouter = (path: any) => {
    router.push({name: path});
    toggleSidebar();
  };

  return {
    isOpen,
    isShoppingOpen,
    toggleSidebar,
    mobileIconRouter,
    toggleSidebarDetail,
    isSomethingOpen,
    isSearchOpen,
  };
};

export const useMobileUserMenu = () => {
  const store = useStore();

  const isOpen = computed(() => {
    return store.state.open.mobileUserMenu.isOpen;
  });
  const toggleUserMenu = () => {
    store.commit('open/TOGGLE_MOBILE_USER_MENU');
  };
  return {
    isOpen,
    toggleUserMenu,
  };
};

export const useMobileNav = () => {
  const store = useStore();
  const router = useRouter();

  const menuList = reactive(
    navBarMenu.map((menu: any) => {
      return {
        ...menu,
        isOpen: false,
      };
    })
  );

  const sidebarRouter = (routerName: string) => {
    store.commit('open/CONFIG_MOBILE_SIDEBAR', false);
    store.commit('open/CONFIG_MOBILE_USER_MENU', false);
    router.push({name: routerName});
  };

  const clickMenu = (menu: NavMenuItem, index: number) => {
    if (menu.subMenu) {
      menuList[index].isOpen = !menuList[index].isOpen;
    } else {
      sidebarRouter(menu.route);
    }
  };

  return {
    menuList,
    clickMenu,
    sidebarRouter,
  };
};

export const useHeaderMenuNav = () => {
  const menuList = reactive(
    headerMarketMenu.map((menu: any) => {
      return {
        ...menu,
      };
    })
  );

  return {
    menuList,
  };
};

export const useMobileHeaderMenuNav = () => {
  const menuList = reactive(
    headerMarketMenu.map((menu: any) => {
      return {
        ...menu,
      };
    })
  );

  return {
    menuList,
  };
};
