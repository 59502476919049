<template>
  <gaze-modal
      :visible="true"
      class="login-alert-popup"
      :is-header="false"
  >
    <template #content="{toggleModal}">
      <div class="content-area">
        <div class="warning-message">{{ $t(title) }}</div>
        <div class="warning-message-contents">
          {{$t(contents)}}
        </div>
<!--        <div class="warning-name-section">-->
<!--          <div class="warning-name"  v-for="(nftName , index) in name" :key="index">-->
<!--            {{nftName}}-->
<!--          </div>-->
<!--        </div>-->
        <button
            class="gaze-button-sec confirm-button"
            @click="toggleModal(false);goToLogin(); closePopup();"
        >{{$t('modal.loginAlertPopup.ok')}}</button>
      </div>
    </template>
  </gaze-modal>
</template>

<script>
import {computed, defineComponent, ref} from "vue";
import GazeModal from "@/components/common/modal/GazeModal";
import {store} from "@/store";
import { closePopup } from "@/script/common/popupScript";
import router from "@/router";

export default defineComponent({
  name: "LoginAlertPopup",
  emits: ["canceled"],
  props: ["wallet"],
  components: {
    GazeModal,
  },
  setup(props, context) {
    const title = computed(() => {
      return store.state.open.popup.loginAlert.title
    })

    const contents = computed(() => {
      return store.state.open.popup.loginAlert.contents
    })

    const goToLogin =()=>{
      router.push({name:'LoginView'})
    }


    return {
      title,
      contents,
      closePopup,
      goToLogin
    }
  }

})
</script>

<style scoped>

</style>