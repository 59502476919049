import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/wait',
        name: 'WaitView',
        component: () => import('@/components/pages/login/LoginWaitView.vue'),
        meta: {
            isVerifiedEmail: false,
        },
    },

    {
        path: '/verified',
        name: 'VeridiedView',
        component: () => import('@/components/pages/login/LoginVerifiedView.vue'),
        meta: {
            isVerifiedEmail: true,
        },
    },

    {
        path: '/expired',
        name: 'ExpiredView',
        component: () => import('@/components/pages/login/LoginExpiredView.vue'),
        meta: {
            isVerifiedEmail: false,
        },
    },

    {
        path: '/unverified',
        name: 'UnverifiedView',
        component: () => import('@/components/pages/login/LoginUnverifiedView.vue'),
        meta: {
            isVerifiedEmail: false,
            login:true
        },
    },
];

export default routes