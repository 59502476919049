
import {defineComponent, computed, onMounted, watch} from 'vue';
import {useRoute} from 'vue-router';
import CommonLayout from './components/layout/CommonLayout.vue';
import BlackHeaderLayout from '@/components/layout/BlackHeaderLayout.vue';
import BasketView from '@/components/pages/basket/BasketView.vue';
import BlackHeaderWithoutFooterLayout from '@/components/layout/BlackHeaderWithoutFooterLayout.vue';
import GazeAppLoading from "@/components/common/loading/GazeAppLoading.vue";
import {store} from "@/store";
import MobileSystemHeaderLayout from '@/components/layout/mobile/MobileSystemHeaderLayout.vue';
import OnlyLogoWithoutFooterLayout from '@/components/layout/OnlyLogoWithoutFooterLayout.vue';
import LoadingTransferView from "@/components/pages/loading/LoadingTransferView.vue";
import PopupView from "@/components/pages/popup/PopupView.vue";
import {useI18n} from "vue-i18n";
export default defineComponent({
  name: 'App',
  components: {
    BasketView,
    CommonLayout,
    BlackHeaderLayout,
    BlackHeaderWithoutFooterLayout,
    MobileSystemHeaderLayout,
    GazeAppLoading,
    OnlyLogoWithoutFooterLayout,
    LoadingTransferView,
    PopupView,
  },
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      switch (route.meta.layout) {
        case 'black-header':
          return 'black-header-layout';
        case 'black-header-w/o-footer':
          return 'black-header-without-footer-layout';
        case 'header-only-logo':
          return 'only-logo-without-footer-layout';
        case 'mobile-system-header-layout':
          return 'mobile-system-header-layout';
        default:
          return 'common-layout';
      }
    });

    const popup = computed(() => {
      return store.state.open.popup;
    })

    const { locale } = useI18n();

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // resize
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    const loading = computed(() => {
      return store.getters['loading/getAppLoading']
    })

    const transferLoading = computed(()=>{
      return store.state.payment.transferLoading
    })
    const getLan = localStorage.getItem('setLang')
    if(getLan) {
      locale.value = getLan
    }
    return {
      popup,
      route,
      layout,
      loading,
      transferLoading
    };
  },
});
