import apiCaller from "@/api/index";

export async function getHomeNftsApi(request:any) {
    const response = await apiCaller.get("/nfts?:page?:size", { params:{page : request.page,size:request.size}}, {});
    return response.data;
}

export async function getHomeDataApi() {
    const response = await apiCaller.get("/home", {}, {});
    return response.data;
}

export async function getHomeSearchApi(request: any) {
    const response = await apiCaller.get("/home/search", {query:{keyword:request}}, {});
    return response.data;
}

export async function getHomeBannerApi(){
    const response = await apiCaller.get('/home/banners',{},{})
    return response.data
}