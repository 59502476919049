import {Module} from 'vuex';
import {RootState} from '../index';
import {NftDcpt, NftDetail, NftHistory} from '@/models/nftModels';
import {getAllNftsApi, getNftDetailApi, getNftHistoryApi} from '@/api/nftApis';
import dayjs from '@/utils/dayjs';
import {SNS_GROUP, snsType} from '@/config/constants';
import {convertToKUnit, toLocalString} from '@/utils/unitUtils';
import {hasSameItem} from '@/utils/arrUtils';
import {lowerFirst, snakeCase} from 'lodash';
import {displayRational} from '@/utils/stringUtil';

export interface NftState {
  detail: NftDetail;
  history: {
    data: NftHistory[];
    pageInfo: {
      size: number;
      page: number;
      totalPage: number;
    };
    statusList: string[];
  };
}

export const nftModule: Module<NftState, RootState> = {
    namespaced: true,
    state: {
        detail: {
            id: "",
            name:"",
            rsrcPath: "",
            nftName: "",
            cltnName: "",
            price: 0,
            priceUnit: "",
            publicYn: false,
            krwPrice: 0,
            highestOfferPrice: 0,
            highestOfferKrwPrice: 0,
            highestOfferPriceUnit: "",
            lastTransferPrice: 0,
            lastTransferKrwPrice: 0,
            lastTransferPriceUnit: "",
            explain: "",
            dcpt: [],
            otherNftList: [],
            cltnId: "",
            saleEndDate: "",
            status:'',
            isItrt: false,
            isMyNft: false,
            isMyOffer: false,
            offerAddress:'',
            offerYn: false,
            offerId: "",
            user:{
                name:''
            },
            cltn: {
                displayName: "",
                count:0,
                ownerName:'',
                nftCount: 0,
                nftOwnerCount: 0,
                tradingHistories: 0,
                lowestPrice: 0,
                lowestPriceUnit: "",
                benefit: [],
                royalty: 0,
                tokenStnd: "",
                blockchain: "",
                bannerRsrcPath: "",
                rsrcPath: "",
                socialLinks: {},
                isMyCltn:false,
                holderCount:0
            },
            tradingHistories: 0,
            lowestPrice: 0,
            lowestPriceUnit: "",
            ownerWalletAddress: "",
            ownerUserName: "",
            ownerUserId: "",
            itrts: 0,
            userProfileImg:'',
            expiredDate:'',
            tradeCount:0,
            holderCount:0
        },
        history: {
            data: [],
            pageInfo: {
                size: 7,
                page: 1,
                totalPage: 1,
            },
            statusList: [],
        },
  },
  mutations: {
    FETCH_NFT_DETAIL: (state, payload) => {
      state.detail = payload;
    },
    FETCH_OTHER_NFT_LIST: (state, payload) => {
      state.detail.otherNftList = payload;
    },
    FETCH_NFT_HISTORY: (state, {total, data}) => {
      state.history.pageInfo.totalPage = Math.ceil(total / state.history.pageInfo.size);
      if (state.history.pageInfo.page === 1) state.history.data = data;
      else state.history.data.push(...data);
    },
    FETCH_NFT_ITRT: (state, isItrt) => {
      state.detail.isItrt = isItrt;
      state.detail.itrts += isItrt ? 1 : -1;
    },
  },
  actions: {
    fetchNftDetail: async ({commit, state}, nftId) => {
      commit('FETCH_NFT_DETAIL', await getNftDetailApi(nftId));
      const {data} = await getAllNftsApi({page: 1, size: 4, cltnId: state.detail.cltnId});
      commit('FETCH_OTHER_NFT_LIST', data);
      const {page, size} = state.history.pageInfo;
      commit('FETCH_NFT_HISTORY', await getNftHistoryApi(nftId, page, size, []));
    },
    fetchNftHistory: async ({commit, state}, statusList: string[]) => {
      if (!hasSameItem(state.history.statusList, statusList)) {
        state.history.pageInfo.page = 0;
        state.history.statusList = statusList;
      }
      state.history.pageInfo.page += 1;
      const {page, size} = state.history.pageInfo;
      commit('FETCH_NFT_HISTORY', await getNftHistoryApi(state.detail.id, page, size, statusList));
    },
  },
  getters: {
    getNftPriceInfo: state => {
      const highestPrice =
        state.detail.highestOfferPrice === undefined
          ? null
          : {
              price: toLocalString(state.detail.highestOfferPrice, ' - '),
              krwPrice: toLocalString(state.detail.highestOfferKrwPrice, ' - '),
              unit: state.detail.highestOfferPriceUnit
                ? state.detail.highestOfferPriceUnit
                : state.detail.priceUnit,
            };
      const lastPrice =
        state.detail.lastTransferPrice === undefined
          ? null
          : {
              price: toLocalString(state.detail.lastTransferPrice, ' - '),
              krwPrice: toLocalString(state.detail.lastTransferKrwPrice, ' - '),
              unit: state.detail.lastTransferPriceUnit
                ? state.detail.lastTransferPriceUnit
                : state.detail.priceUnit,
            };
      return {
        userProfileImg: state.detail.userProfileImg,
        status: state.detail.status,
        nftId: state.detail.id,
        cltnName: state.detail.cltn.displayName,
        cltnId: state.detail.cltnId,
        nftName: state.detail.name,
        price: state.detail.price !== null ? displayRational(state.detail.price.toString()) : '-',
        priceUnit: state.detail.priceUnit,
        krwPrice: toLocalString(state.detail.krwPrice, ' - '),
        itrts: state.detail.itrts,
        isMyOffer: state.detail.isMyOffer,
        isMyNft: state.detail.isMyNft,
        isItrt: state.detail.isItrt,
        offerYn: state.detail.offerYn,
        offerId: state.detail.offerId,
        ownerWalletAddress: state.detail.ownerWalletAddress,
        highestPrice,
        lastPrice,
        ownerUserName: state.detail.user?.name ? state.detail.user?.name : '',
        ownerUserId: state.detail.ownerUserId,
        leftDay: dayjs(state.detail.saleEndDate).diff(dayjs(), 'day'),
        rsrcPath: state.detail.rsrcPath,
        expiredDate: state.detail.expiredDate,
          offerAddress: state.detail.offerAddress
      };
    },
    getNftDcptList: state => {
      return state.detail.dcpt
        ? state.detail.dcpt.map((dcpt: NftDcpt) => {
            return {
              chrtKey: dcpt.traitType,
              value: dcpt.value,
              ratio: dcpt.percent,
            };
          })
        : [];
    },
    getNftCltnDetail: state => {
      const cltn = state.detail.cltn;
      const snsLinks = cltn.socialLinks
        ? Object.entries(cltn.socialLinks).map(([sns, link]) => {
            return {
              icon: SNS_GROUP[sns as snsType].linkIcon,
              link,
              alt: sns,
            };
          })
        : {};

      return {
        cltnId: state.detail.cltnId,
        name: cltn.displayName,
        unit: state.detail.priceUnit,
        nftCount: convertToKUnit(cltn.count, ' - '),
        ownerCount: convertToKUnit(cltn.nftOwnerCount, ' - '),
        ownerName: cltn.ownerName ? cltn.ownerName : ' - ',
        tradingCount: convertToKUnit(state.detail.tradeCount, ' - '),
        lowestPrice: toLocalString(state.detail.lowestPrice, ' - '),
        lowestPriceUnit: cltn.lowestPriceUnit,
        cltnImage: cltn.rsrcPath,
          holderCount: cltn.holderCount,
        snsLinks,
      };
    },
    getNftTimeline: state => {
      const ownerAddress = state.detail.ownerWalletAddress;
      return state.history.data.map(history => {
        const commonInfo: any = {
          type: history.type,
          header: dayjs(history.createdAt).format('YYYY-MM-DD HH:mm'),
          price: history.price,
          priceUnit: history.priceUnit,
          trstHash: history.trstHash,
        };
        if (history.fromWalletAddress)
          commonInfo.from = {
            profileImg: history.fromUserProfileImg,
            name: history.fromUserName ? history.fromUserName : history.fromWalletAddress,
            isOwner: ownerAddress === history.fromWalletAddress,
          };
        if (history.toWalletAddress)
          commonInfo.to = {
            profileImg: history.toUserProfileImg,
            name: history.toUserName ? history.toUserName : history.toWalletAddress,
            isOwner: ownerAddress === history.toWalletAddress,
          };
        return commonInfo;
      });
    },
  },
};
