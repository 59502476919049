// import { DeleteProductTag, ProductTag } from "@/models/productModels";
import {ProductList} from "@/models/productModels";


import {Module} from "vuex";
import {RootState} from "../index";


export interface memberState {
    userList:Array<any>,
    itrtNftList:Array<any>,
    itrtCltnList:Array<any>
    haveNftTotal:any,
    itrtNftTotal:any,
    itrtCltnTotal:any
}

export const memberModule: Module<memberState, RootState> = {
    namespaced: true,
    state: () => ({
        userList:[],
        itrtNftList:[],
        itrtCltnList:[],
        haveNftTotal :0,
        itrtNftTotal:0,
        itrtCltnTotal:0

    }),
    mutations: {

        ADD_USER_HAVE_NFT_LIST : (state, item)=>{
            state.userList.push(...item)
        },

        DELETE_USER_HAVE_NFT_LIST: (state, item)=>{
            state.userList = []
        },

        ADD_USER_HAVE_NFT_TOTAL : (state, item)=>{
            state.haveNftTotal = item
        },

        ADD_USER_ITRT_NFT_LIST : (state, item)=>{

            state.itrtNftList.push(...item)
        },

        DELETE_USER_ITRT_NFT_LIST: (state, item)=>{
            state.itrtNftList = []
        },
        ADD_USER_ITRT_NFT_TOTAL : (state, item)=>{

            state.itrtNftTotal = item
        },

        ADD_USER_ITRT_CLTN_LIST : (state, item)=>{

            state.itrtCltnList.push(...item)
        },

        DELETE_USER_ITRT_CLTN_LIST: (state, item)=>{
            state.itrtCltnList = []
        },
        ADD_USER_ITRT_CLTN_TOTAL : (state, item)=>{

            state.itrtCltnTotal = item
        },




    },
    actions: {

        applyUserHaveNftList:({commit}, nft) => {

            commit("ADD_USER_HAVE_NFT_LIST", nft)
        },
        deleteUserHaveNftList:({commit})=>{
            commit("DELETE_USER_HAVE_NFT_LIST")
        },
        applyUserItrtNftList:({commit}, nft) => {

            commit("ADD_USER_ITRT_NFT_LIST", nft)
        },
        deleteUserItrtNftList:({commit})=>{
            commit("DELETE_USER_ITRT_NFT_LIST")
        },

        applyUserItrtCltnList:({commit}, nft) => {

            commit("ADD_USER_ITRT_CLTN_LIST", nft)
        },
        deleteUserItrtCltnList:({commit})=>{
            commit("DELETE_USER_ITRT_CLTN_LIST")
        },

        applyUserHaveNftTotal:({commit}, nft) => {

            commit("ADD_USER_HAVE_NFT_TOTAL", nft)
        },
        applyUserItrtNftTotal:({commit}, nft) => {

            commit("ADD_USER_ITRT_NFT_TOTAL", nft)
        },
        applyUserItrtCltnTotal:({commit}, nft) => {

            commit("ADD_USER_ITRT_CLTN_TOTAL", nft)
        },

    },
    getters: {},
};
