import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "item-info" }
const _hoisted_2 = { class: "item-price" }
const _hoisted_3 = { class: "price-unit" }
const _hoisted_4 = { class: "item-footer-row" }
const _hoisted_5 = { class: "disable-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nft_image_or_video_small = _resolveComponent("nft-image-or-video-small")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.className)
  }, [
    _createVNode(_component_nft_image_or_video_small, { nft: _ctx.nft }, null, 8, ["nft"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          class: "item-name pointer-hover",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'NftDetailView', params: { nftId: _ctx.nft.id }})))
        }, _toDisplayString(_ctx.nft.nftName), 1),
        _createElementVNode("div", {
          class: "cltn-name pointer-hover",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'CltnDetailView', params: { id: _ctx.nft.cltnId }})))
        }, _toDisplayString(_ctx.nft.cltnName), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.toLocalString(_ctx.nft.price)), 1),
          _createElementVNode("span", _hoisted_3, "  " + _toDisplayString(_ctx.nft.priceUnit), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('basket.cardComp.saleImpossible')), 1),
          _createElementVNode("div", {
            class: "delete-button pointer-hover",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteBasketItem(_ctx.nft.id)))
          }, _toDisplayString(_ctx.$t('basket.cardComp.remove')), 1)
        ])
      ])
    ])
  ], 2))
}