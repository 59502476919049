import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/register',
        name: 'RegisterView',
        component: () => import('@/components/pages/register/RegisterView.vue'),
    },
];

export default routes