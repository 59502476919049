import {RouteRecordRaw} from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/abt',
    name: 'AboutView',
    component: () => import('@/components/pages/about/AboutView.vue'),
    // meta: {
    //   layout: 'black-header-w/o-footer',
    // },
    meta: {
      layout: "black-header",
    },
  },
];

export default routes;
