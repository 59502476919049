<template>
  <div
      class="gaze-pending-modal"
      v-if="isOpen"
      @click="toggleModal(false)"
  >
    <div class="modal-content" @click.stop="() => {}">
      <div class="modal-header" v-if="isHeader">
        <div class="modal-title">{{ title }}</div>
        <img
            src="@/assets/image/icons/close.svg"
            class="close-button pointer-hover"
            @click="toggleModal(false)"
        >
      </div>
      <!--      <slot name="content" :toggleModal="toggleModal"></slot>-->
      <div class="pending-contents-section">
        <div class="pending-logo">
          <img src="@/assets/image/icons/payment/partial-success.png" alt="">
        </div>
        <div class="pending-desc">
          <div>
            {{$t('modal.pendingModal.ko.subtitle1')}} <br>
            <span class="pending-bold"> {{contents}} {{$t('modal.pendingModal.ko.subtitle2')}}</span>{{$t('modal.pendingModal.ko.subtitle3')}} {{$t('modal.pendingModal.en.subtitle1')}}
          </div>
        </div>
        <div class="pending-notice-section" >
          <div class="notice-wrapper">
            <div class="notice-icon">
              <img src="@/assets/image/icons/payment/offer/notice.png" alt="">
            </div>
            <div class="notice-text">
              {{$t('modal.pendingModal.ko.notice1')}}{{$t('modal.pendingModal.en.notice1')}}<br> {{$t('modal.pendingModal.en.notice2')}}{{contents}} {{$t('modal.pendingModal.ko.notice2')}}{{$t('modal.pendingModal.en.notice3')}}
            </div>
          </div>

        </div>

        <div class="pending-btn-section">
          <div class="pending-shopping-btn pointer-hover" @click="moveToNftList">
            <div class="">
              {{$t('modal.pendingModal.waitBtn')}}
            </div>
          </div>
          <div class="pending-check-btn pointer-hover" @click="moveToDashboard">
            <div>
              {{contents}} {{$t('modal.pendingModal.checkBtn')}}
            </div>
          </div>
        </div>
        <div  class="pending-qna pointer-hover" @click="openExternalWindow('https://gazemarket.gitbook.io/support/faq/undefined-1/undefined-2')">
          {{$t('modal.pendingModal.whyPending')}}
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {useModal} from "@/script/common/modalScript";
import {useRouter} from "vue-router";
import {windowUtils} from "@/utils/windowUtils";

export default defineComponent({
  name: "GazePendingModal",
  props: {
    title: {
      default: "",
    },
    isHeader: {
      default: true,
    },
    visible: {
      default: false,
    },
    contents:{
      default:''
    }
  },
  setup(props) {
    const {
      isOpen,
      toggleModal,
    } = useModal();
    const router = useRouter()
    const {
      openExternalWindow
    } = windowUtils()

    if (props.visible) {
      toggleModal(true);
    }

    const moveToNftList = () =>{
      toggleModal(false)
      router.push({name:'NftListView'})
    }

    const moveToDashboard = () =>{
      toggleModal(false)
      router.push({name:'Dashboard',params:{from:'pendingModal'}})
    }

    return {
      isOpen,
      toggleModal,
      router,
      moveToNftList,
      moveToDashboard,
      openExternalWindow
    }
  }
})
</script>

<style scoped>

</style>