import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {store, key} from './store'
import i18n from './i18n';
import {
    Layout,
    Menu,
    Button,
    Input,
    Carousel,
    Form,
    Modal,
    DatePicker,
    Checkbox
} from 'ant-design-vue'
import "ant-design-vue/dist/antd.css";
import "./serviceWorker";
import "@/assets/scss/site.scss";
import { createHead } from '@vueuse/head'
import Web3 from 'web3';
const head = createHead()
const web3 = new Web3(Web3.givenProvider || 'http://localhost:8080');

createApp(App)
    .use(store, key)
    .use(router)
    .use(Layout)
    .use(Menu)
    .use(Button)
    .use(Input)
    .use(Carousel)
    .use(Form)
    .use(Modal)
    .use(DatePicker)
    .use(Checkbox)
    .use(head)
    .use(i18n)
    .provide('web3', web3)
    .mount('#app')
