
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'HeaderView',
  components: {},

  setup() {
    return {};
  },
});
