// import { DeleteProductTag, ProductTag } from "@/models/productModels";
import {ProductList} from "@/models/productModels";


import {Module} from "vuex";
import {RootState} from "../index";


export interface findState {
    email:string
}

export const findModule: Module<findState, RootState> = {
    namespaced: true,
    state: () => ({
        email:''
    }),
    mutations: {

        ADD_FIND_PASSWORD_EMAIL: (state, item: string) => {

            state.email = item
        }

    },
    actions: {
        applyFindPasswordEmail: ({commit}, email) => {

            commit("ADD_FIND_PASSWORD_EMAIL", email)
        },

    },
    getters: {},
};
