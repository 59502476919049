import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_svg = _resolveComponent("base-svg")!

  return (_openBlock(), _createBlock(_component_base_svg, {
    width: _ctx.width,
    height: _ctx.height,
    fill: _ctx.fill,
    viewBox: `0 0 24 24`
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M3.79578 4.06863H2V2H5.37579L6.08101 4.58579H22L19.2425 13.9612C18.9836 14.8415 18.1756 15.4461 17.258 15.4461H8.47874C7.54589 15.4461 6.72845 14.8217 6.483 13.9218L3.79578 4.06863ZM6.64518 6.65442L8.47874 13.3775L17.258 13.3775L19.2353 6.65442H6.64518ZM9.2 20C9.47614 20 9.7 19.7761 9.7 19.5C9.7 19.2239 9.47614 19 9.2 19C8.92386 19 8.7 19.2239 8.7 19.5C8.7 19.7761 8.92386 20 9.2 20ZM9.2 22C10.5807 22 11.7 20.8807 11.7 19.5C11.7 18.1193 10.5807 17 9.2 17C7.81929 17 6.7 18.1193 6.7 19.5C6.7 20.8807 7.81929 22 9.2 22ZM16.7 19.5C16.7 19.7761 16.4761 20 16.2 20C15.9239 20 15.7 19.7761 15.7 19.5C15.7 19.2239 15.9239 19 16.2 19C16.4761 19 16.7 19.2239 16.7 19.5ZM18.7 19.5C18.7 20.8807 17.5807 22 16.2 22C14.8193 22 13.7 20.8807 13.7 19.5C13.7 18.1193 14.8193 17 16.2 17C17.5807 17 18.7 18.1193 18.7 19.5Z",
        fill: _ctx.fill
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["width", "height", "fill"]))
}