import {RouteRecordRaw} from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/system/check',
    name: 'SystemCheckView',
    component: () => import('@/components/pages/system/SystemCheckView.vue'),
    meta: {
      layout: 'header-only-logo',
    },
  },
  {
    path: '/system/error/connect',
    name: 'SystemConnectErrorView',
    component: () => import('@/components/pages/system/SystemConnectErrorView.vue'),
  },

  {
    path: '/system/error',
    name: 'SystemErrorView',
    component: () => import('@/components/pages/system/SystemErrorView.vue'),
    meta: {
      layout: 'header-only-logo',
    },
  },

  {
    path: '/system/mobile/check',
    name: 'SystemMobileCheckView',
    component: () => import('@/components/pages/system/mobile/SystemMobileCheckView.vue'),
    meta: {
      layout: 'mobile-system-header-layout',
    },
  },
];

export default routes;
