import { isNaN } from "lodash";

export const ellipsisString = (text: string, limit: number, startNumber: number, endNumber: number, unknown?: string) => {
    if (!text) return unknown? unknown : "-";
    if (text.length > limit) {
        return text.substring(0, startNumber) + "..." + text.substring(text.length - 1 - endNumber)
    }
    return text
}

export const getWalletAddressEnd4 = (address: string ) => {
    if (address.length >= 4) return address.substring(address.length - 4, address.length)
    return address
}

export const displayRational = (numString: string) => {
    if (isNaN(Number(numString))) return NaN;
    if (numString === "") return "";
    if (numString.includes(".")) {
        const numArray = numString.split(".");
        numArray[0] = Number(numArray[0]).toLocaleString();
        if (numArray.length > 1 ) return numArray.join(".");
        else return numArray[0] + "."
    }
    return Number(numString).toLocaleString();
}

export const isFinishSupportKorean = (letter: string) => {
    const charCode = letter.charCodeAt(0);
    const supportCode = (charCode - 44032) % 28;
    return supportCode !== 0;
}

export const getContentType = (mimeType: string) => {
    if (mimeType.includes('video')) return 'VIDEO';
    return 'IMAGE';
}