import {Module} from "vuex";
import {RootState, store} from "../index";
import router from "@/router";
import {notification, Modal} from "ant-design-vue";
import {loginApi} from "@/api/authApis";
import {LoginForm, LoginState, RegisterForm} from "@/models/authModels";
import {useI18n} from "vue-i18n";
import {computed} from "vue";


export interface AuthState {
    userId: string;
    name: string;
    isLogin: boolean;
    walletAddress: string;
    userProfileImg:any;
    lang:any;
}

const initAuth = (): AuthState => {
    return {
        userId: "",
        name: "",
        isLogin: false,
        walletAddress: "",
        userProfileImg:'',
        lang:''
    };
};

export const authModule: Module<AuthState, RootState> = {
    namespaced: true,
    state: () => initAuth(),

    mutations: {
        SET_USER: (state, info) => {
            state.name = info.name;
            state.userId = info.id;

        },
        LOGIN_USER: (state, info) => {
            state.name = info.name;
            state.userId = info.id;
            state.isLogin = true;
            state.lang = info.lang
        },
        SET_LOGGED_IN: (state, info) => {
            state.name = info.name;
            state.isLogin = info.isLogin;
            state.userId = info.id;
            state.lang = info.lang;
            state.userProfileImg = info.userProfileImg;
        },
        SET_LOGGED_OUT:(state, info) => {
            state.name = info.name;
            state.isLogin = info.isLogin;
            state.userId = info.id;
            state.userProfileImg = info.userProfileImg
        },
    },
    actions: {
        login: async ({commit}, loginForm: LoginForm) => {
            // const encLoginForm = {
            //     email: loginForm.email,
            //     pwd: await rsaEncryptPwd(loginForm.pwd),
            // };
            // const loginResponse = await loginApi(encLoginForm);
            commit("LOGIN_USER", loginForm);
        },
        loggedIn: async ({commit}, isLogin: string) => {
            commit("SET_LOGGED_IN", isLogin);
        },
        logout:async ({commit}) => {
            commit("SET_LOGGED_OUT", initAuth());
        },

    },
    getters: {
        log:(()=>{
            const {locale} = useI18n()
            const selectedLang=computed(()=>{
                return store.state.auth.lang
            })

            locale.value = selectedLang.value
        })
    },

};
