import {createRouter, createWebHistory, RouteRecordRaw, useRouter} from 'vue-router';
import HomeRoute from './routes/home_routes';
import LoginRoute from './routes/login_routes';
import RegisterRoute from './routes/register_routes';
import Product_Routes from '@/router/routes/product_routes';
import Wallet_Routes from '@/router/routes/wallet_routes';
import {useUserLoginInfo} from '@/script/login/loginScript';
import Wait_Route from '@/router/routes/wait_routes';
import Error_Route from '@/router/routes/error_routers';
import NftRoutes from '@/router/routes/nft_routes';
import CltnRoutes from '@/router/routes/cltn_routes';
import MemberRoutes from '@/router/routes/member_routes';
import FindRoutes from '@/router/routes/find_routes';
import MyPageRoutes from '@/router/routes/mypage_routes';
import ContactRoutes from '@/router/routes/contact_routes';
import SystemRoutes from '@/router/routes/system_routes';
import TermsRoutes from "@/router/routes/terms_routes";
import EventRoutes from "@/router/routes/event_routes";
import {createLogger} from 'vuex';
import {store} from '@/store';
import {getIsLoginInfoApi} from '@/api/userInfoApi';
import AboutRoutes from '@/router/routes/about_routes';
import {toggleBodyScroll} from "@/script/common/windowScript";
import {computed, inject} from "vue";
import {useI18n} from "vue-i18n";


const routes: Array<RouteRecordRaw> = [
    ...HomeRoute,
    ...LoginRoute,
    ...RegisterRoute,
    ...Product_Routes,
    ...Wallet_Routes,
    ...Wait_Route,
    ...Error_Route,
    ...NftRoutes,
    ...CltnRoutes,
    ...MemberRoutes,
    ...FindRoutes,
    ...MyPageRoutes,
    ...ContactRoutes,
    ...SystemRoutes,
    ...AboutRoutes,
    ...TermsRoutes,
    ...EventRoutes,
    {
        path: '/test/component',
        name: 'ComponentTestView',
        component: () => import('@/components/pages/test/ComponentTestView.vue'),
        meta: {
            layout: 'blank',
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        toggleBodyScroll(true)
        if (!to.hash && savedPosition) {
            document.body.scrollTo(savedPosition.top, savedPosition.left);
            return savedPosition;
        } else if (!to.hash) {
            // document.body.style.scrollBehavior = "auto";
            // document.body.scrollTo({top: 0, left: 0});
            // document.body.style.scrollBehavior = "smooth";
            return {
                top: 0,
                left: 0,
                behavior: 'auto',
            };
        }


    },
});
router.beforeEach(async (to, from) => {
    // 사이드바 닫기
    store.commit('open/CONFIG_BASKET_BAR', false);
    store.commit('terms/TERMS_ALL_RESET_MODAL', false)

    store.commit("open/CLOSE_POPUP");
    // 1024 이하 일때 모바일 시스템 화면으로 이동
    // if(window.innerWidth <=1023){
    //   if(to.name ==='SystemMobileCheckView'){
    //     return
    //   }
    //   await router.push({name: 'SystemMobileCheckView'});
    // }
    //
    // if (window.innerWidth > 1023) {
    //   if (from.name === 'SystemMobileCheckView') {
    //     await router.push({name:'Home'});
    //   }
    // }
    const myData = new Promise<{ isVerifiedEmail: any; isLogin: any }>((resolve, reject) => {
        const myDataResponse = getIsLoginInfoApi();
        resolve(myDataResponse);
    });

    myData.then(myDataResponse => {
        store.dispatch('auth/loggedIn', myDataResponse);
        if (myDataResponse.isLogin) {
            if (myDataResponse.isVerifiedEmail === false) {
                if (to.meta.isVerifiedEmail !== false) {
                    router.push({name: 'UnverifiedView'});
                }
            }
            if (
                to.name === 'LoginView' ||
                to.name === 'RegisterView' ||
                to.name === 'FindPasswordEmailView' ||
                to.name === 'FindPasswordWaitView'
            ) {
                router.push({name: 'Home'});
            }
            if(to.name === 'EventRegisterView')  router.push({name: 'EventConnectWalletView'});
            // 인증한 후 계정인증페이지 접근시
            if (to.name === 'UnverifiedView' && myDataResponse.isVerifiedEmail) {
                router.push({name: 'Home'});
            }
        } else {
            if (myDataResponse.isVerifiedEmail) {
                store.dispatch('email/checkEmail', myDataResponse.isVerifiedEmail);
            }
            if (myDataResponse.isVerifiedEmail === false) {
                if (to.meta.isVerifiedEmail !== false) {
                    router.push({name: 'WaitView'});
                }
            }
            if(to.meta.isNotEventLogin){
                router.push({name:'EventView'})
            }

            if (myDataResponse.isVerifiedEmail) {
                if (to.name === 'WaitView' || to.name === 'VeridiedView' || to.name === 'ExpiredView') {
                    router.push({name: 'Home'});
                }
            }

            if (to.meta.auth) {
                router.push(`/login?target=${to.fullPath}`);
            }
            if (to.meta.login) {
                router.push(`/login`);
            }
            if (to.meta.reload) {
                window.location.reload();
            }
        }
    });
});

export default router;
