
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "@/store";
import MobileSystemHeaderView from "@/components/layout/mobile/MobileSystemHeaderView.vue";
import FooterView from "@/components/layout/FooterView.vue";

export default defineComponent({
  name: "CommonLayout",
  components: {
    MobileSystemHeaderView,
    // HeaderMobileView,
    // FooterView,
  },
  setup() {
    const store = useStore();
    // const changeHeaderSideBar = computed(
    //     () => store.state.open.mobileSidebar.isOpen
    // );
    // const changeHeaderUserMenu = computed(
    //     () => store.state.open.mobileUserMenu.isOpen
    // );

    return {
      // changeHeaderSideBar,
      // changeHeaderUserMenu,
    };
  },
});
