import apiCaller from "./index";
import {
    GetMyNftListRequest,
    GetUserTradeHistoryRequest,
    ModifyProfileInfoRequest,
    ModifyUserPwdRequest
} from "@/models/request/userRequests";
import {store} from "@/store";
export async function getIsLoginInfoApi() {
    store.state.loading.app.isLogin = true;
    const response = await apiCaller.get("/users/login-info", {}, {});
    setTimeout(() => { store.state.loading.app.isLogin = false;}, 500)
    return response.data;
}


export async function getUserApi(request: any) {
    const response = await apiCaller.get("/users", {query:request}, {});
    return response.data;
}

export async function getUserItrtListApi(userId: string, type: "user" | "nft" | "cltn") {
    const response = await apiCaller.get("/users/:userId/itrts/:type", {
        params: {
            userId,
            type
        }}, {});
    return response.data;
}

export async function getUserProfileApi() {
    const response = await apiCaller.get("/users/me", {}, {});
    return response.data;
}

export async function modifyNicknameApi(name: string) {
    const response = await apiCaller.put("/users/name", { body: { name }}, {});
    return response.data;
}

export async function modifyProfileInfoApi(request: ModifyProfileInfoRequest) {
    const response = await apiCaller.put("/users", { body: request}, {});
    return response.data;
}

export async function modifyProfileImageApi(image: Blob) {
    const formData = new FormData();
    formData.append("file", image);
    const response = await apiCaller.post("/users/profile-img", { body: formData }, {});
    return response.data;
}

export async function deleteProfileImageApi() {
    const response = await apiCaller.delete("/users/profile-img", {}, {});
    return response.data;
}

export async function modifyUserPwdApi(request :ModifyUserPwdRequest) {
    const response = await apiCaller.put("/users/pwd", { body: request }, {});
    return response.data;
}

export async function getUserTradeHistoryApi(request: GetUserTradeHistoryRequest) {
    const response = await apiCaller.get("/users/nft_trading_histories", {
        query: request,
    }, {});
    return response.data;
}

export async function getUserNftListApi(userId: string) {
    const response = await apiCaller.get("/users/:userId/nfts", {
        params: { userId }
    }, {});
    return response.data;
}

export async function getMyNftListApi(request: GetMyNftListRequest) {
    const response = await apiCaller.get("/users/nfts", {
        query: request
    }, {});
    return response.data;
}


export async function getUserSuggestListApi(request: GetMyNftListRequest) {
    const response = await apiCaller.get("/users/offers", {
        query: request
    }, {});
    return response.data;
}
