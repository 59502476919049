<template>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>

</template>

<script>
import {defineComponent, onMounted, ref} from "vue";

export default defineComponent({
  name: 'LoadingTransferView',
  components: {},
  setup() {


    return {}
  },
})
</script>

<style lang="scss">
.lds-ring {
  //display: inline-block;
  position: fixed;
  //width: 80px;
  //height: 80px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 101 !important;
  div {
    box-sizing: border-box;
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: rotate(-50%,-50%);
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #101010;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ffffff transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}


@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>