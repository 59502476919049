import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/wallet',
        name: 'WalletView',
        component: () => import('@/components/pages/wallet/WalletView.vue'),
        meta: {
            auth: true,
            reload:true
        },
    },
    {
        path: '/wallet/manage',
        name: 'WalletManageView',
        component: () => import('@/components/pages/wallet/WalletManageView.vue'),
        meta: {
            auth: true,
        },
    },

];

export default routes