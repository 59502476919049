// import { DeleteProductTag, ProductTag } from "@/models/productModels";
import {ProductList} from "@/models/productModels";


import {Module} from "vuex";
import {RootState} from "../index";


export interface pageState {
    product: {
        filterTagList: Array<any> | [],
        selectedTagList: Array<any>,
    },
    nftList:Array<any>,
    cltnList:Array<any>,
    userList:Array<any>,
    cltnNftList:Array<any>,
    cltnDetailList:any,
    cltnDetailTotal:any,
    nftCltnTotal:any,
    nftTotal:any,
    nftMemberTotal:any
}

export const pageModule: Module<pageState, RootState> = {
    namespaced: true,
    state: () => ({
        product: {
            filterTagList: [
                {
                    title: 'Status1',
                    filterName: ['Offer Sale', 'Direct Sale', 'Has Offers', 'Closed Auction'],
                    selected: false
                },
                {
                    title: 'Status2',
                    filterName: ['Offer Sale', 'Direct Sale', 'Has Offers', 'Closed Auction'],
                    selected: false
                }, {
                    title: 'Status3',
                    filterName: ['Offer Sale', 'Direct Sale', 'Has Offers', 'Closed Auction'],
                    selected: false
                }, {
                    title: 'Status4',
                    filterName: ['Offer Sale', 'Direct Sale', 'Has Offers', 'Closed Auction'],
                    selected: false
                }, {
                    title: 'Status5',
                    filterName: ['Offer Sale', 'Direct Sale', 'Has Offers', 'Closed Auction'],
                    selected: false
                },
            ],
            selectedTagList: [],
        },
        nftList:[],
        cltnList:[],
        userList:[],
        cltnNftList:[],
        cltnDetailList:[],
        cltnDetailTotal:0,
        nftCltnTotal:0,
        nftTotal:0,
        nftMemberTotal:0
    }),
    mutations: {
        SET_FILTER_TAG: (state, filterTagList) => {
            state.product.filterTagList = filterTagList;
        },
        SET_SELECTED_TAG: (state, selectedTag) => {
            state.product.selectedTagList = selectedTag;
        },
        ADD_SELECTED_TAG: (state, item: string) => {

            state.product.selectedTagList.push(item)
        },
        ADD_NFT_LIST : (state, item)=>{
            state.nftList.push(...item)

        },
        DELETE_NFT_LIST: (state, item)=>{
            state.nftList = []
        },
        ADD_CLTN_LIST : (state, item)=>{
            state.cltnList.push(...item)

        },
        ADD_CLTN_DETAIL_LIST : (state, item)=>{
            state.cltnDetailList.push(item)
        },
        ADD_CLTN_DETAIL_NFT_LIST : (state, item)=>{
            state.cltnNftList.push(...item)
        },
        INIT_CLTN_DETAIL_NFT_LIST : (state, list)=>{
            state.cltnNftList = list;
        },
        ADD_CLTN_DETAIL_TOTAL : (state, item)=>{
            state.cltnDetailTotal = item
        },
        ADD_USER_LIST:(state, item)=>{
            state.userList.push(...item)
        },
        DELETE_CLTN_LIST: (state, item)=>{

            state.cltnList = []

        },

        DELETE_CLTN_DETAIL_NFT_LIST: (state, item)=>{
            state.cltnDetailList = []
        },

        DELETE_CLTN_NFT_LIST: (state, item)=>{

            state.cltnNftList = []

        },
        DELETE_USER_LIST: (state, item)=>{
            state.userList = []
        },
        DELETE_TOKENSTND_FILTER: (state, item)=>{
            state.cltnList = state.cltnList.filter(filter => filter.tokenStnd !== item )
        },

        DELETE_CLTN_DISPLAYNAME_FILTER: (state, item)=>{
            state.cltnList = state.cltnList.filter(filter => filter.displayName !== item )
        },
        DELETE_CLTN_ORDER_FILTER: (state, item)=>{
            state.cltnList = state.cltnList.filter(filter => filter.order !== item )
        },
        DELETE_NFT_ORDER_FILTER: (state, item)=>{
            state.nftList = state.nftList.filter(filter => filter.order !== item )
        },
        DELETE_NFT_STATUS_FILTER: (state, item)=>{
            state.nftList = state.nftList.filter(filter => filter.status !== item )
        },
        DELETE_NFT_NFTNAME_FILTER: (state, item)=>{
            state.nftList = state.nftList.filter(filter => filter.nftName !== item )
        },
        DELETE_NFT_PRICEUNIT_FILTER: (state, item)=>{
            state.nftList = state.nftList.filter(filter => filter.priceUnit !== item )
        },
        DELETE_NFT_PRICE_FILTER: (state, item)=>{
            state.nftList = state.nftList.filter(filter => filter.price !== item )
        },
        ADD_NFT_TOTAL : (state, item)=>{
            state.nftTotal = item
        },
        ADD_NFT_CLTN_TOTAL : (state, item)=>{
            state.nftCltnTotal = item
        },

        ADD_NFT_MEMBER_TOTAL : (state, item)=>{
            state.nftMemberTotal = item
        },


    },
    actions: {
        applyFilterTag: ({commit}, list) => {

            commit("SET_FILTER_TAG", list)
        },
        applySelectTag: ({commit}, tag) => {

            commit("SET_SELECTED_TAG", tag)
        },
        applyNftList: ({commit}, nft) => {

            commit("ADD_NFT_LIST", nft)
        },
        applyCltnDetailList: ({commit}, nft) => {
            commit("ADD_CLTN_DETAIL_LIST", nft)
        },
        applyCltnDetailNftList: ({commit}, nft) => {
            commit("ADD_CLTN_DETAIL_NFT_LIST", nft)
        },
        applyCltnDetailNftTotal: ({commit}, nft) => {

            commit("ADD_CLTN_DETAIL_TOTAL", nft)
        },
        deleteNftList:({commit}, nft)=>{
            commit("DELETE_NFT_LIST", nft)
        },
        applyCltnList:({commit}, nft) => {

            commit("ADD_CLTN_LIST", nft)
        },

        applyUserList:({commit}, nft) => {

            commit("ADD_USER_LIST", nft)
        },
        deleteCltnList:({commit}, nft)=>{
            commit("DELETE_CLTN_LIST", nft)
        },
        deleteUserList:({commit}, nft)=>{
            commit("DELETE_USER_LIST", nft)
        },
        deleteCltnNftList:({commit}, nft)=>{
            commit("DELETE_CLTN_NFT_LIST", nft)
        },
        deleteTokenStndFilter:({commit}, nft)=>{
            commit("DELETE_TOKENSTND_FILTER", nft)
        },

        deleteCltnDisplayNameFilter:({commit}, nft)=>{
            commit("DELETE_CLTN_DISPLAYNAME_FILTER", nft)
        },

        deleteCltnOrderFilter:({commit}, nft)=>{
            commit("DELETE_CLTN_ORDER_FILTER", nft)
        },

        deleteNftOrderFilter:({commit}, nft)=>{
            commit("DELETE_NFT_ORDER_FILTER", nft)
        },

        deleteNftStatusFilter:({commit}, nft)=>{
            commit("DELETE_NFT_STATUS_FILTER", nft)
        },
        deleteNftPriceUnitFilter:({commit}, nft)=>{
            commit("DELETE_NFT_PRICEUNIT_FILTER", nft)
        },
        deleteNftPriceFilter:({commit}, nft)=>{
            commit("DELETE_NFT_PRICE_FILTER", nft)
        },
        deleteNftNftNameFilter:({commit}, nft)=>{
            commit("DELETE_NFT_NFTNAME_FILTER", nft)
        },

        deleteCltnDetailFilter:({commit}, nft)=>{
            commit("DELETE_CLTN_DETAIL_NFT_LIST", nft)
        },
        applyNftTotal: ({commit}, nft) => {

            commit("ADD_NFT_TOTAL", nft)
        },
        applyNftCltnTotal: ({commit}, nft) => {

            commit("ADD_NFT_CLTN_TOTAL", nft)
        },
        applyNftMemberTotal: ({commit}, nft) => {

            commit("ADD_NFT_MEMBER_TOTAL", nft)
        },



        // applyProductTag: ({ commit }, { typeTagList, statusTagList }) => {
        //     const newTypeTagList: Array<ProductTag> = [];
        //     const newStatusTagList: Array<ProductTag>  = [];
        //     typeTagList.forEach((tag: ProductTag) => {
        //         if (tag.selected && tag.value !== "all") newTypeTagList.push(tag);
        //     })
        //     statusTagList.forEach((tag: ProductTag) => {
        //         if (tag.selected && tag.value !== "all") newStatusTagList.push(tag);
        //     })
        //     commit("SET_PRODUCT_TAG", { newTypeTagList, newStatusTagList })
        // },
        // deleteProductTag: ({ commit }, { index, listName }) => {
        //     commit("DELETE_PRODUCT_TAG", { index, listName});
        // }
    },
    getters: {},
};
