
import {defineComponent, ref} from "vue";
import NftSingleBuyComp from "@/components/pages/nft/comp/buy/NftSingleBuyComp.vue";
import NftMultiBuyComp from "@/components/pages/nft/comp/buy/NftMultiBuyComp.vue";
import {loginAlert} from "@/script/common/popupScript";

export default defineComponent({
  name:"NftBuyComp",
  components: {
    NftSingleBuyComp,
    NftMultiBuyComp,
  },
  setup() {
    const singleBuyRef = ref();
    const multiBuyRef = ref();

    const openBuyingModal = (nfts: string | string[], state: "single" | "multi", coin?: string) => {
      loginAlert()
      if (state === "single") singleBuyRef.value.openBuyingModal(nfts);
      else if (state === "multi") multiBuyRef.value.openBuyingModal(nfts, coin);
    }

    return {
      singleBuyRef,
      multiBuyRef,
      openBuyingModal,
    }
  }
})
