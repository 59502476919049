import {Module} from "vuex";
import {RootState} from "../index";

export interface CltnState {
    isMyCltn:any
}

export const cltnModule: Module<CltnState, RootState> = {
    namespaced: true,
    state: {
        isMyCltn:false
    },
    mutations: {
        CHANGE_CLTN_ISMYCLTN: (state, payload) => {
            state.isMyCltn = payload
        },
    },
    actions: {
        changeIsMyCltn: async ({commit, state}, isMyCltn) => {

            commit("CHANGE_CLTN_ISMYCLTN", isMyCltn);
        },

    },
    getters: {
        getIsMyCltnInfo: (state) => {
            return {
                isMyCltn : state.isMyCltn
            }
        },
    },
};
