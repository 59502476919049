import {K_UNIT_ARRAY} from "@/config/constants";

export const convertToKUnit = (num: number, nan ?: string) => {
    if(num === 0) return 0
    if (!num) return nan;
    for (let i = 0; i < K_UNIT_ARRAY.length ; i++) {
        if (num >= K_UNIT_ARRAY[i].stnd) {
            return (Math.floor(Number(num) * 10/K_UNIT_ARRAY[i].stnd)/10).toLocaleString() + K_UNIT_ARRAY[i].unit
        }
    }
    return num
}

export const toLocalString = (num: number, nan ?: string) => {
    if (!num && num !== 0) return nan;
    return num.toLocaleString();
}

