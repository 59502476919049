import config from "@/config";
import axios from "axios";
import compareVersions from "compare-versions";
import { register } from "register-service-worker";

class ServiceWorker {
    loadManifest() {
        return new Promise((resolve, reject) => {
            axios
                .get("/manifest.json?r=" + Math.random(), {}, {})
                .then((response) => {
                    if (response.data["version"]) {
                        resolve(response.data);
                    } else {
                        let error = new Error("version is undefined");
                        reject(error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    refreshVersion(reload) {
        console.log("Content is unregistering.");

        if ("serviceWorker" in window.navigator) {
            window.navigator.serviceWorker.ready.then((registration) => {
                registration.unregister().then(() => {
                    console.log("Service worker has been unregistered.");
                    if (reload === true) {
                        window.location.reload();
                    }
                });
            });

            if (reload === true) {
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
        } else {
            if (reload === true) {
                window.location.reload();
            }
        }
    }

    registerServiceWorker() {
        if (process.env.NODE_ENV === "production") {
            register(`${process.env.BASE_URL}service-worker.js`, {
                ready() {
                    console.log(
                        "App is being served from cache by a service worker.\n" +
                        "For more details, visit https://goo.gl/AFskqB"
                    );
                },
                registered() {
                    console.log("Service worker has been registered.");
                },
                cached() {
                    console.log("Content has been cached for offline use.");
                },
                updatefound() {
                    console.log("New content is downloading.");
                },
                updated() {
                    // console.log('New content is available; please refresh.')
                },
                offline() {
                    console.log(
                        "No internet connection found. App is running in offline mode."
                    );
                },
                error(error) {
                    console.error("Error during service worker registration:", error);
                },
            });
        }
    }

    initialize() {
        console.log("NODE_ENV: " + process.env.NODE_ENV);
        console.log("BASE_URL: " + process.env.BASE_URL);

        if (process.env.NODE_ENV !== "production") {
            console.log("service worker is disabled.");
            return;
        }

        console.log("initialize");

        this.loadManifest()
            .then((data) => {
                let manifestVersion = data.version;
                let storeVersion = localStorage.getItem("__VERSION__");

                let versionString = "";
                versionString += "Local Version: " + config.version;
                versionString += " / Manifest Version: " + manifestVersion;
                versionString += " @(" + storeVersion + ")";

                if (config.version !== manifestVersion) {
                    if (storeVersion === manifestVersion) {
                        console.log("Detect new version. try to refresh this page");
                        return Promise.reject(new Error("need_update"));
                    }

                    console.log("Detect new version. refreshing this page");
                    localStorage.setItem("__VERSION__", manifestVersion);
                    return this.refreshVersion(true);
                } else {
                    localStorage.setItem("__VERSION__", manifestVersion);
                }

                if (!storeVersion) {
                    console.log("First Version");
                    localStorage.setItem("__VERSION__", manifestVersion);
                }

                this.startVersionCheck({
                    interval: 60, // 60secs
                });

                return this.registerServiceWorker();
            })
            .catch((e) => {
                console.log("error", e);
            });
    }

    startVersionCheck(options = {}) {
        let { interval } = options;

        if (this._interval) {
            clearInterval(this._interval);
            this._interval = null;
        }

        this._interval = setInterval(() => {
            this.loadManifest().then((data) => {
                let minimumVersion = data.minimumVersion;
                if (compareVersions(config.version, minimumVersion) < 0) {
                    this.refreshVersion(true);
                }
            });
        }, 1000 * interval);
    }
}

const serviceWorker = new ServiceWorker();
serviceWorker.initialize();
export default serviceWorker;
