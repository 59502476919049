
  import { defineComponent } from "vue";
  import BaseSvg from "./BaseSvg.vue";

  export default defineComponent({
    components: {
      BaseSvg,
    },
    props: {
      width: {
        default: 32,
      },
      height: {
        default: 32,
      },
      fill: {
        default: "currentColor",
      },
      disabled: {
        default: false,
      },
    },
  })
