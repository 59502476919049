
import {defineComponent, ref, computed} from "vue";
import I18nIcon from "@/components/svg/I18nIcon.vue";


export default defineComponent({
  name: "i18nComp",
  component: {
    I18nIcon,
  },
  props: {
    headerStyle: {
      default: "",
    }
  },
  components: {I18nIcon},
  setup() {
    const localeIcon =
      {
        black: require('@/assets/image/icons/header/i18n-icon.png'),
        white: require('@/assets/image/icons/header/i18n-icon.png'),
      }

    const isSelectedLanguage = ref(false)
    const toggleSelectedLanguage = () => {
      isSelectedLanguage.value = !isSelectedLanguage.value
    }
    const setLang =(lang:string) =>{
      localStorage.setItem('setLang',lang)
    }

    return {
      toggleSelectedLanguage,
      isSelectedLanguage,
      localeIcon,
      setLang
    };
  },
});
