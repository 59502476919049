// import { DeleteProductTag, ProductTag } from "@/models/productModels";
import {ProductList} from '@/models/productModels';

import {Module} from 'vuex';
import {RootState} from '../index';

export interface searchState {
  keyword: string;
  nftList: any;
  cltnList: any;
  memberList: any;
}

export const searchModule: Module<searchState, RootState> = {
  namespaced: true,
  state: () => ({
    keyword: '',
    nftList: [],
    cltnList: [],
    memberList: [],
  }),
  mutations: {
    ADD_SEARCH_KEYWORD_LIST: (state, item) => {
      state.keyword = item;
    },

    ADD_SEARCH_NFT_LIST: (state, item) => {
      state.nftList = item;
    },
    ADD_SEARCH_CLTN_LIST: (state, item) => {
      state.cltnList = item;
    },
    ADD_SEARCH_MEMBER_LIST: (state, item) => {
      state.memberList = item;
    },

    DELETE_SEARCH_KEYWORD_LIST: (state, item) => {
      state.keyword = '';
    },

    DELETE_SEARCH_NFT_LIST: (state, item) => {
      state.nftList = [];
    },
    DELETE_SEARCH_CLTN_LIST: (state, item) => {
      state.cltnList = [];
    },
    DELETE_SEARCH_MEMBER_LIST: (state, item) => {
      state.memberList = [];
    },
  },
  actions: {
    applySearchKeyword: ({commit}, keyword) => {
      commit('ADD_SEARCH_KEYWORD_LIST', keyword);
    },

    applySearchNftList: ({commit}, keyword) => {
      commit('ADD_SEARCH_NFT_LIST', keyword);
    },

    applySearchCltnList: ({commit}, keyword) => {
      commit('ADD_SEARCH_CLTN_LIST', keyword);
    },

    applySearchMemberList: ({commit}, keyword) => {
      commit('ADD_SEARCH_MEMBER_LIST', keyword);
    },

    deleteSearchKeyword: ({commit}, keyword) => {
      commit('DELETE_SEARCH_KEYWORD_LIST', keyword);
    },

    deleteSearchNftList: ({commit}, keyword) => {
      commit('DELETE_SEARCH_CLTN_LIST', keyword);
    },

    deleteSearchCltnList: ({commit}, keyword) => {
      commit('ADD_SEARCH_CLTN_LIST', keyword);
    },

    deleteSearchMemberList: ({commit}, keyword) => {
      commit('DELETE_SEARCH_MEMBER_LIST', keyword);
    },
  },
  getters: {},
};
