
import {defineComponent,computed,ref} from "vue";
import {useHeaderMenuNav, useMobileNav} from "@/script/layout/navBarScript";
import {useRouter} from "vue-router";
import {useStore} from "@/store";
import {logoutApi} from "@/api/authApis";
import useDetectOutsideClick from "@/utils/outSideClick";
import {useLogin} from "@/script/login/loginScript";

export default defineComponent({
  name: "HeaderMenuBar",
  emits: ['closeModal','closeMenu'],
  setup(props, context) {

    const router = useRouter();
    const store = useStore()
    const useIsLogin = computed(() => store.state.auth.isLogin);
    const menuOpen = ref(false)

    // const { logout } = useLogout(store);
    const moveRouter = async (routerName:any) =>{
      await router.push({name:`${routerName}`})
      context.emit('closeMenu','')
    }
    const logout = async () =>{
      await logoutApi()
      await router.push({name:'Home'})
      context.emit('closeModal','')
      menuOpen.value = false
    }


    return {
      useIsLogin,
      router,
      menuOpen,
      logout,
      moveRouter
    };
  },
});
