import {message} from "ant-design-vue";
import {store} from "@/store";
import {openPopup} from "@/script/common/popupScript";

export const setMetaMaskInitEvent = (metamask: any) => {
    metamask.on("accountsChanged", function (accounts: any) {
        store.commit("wallet/FETCH_METAMASK_INFO", {isEnabled: true, address: accounts[0]});
        // message.success("활성화 계정이 변경되었습니다");
        openPopup('warning',{message:'paymentManage.activeWallet'})
        // Time to reload your interface with accounts[0]!
    });

    metamask.on("chainChanged", function (networkId: any) {
        console.log('network', networkId)
        // `networkChanged` event is only useful when auto-refresh on network is disabled
        // Otherwise, Kaikas will auto-reload pages upon network change
    });

    metamask.on("disconnected", function () {
        console.log('disconnect')
        // Use the `disconnected` event to check the wallet connection status.
    });
}