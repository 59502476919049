import {
    deleteNftItrtApi,
    getAllNftsApi, getNftBuyInfosApi,
    getNftDetailApi,
    getNftHistoryApi, postNftBuyApi,
    postNftMultiBuyApi,
    registerNftItrtApi
} from "@/api/nftApis";
import {computed, reactive, ref} from "vue";
import {store} from "@/store";
import {getUserItrtListApi} from "@/api/userInfoApi";
import {useRouter} from "vue-router";
import {lowerFirst} from "lodash";
import {useTagMeta,useHead} from "@vueuse/head";
import {loginAlert} from "@/script/common/popupScript";

export const useNftDetail = () => {

    // const configMetaDetail = (detail: any) => {
    //     useHead({
    //         title:detail.nftName
    //     })
    //     useTagMeta([
    //         {
    //             name: 'og:title',
    //             content: detail.nftName,
    //         },
    //         {
    //             name: 'og:image',
    //             content: detail.rsrcPath,
    //         },
    //     ])
    // }
    const fetchNftDetail = async (nftId: string) => {
        try {
            store.state.loading.app.nftDetail = true;
            await store.dispatch("nft/fetchNftDetail", nftId);
        } catch(e) {
            history.back();
        } finally {
            store.state.loading.app.nftDetail = false;
        }
    }
    const detail = computed(() => {
        return store.state.nft.detail;
    })
    const loading = computed(() => {
        return store.state.loading.app.nftDetail;
    })
    return {
        loading,
        fetchNftDetail,
        detail
    }
}


export const useNftPriceInfo = () => {
    const detail = computed(() => {
        return store.getters["nft/getNftPriceInfo"];
    })
    const isOwner = computed(() => {
        return detail.value.isMyNft;
    })
    const saleState = computed(() => {
        const priceInfo = store.getters["nft/getNftPriceInfo"];
        const isLogin = store.state.auth.isLogin;
        if (isLogin) {
            if (priceInfo.isMyNft) {
                if (priceInfo.status === "SALE") {
                    return "MY_SALE"
                }
                else return "MY_UNSOLD"
            } else {
                if (priceInfo.status === "SALE") {
                    return "OTHER_SALE"
                } else return "UNSOLD"
            }
        } else {
            if (priceInfo.status === "SALE") {
                return "NOT_LOGIN_SALE"
            }
            else return "UNSOLD"
        }
    })
    return {
        saleState,
        detail,
        isOwner,
    }
}

export const useNftChrtDetail = () => {
    const chrtList = computed(() => {
        return store.getters["nft/getNftDcptList"];
    })
    return {
        chrtList,
    }
}

export const useNftCltnDetail = () => {
    const cltn = computed(() => {
        return store.getters["nft/getNftCltnDetail"];
    })
    return {
        cltn,
    }
}

export const useNftTimeline = () => {
    const statusList = ref([]);
    const timeline = computed(() => {
        return store.getters["nft/getNftTimeline"]
    })
    const fetchNftHistory = async () => {
        await store.dispatch("nft/fetchNftHistory", statusList.value)
    }
    const isLast = computed(() => {
        const pageInfo = store.state.nft.history.pageInfo;
        return pageInfo.totalPage === pageInfo.page && pageInfo.page !== 0;
    })

    return {
        timeline,
        statusList,
        fetchNftHistory,
        isLast,
    }
}

export const useNftItrt = () => {
    const router = useRouter();
    const nftItrtList = ref();
    const fetchUserNftItrt = async () => {
        const userId = store.state.auth.userId;
        if (userId) nftItrtList.value = await getUserItrtListApi(userId, "nft");
    }
    const registerNftItrt  = async (nftId: string) => {
        await registerNftItrtApi(nftId);
    }
    const deleteNftItrt  = async (nftId: string) => {
        await deleteNftItrtApi(nftId);
    }
    const toggleNftItrt = async (isItrt: boolean, nftId: string) => {
        try {
            if (isItrt) {
                await deleteNftItrtApi(nftId);
            } else {
                if(loginAlert()){
                    return
                }
                const response = await registerNftItrtApi(nftId);
                // if (response?.errorCode === 1001) {
                //     // router.push({ name: "LoginView" })
                //     // return
                // }
            }
            store.commit("nft/FETCH_NFT_ITRT", !isItrt);
        } catch(e) {
            console.log(e)
        }
    }
    return {
        registerNftItrt,
        deleteNftItrt,
        fetchUserNftItrt,
        toggleNftItrt,
    }
}

export const useBuyingNftModal = () => {
    const toggleModal = ref(false)

    const buyMultiNft = async (nftIdList:any) =>{
        const multiTrstHash =ref()
        // await postNftMultiBuyApi(multiTrstHash,nftIdList)
        // console.log('결제')
    }

    const buyNft = async (nftId:any)=>{
        const singleTrstHash = ref()
        // await postNftBuyApi(nftId,singleTrstHash)
    }

    return {
        toggleModal,
        buyMultiNft,
        buyNft

    }
}

export const useNftBuyInfos = () => {
    const loading = ref(false);
    const buyInfosData = ref()
    const fetchNftBuyInfos = async (nftId: string) => {
        loading.value = false;
        buyInfosData.value = await getNftBuyInfosApi(nftId)
        loading.value = true;
    }

    return {
        fetchNftBuyInfos,
        buyInfosData
    }
}