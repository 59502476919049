import {Module} from "vuex";
import {RootState} from "../index";

export interface PaymentState {
    success:any,
    fail:any,
    transferLoading:any
}

export const paymentModule: Module<PaymentState, RootState> = {
    namespaced: true,
    state: {
        success:[
            // {
            //     cltnName:'Raon',
            //     nftId:'171',
            //     nftName:'Raon Membership #7',
            //     rsrcPath:'https://guarant.infura-ipfs.io/ipfs/QmSsPRy8Gzn7W7QFmDuTp3PJRwMqSRq4PpBnbbodvyj923',
            //     rsrcType:'image'
            // },
            // {
            //     cltnName:'Raon',
            //     nftId:'171',
            //     nftName:'Raon Membership #7',
            //     rsrcPath:'https://guarant.infura-ipfs.io/ipfs/QmdpQr2sLuGtvVNo1d6SVXYTVeBd1w5NDZ4ABFfj143asm',
            //     rsrcType:'video'
            // },
            // {
            //     cltnName:'Raon',
            //     nftId:'171',
            //     nftName:'Raon Membership #7',
            //     rsrcPath:'https://guarant.infura-ipfs.io/ipfs/QmSsPRy8Gzn7W7QFmDuTp3PJRwMqSRq4PpBnbbodvyj923',
            //     rsrcType:'image'
            // },
            // {
            //     cltnName:'Raon',
            //     nftId:'171',
            //     nftName:'Raon Membership #7',
            //     rsrcPath:'https://guarant.infura-ipfs.io/ipfs/QmSsPRy8Gzn7W7QFmDuTp3PJRwMqSRq4PpBnbbodvyj923',
            //     rsrcType:'image'
            // },
            // {
            //     cltnName:'Raon',
            //     nftId:'171',
            //     nftName:'Raon Membership #7',
            //     rsrcPath:'https://guarant.infura-ipfs.io/ipfs/QmSsPRy8Gzn7W7QFmDuTp3PJRwMqSRq4PpBnbbodvyj923',
            //     rsrcType:'image'
            // },
        ],
        fail:[
            // {
            //     cltnName:'Raon',
            //     nftId:'171',
            //     nftName:'Raon Membership #7',
            //     rsrcPath:'https://guarant.infura-ipfs.io/ipfs/QmSsPRy8Gzn7W7QFmDuTp3PJRwMqSRq4PpBnbbodvyj923',
            //     rsrcType:'image'
            // },
            // {
            //     cltnName:'Raon',
            //     nftId:'171',
            //     nftName:'Raon Membership #7',
            //     rsrcPath:'https://guarant.infura-ipfs.io/ipfs/QmdpQr2sLuGtvVNo1d6SVXYTVeBd1w5NDZ4ABFfj143asm',
            //     rsrcType:'video'
            // },
            // {
            //     cltnName:'Raon',
            //     nftId:'171',
            //     nftName:'Raon Membership #7',
            //     rsrcPath:'https://guarant.infura-ipfs.io/ipfs/QmSsPRy8Gzn7W7QFmDuTp3PJRwMqSRq4PpBnbbodvyj923',
            //     rsrcType:'image'
            // },
            // {
            //     cltnName:'Raon',
            //     nftId:'171',
            //     nftName:'Raon Membership #7',
            //     rsrcPath:'https://guarant.infura-ipfs.io/ipfs/QmSsPRy8Gzn7W7QFmDuTp3PJRwMqSRq4PpBnbbodvyj923',
            //     rsrcType:'image'
            // },
            // {
            //     cltnName:'Raon',
            //     nftId:'171',
            //     nftName:'Raon Membership #7',
            //     rsrcPath:'https://guarant.infura-ipfs.io/ipfs/QmSsPRy8Gzn7W7QFmDuTp3PJRwMqSRq4PpBnbbodvyj923',
            //     rsrcType:'image'
            // },
        ],
        transferLoading:false
    },
    mutations: {
        ADD_SUCCESS_LIST: (state, list) => {
            state.success = [...list];
        },
        ADD_FAIL_LIST: (state, list) => {
            state.fail =[...list]
        },
        START_LOADING: (state, list) => {
            state.transferLoading = true
        },
        STOP_LOADING: (state, list) => {
            state.transferLoading =false
        },
        DELETE_LIST: (state, list) => {
            state.success = []
            state.fail =[]
        },
    },
    actions: {
        applySuccessList: ({commit}, list) => {
            commit("ADD_SUCCESS_LIST", list)
        },

        applyFailList: ({commit}, list) => {
            commit("ADD_FAIL_LIST", list)
        },
        startTransferLoading: ({commit}, loading) => {
            commit("START_LOADING", loading)
        },
        stopTransferLoading: ({commit}, loading) => {
            commit("STOP_LOADING", loading)
        },

        deleteList: ({commit}, list) => {
            commit("DELETE_LIST", list)
        },
    },
    getters: {
        // getSuccessList: async (state) => {
        //
        // }
    },
};
