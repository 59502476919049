import {useWallet} from "@/script/wallet/walletScript";
import {useKlaytn} from "@/script/klaytn/klaytnScript";

export const useTempImg = () => {
    const tempNftCardImg ={
        img:require('@/assets/image/thumb.png')
    }
    const tempProfile:any = {
        // img : require('@/assets/image/icons/home/basic-profile.png')
        img : require('@/assets/image/icons/header/white-profile-icon.png'),
        black:require('@/assets/image/icons/header/black-profile-icon.png'),
        white:require('@/assets/image/icons/header/white-profile-icon.png'),
        default:require('@/assets/image/icons/header/default-profile-icon.png')
    }

    const isVerifiedIcon = {
        icon : require('@/assets/image/icons/nft/list/badge.png')
    }

    const tempNftImg = {
        img :require('@/assets/image/icons/loading/loading-background-img.png')
    }

    return {
        tempProfile,
        isVerifiedIcon,
        tempNftImg,
        tempNftCardImg
    }
}