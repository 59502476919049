<template>
  <div v-if="isMetamaskMobile()" class="gaze-buying-sign-modal">
    <gaze-modal ref="signModal" :visible="isOpen" @close="$emit('closeModal')">
      <template #title>
        <div class="buying-sign-title-section">
          <div class="sign-title-area">
            <div class="sign-title">{{ title }}</div>
            <div class="sign-title-img">
              <img src="@/assets/image/icons/nft-buying-modal/arrow-right2.png" alt="">
            </div>
            <div class="sign-title">
              {{$t('modal.signModal.title')}}
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <div class="buying-sign-section metamask-sign-modal-contents">
          <div>
            <div class="sign-contents-text-section">
              <div v-show="title==='NFT'" class="sign-text">
                {{ requestText }}
              </div>
              <div class="sign-text i18n-pre-wrap">
                {{$t('modal.signModal.desc')}}
              </div>
            </div>
            <div class="sign-img">
              <img src="@/assets/image/icons/nft-buying-modal/sign-img.png" alt="">
            </div>
            <div class="sign-btn-section pointer-hover" @click="signTrst">
              <div>
                {{$t('modal.signModal.signBtnText')}}
              </div>
            </div>
            <div class="sign-question pointer-hover"
                 @click="openExternalWindow('https://gazemarket.gitbook.io/support/onborading/gaze-1/undefined-3')">
              {{$t('modal.signModal.signReason')}}
            </div>
          </div>
        </div>
      </template>
    </gaze-modal>
  </div>
  <div v-else class="gaze-buying-sign-modal">
    <gaze-modal ref="signModal" :visible="isOpen" @close="$emit('closeModal')">
      <template #title>
        <div class="buying-sign-title-section">
          <div class="sign-title-area">
            <div class="sign-title">{{ title }}</div>
            <div class="sign-title-img">
              <img src="@/assets/image/icons/nft-buying-modal/arrow-right2.png" alt="">
            </div>
            <div class="sign-title">
              {{$t('modal.signModal.title')}}
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <div class="buying-sign-section">
          <div>
            <div class="sign-contents-text-section">
              <div v-show="title==='NFT'" class="sign-text">
                {{ requestText }}
              </div>
              <div class="sign-text i18n-pre-wrap">
                {{$t('modal.signModal.desc')}}
              </div>
            </div>
            <div class="sign-img">
              <img src="@/assets/image/icons/nft-buying-modal/sign-img.png" alt="">
            </div>
            <div class="sign-btn-section pointer-hover" @click="signTrst">
              <div>
             {{$t('modal.signModal.signBtnText')}}
              </div>
            </div>
            <div class="sign-question pointer-hover"
                 @click="openExternalWindow('https://gazemarket.gitbook.io/support/onborading/gaze-1/undefined-3')">
              {{$t('modal.signModal.signReason')}}
            </div>
          </div>
        </div>
      </template>
    </gaze-modal>
  </div>
</template>

<script>
import {computed, defineComponent} from "vue";
import {useStore} from "@/store";
import GazeModal from "@/components/common/modal/GazeModal";
import {isFinishSupportKorean} from "@/utils/stringUtil";
import {useModal} from "@/script/common/modalScript";
import {isKaikasMobile, isMetamaskMobile, isMobileDevice, windowUtils} from "@/utils/windowUtils";
import {openPopup} from "@/script/common/popupScript";

export default defineComponent({
  name: "NftOfferSignComp",
  components: {
    GazeModal,
  },
  emits: [
    'closeModal',
    'signTrst',
  ],
  props: {
    title: {
      default: '타이틀 입력'
    },
    type: {
      default: 'CANCEL'
    }
  },
  setup(props, context) {
    const store = useStore()

    const {
      isOpen,
      toggleModal,
    } = useModal();
    toggleModal(true);

    const {
      openExternalWindow
    } = windowUtils()

    const transferLoading = computed(() => {
      return store.state.payment.transferLoading
    })

    const requestText = computed(() => {
      const title = props.title
      const adv = isFinishSupportKorean(title[title.length - 1]) ? "를" : "을"
      if (title === "NFT 구매") return "NFT를 구매합니다."
      else return `${title}${adv} 요청합니다.`
    })

    const signTrst = () => {
      if(props.type === 'cancel' ){
        context.emit("signTrst")
      }else if (props.type==='offerCancel') {
        context.emit("offerCancel")
      } else if (props.type === 'offerAccept') {
        context.emit("offerAccept")
      } else if (props.type === 'offerReject') {
        context.emit("offerReject")
      } else {
        context.emit("signTrst")
      }

    }

    return {
      isOpen,
      requestText,
      transferLoading,
      signTrst,
      openExternalWindow,
      isMetamaskMobile
    }
  }
})
</script>

<style scoped>

</style>