import {Module} from 'vuex';
import {RootState} from '../index';

export interface OpenState {
    mobileSidebar: {
        isOpen: boolean;
        isShoppingOpen: boolean;
    };
    mobileUserMenu: {
        isOpen: boolean;
    };
    mobileTopBtn: {
        isOpen: boolean;
    };
    navbar: {
        isOpen: boolean;
    };
    basketBar: {
        isOpen: boolean;
    };
    popup: {
        isOpen: boolean;
        compName: string;
        type: string;
        nftId: string;
        walletInstall: {
            walletName: string,
        },
        warning: {
            message: string,
            type:string
        },
        warningBasket: {
            message: string,
            name: any
        },
        loginAlert: {
            title: string,
            contents: string
        },
        commonAlert:{
            title:string,
            contents:string
        }
    };
    modal: {
        scrollY: number;
    }
    mobileSearch: {
        isOpen: boolean;
    };
}

export const openModule: Module<OpenState, RootState> = {
    namespaced: true,
    state: () => ({
        mobileSidebar: {
            isOpen: false,
            isShoppingOpen: false,
        },
        mobileUserMenu: {
            isOpen: false,
        },
        mobileTopBtn: {
            isOpen: false,
        },
        navbar: {
            isOpen: false,
        },
        basketBar: {
            isOpen: false,
        },
        popup: {
            isOpen: false,
            compName: "",
            type: '',
            nftId: '',
            walletInstall: {
                walletName: "",
            },
            warning: {
                message: "",
                type:""
            },
            warningBasket: {
                message: "",
                name: []
            },
            loginAlert: {
                title: "",
                contents: ""
            },
            commonAlert:{
                title:'',
                contents:''
            }
        },
        modal: {
            scrollY: 0,
        },
        mobileSearch: {
            isOpen: false,
        },
    }),
    mutations: {
        TOGGLE_MOBILE_SIDEBAR: state => {
            state.mobileSidebar.isOpen = !state.mobileSidebar.isOpen;
            if (!state.mobileSidebar.isOpen) {
                state.mobileSearch.isOpen = false;
                state.mobileSidebar.isShoppingOpen = false;
            }
        },
        TOGGLE_MOBILE_SIDEBAR_SHOPPING: state => {
            state.mobileSidebar.isShoppingOpen = !state.mobileSidebar.isShoppingOpen;
            // state.mobileSidebar.isOpen = !state.mobileSidebar.isOpen;
            // if (state.mobileSidebar.isShoppingOpen) {
            //     state.mobileUserMenu.isShoppingOpen = false;
            // }
        },
        TOGGLE_MOBILE_SIDEBAR_SEARCH: state => {
            state.mobileSearch.isOpen = !state.mobileSearch.isOpen;
            // console.log(state.mobileSearch.isOpen);
            // state.mobileSidebar.isOpen = !state.mobileSidebar.isOpen;
            // if (state.mobileSidebar.isShoppingOpen) {
            //     state.mobileUserMenu.isShoppingOpen = false;
            // }
        },
        TOGGLE_MOBILE_USER_MENU: (state) => {
            state.mobileUserMenu.isOpen = !state.mobileUserMenu.isOpen;
            if (state.mobileUserMenu.isOpen) {
                state.mobileSidebar.isOpen = false;
            }
        },
        CONFIG_MOBILE_USER_MENU: (state, openValue) => {
            state.mobileUserMenu.isOpen = openValue;
        },
        CONFIG_MOBILE_SIDEBAR: (state, openValue) => {
            state.mobileSidebar.isOpen = openValue;
        },
        CONFIG_MOBILE_TOP_BTN: (state, openValue) => {
            state.mobileTopBtn.isOpen = openValue;
        },
        CONFIG_NAVBAR: (state, openValue) => {
            state.navbar.isOpen = openValue;
        },
        CONFIG_BASKET_BAR: (state, openValue) => {
            state.basketBar.isOpen = openValue;
        },
        OPEN_POPUP: (state, {name, payload, type, nftId}) => {
            state.popup.isOpen = true;
            switch (name) {
                case "walletInstall":
                    state.popup.compName = "wallet-install-popup"
                    state.popup.type = type
                    state.popup.nftId = nftId
                    state.popup.walletInstall.walletName = payload.walletName
                    break;
                case "warning":
                    state.popup.compName = "warning-popup"
                    state.popup.warning.message = payload.message
                    state.popup.warning.type = payload.type
                    break;
                case "warningBasket":
                    state.popup.compName = "warning-basket-popup"
                    state.popup.warningBasket.message = payload.message
                    state.popup.warningBasket.name = payload.name
                    break;
                case "loginAlert":
                    state.popup.compName = "login-alert-popup"
                    state.popup.loginAlert.title = payload.title
                    state.popup.loginAlert.contents = payload.contents
                    break;
                case "commonAlert":
                    state.popup.compName = "common-alert-popup"
                    state.popup.commonAlert.title = payload.title
                    state.popup.commonAlert.contents = payload.contents
                    break;
            }
        },
        CLOSE_POPUP: (state) => {
            state.popup.isOpen = false;
            state.popup.compName = "";
            state.popup.type = ''
        },
        CONFIG_MODAL_SCROLL_Y: (state, number) => {
            state.modal.scrollY = number
        }
    },
    actions: {},
    getters: {},
};
