
import {createVNode, defineComponent, reactive, ref} from "vue";
import {RouteParamsRaw, useRouter} from "vue-router";
import NftMultiBuyingModalComp from "@/components/pages/nft/comp/buy/NftMultiBuyingModalComp.vue";
import NftOfferSignComp from "@/components/pages/nft/comp/NftOfferSignComp.vue";
import {useModal} from "@/script/common/modalScript";
import {message, Modal} from "ant-design-vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {useLogin} from "@/script/login/loginScript";
import {loadWallet, useKlaytn} from "@/script/klaytn/klaytnScript";
import {getNftBuyRequestApi, getNftRegistPollingApi, postNftMultiBuyApi} from "@/api/nftApis";
import {store} from "@/store";
import {useWalletAddress} from "@/script/wallet/walletScript";
import {loadMetamaskWallet, useMetamaskScript} from "@/script/metamask/metamaskScript";
import {useBasket} from "@/script/basket/basketScript";
import GazePendingModal from "@/components/common/modal/GazePendingModal.vue"
import NftConfirmModalComp from "@/components/pages/nft/comp/NftConfirmModalComp.vue";
import {useWalletManageBundle} from "@/script/wallet/walletBundleScirpt";
import router from "@/router";
import Decimal from 'decimal.js';
import {loginAlert, openPopup} from "@/script/common/popupScript";


export default defineComponent({
  name: "NftMultiBuyComp",
  components: {
    NftMultiBuyingModalComp,
    NftOfferSignComp,
    GazePendingModal,
    NftConfirmModalComp
  },
  setup(props, context) {

    const customError = ref({
      isError: false,
      title: "",
      content: "",
      onOk: {},
    })
    // 사인 후 등록 성공,실패 모달
    const signProps = ref()
    const reloadWindow = () => {
      window.location.reload()
    }
    const {
      isOpen: priceOpen,
      toggleModal: togglePrice,
    } = useModal()
    const {
      isOpen: confirmOpen,
      toggleModal: toggleConfirm
    } = useModal()

    const {
      isOpen: signOpen,
      toggleModal: toggleSign,
      toggleBackScroll
    } = useModal()

    const {
      isOpen: pendingOpen,
      toggleModal: togglePending
    } = useModal()

    const closeSignModal = () => {
      toggleSign(false)
      togglePrice(false)
      toggleConfirm(false)
    }

    const payList = reactive<{ nftList: any[], coin: string }>({
      nftList: [],
      coin: ""
    })

    const selectedCoin = ref("");
    const {
      loginCheck
    } = useLogin();
    const router = useRouter();

    const nextToSign = () => {
      toggleSign(true)
    }

    const closeSign = () => {
      toggleSign(false)
      togglePrice(false)
    }
    const openBuyingModal = (nftListParam: any[], coin: string) => {
      payList.nftList = nftListParam
      payList.coin = coin
      const disableCheck = payList.nftList.some(item => {
        return item.status === "UNSALE"
      })

      try {
        if (loginAlert()) {
          return
        }

        if (disableCheck) {
          throw {
            isError: true,
            title: '구매 할 수 없는 상품이 있습니다.',
            content: '장바구니를 확인해주세요.',
            onOk() {
              toggleConfirm(false)
            },
          }
        }
        togglePrice(true)
      } catch (e: any) {
        if (e.isError) {
          customError.value = e
          toggleConfirm(true);
        }
      }
    }

    const {
      metamaskWalletAddress,
      kaikasWalletAddress,

    } = useWalletAddress();

    const {
      signTransaction,
    } = useKlaytn();

    const {
      sendTransactions,
      getTrstReceipt
    } = useMetamaskScript()

    const {
      deleteBasketItemAll,
    } = useBasket();

    const intervalCount = ref(0);
    let pendingSetTimeOut: any;

    const signTrstMultiBuyingApprove = async () => {
      const selectedWalletList = payList.nftList.map(item => {
        return item.ownerWalletAddress
      })
      let totalPay = new Decimal(0)
      const idList: any = []
      const nftIdList: { nftId: string }[] = []
      payList.nftList.forEach((nft) => {
        idList.push(nft.id)
        nftIdList.push({nftId: nft.id})
        totalPay = totalPay.add(nft.price);
      })
      const idListToString = idList.toString()
      let walletAddress
      let pendingSetTimeOut: any
      try {
        if (payList.coin === 'KLAY') {
          await store.dispatch('payment/deleteList')
          await loadWallet()
          walletAddress = kaikasWalletAddress.value
          if (selectedWalletList.indexOf(walletAddress) !== -1) {
            openPopup("warning", {message: "modal.warningPopup.isMyNft"})
            return
          }
          await store.dispatch('basket/deleteSelectedNftList', [])
          await store.dispatch('basket/applySelectedNftList', idList)
          const abiAddress = await postNftMultiBuyApi(nftIdList, walletAddress)
          const signResult = await signTransaction(abiAddress, totalPay.toNumber(),abiAddress.nthId)
          if (signResult.status === "0x1") {
            await store.dispatch('payment/startTransferLoading')
            await store.dispatch('payment/applySuccessList', payList.nftList)
            await store.dispatch('payment/stopTransferLoading')
            await deleteBasketItemAll()
            closeSign()
            await router.push({name: 'NftPaymentMultiSuccessView'})
          } else {
            await store.dispatch('payment/startTransferLoading')
            await store.dispatch('payment/stopTransferLoading')
            await store.dispatch('payment/applyFailList', payList.nftList)
            await deleteBasketItemAll()
            closeSign()
            await router.push({name: 'NftPaymentMultiSuccessView'})
          }
          // signProps.value = true
          // toggleConfirm(true)
        } else if (payList.coin === 'MATIC') {
          await store.dispatch('payment/deleteList')
          await loadMetamaskWallet()
          walletAddress = metamaskWalletAddress.value
          if (!metamaskWalletAddress?.value) {
            throw{
              response: {
                message: 'modal.warningPopup.connectMetamask'
              }
            }
          }
          if (selectedWalletList.indexOf(walletAddress) !== -1) {
            openPopup("warning", {message: "modal.warningPopup.isMyNft"})
            return
          }
          await store.dispatch('basket/deleteSelectedNftList', [])
          await store.dispatch('basket/applySelectedNftList', idList)
          const abiAddress = await postNftMultiBuyApi(nftIdList, walletAddress)
          const signResult = await sendTransactions(abiAddress, totalPay.toNumber(),abiAddress.nthId)

          await store.dispatch('payment/startTransferLoading')
          // 구매 폴링
          let intervalTransfer: any;
          pendingSetTimeOut = setTimeout(async () => {
            await clearInterval(intervalTransfer);
            await store.dispatch('payment/stopTransferLoading');
            pendingOpen.value = true;
            closeSignModal();
          }, 11000);

          const receipt = new Promise((resolve, reject) => {
            const receiptInterval = setInterval(async () => {
              intervalCount.value += 1;
              const trstReceipt = await getTrstReceipt(signResult);
              if (intervalCount.value > 9 || trstReceipt?.status) {
                clearInterval(receiptInterval);
                intervalCount.value = 0;
                if (trstReceipt.status !== '0x1') {
                  await store.dispatch('payment/stopTransferLoading');
                  clearTimeout(pendingSetTimeOut);
                  reject({
                    response: {
                      message: 'modal.confirmModal.fail.buyFail'
                    }
                  });
                }
                resolve(trstReceipt);
              }
            }, 1000);
          });
          await receipt;
          let registCount = 0
          let receiptInterval: any;
          let tempArray: any = [];
          const transferResult = new Promise((resolve, reject) => {
            receiptInterval = setInterval(async () => {
              intervalCount.value += 1;
              const trstReceipt = await getNftRegistPollingApi(abiAddress.nthIdList[registCount]);
              let tempCount = registCount
              registCount += 1;
              if (registCount >= abiAddress.nthIdList.length) {
                registCount = tempCount
              }

              if (trstReceipt.status !== 'PENDING' && trstReceipt.status !== 'WAIT') {
                registCount = 0
                tempArray.push(trstReceipt.status)
                if (tempArray.length === abiAddress.nthIdList.length || intervalCount.value > 9) {
                  clearInterval(receiptInterval)
                  resolve(tempArray);
                }
              }
            }, 1000);
          });
          await transferResult.then(async (result: any) => {
            if (
                result.includes('FAIL')
            ) {
              await store.dispatch('payment/stopTransferLoading');
              closeSign()
              await clearTimeout(pendingSetTimeOut);
              await store.dispatch('payment/applyFailList', payList.nftList)
              await router.push({name: 'NftPaymentMultiSuccessView'})
            } else {
              // clearInterval(intervalTransfer);
              await clearTimeout(pendingSetTimeOut);
              closeSign()
              await store.dispatch('payment/stopTransferLoading');
              await store.dispatch('payment/applySuccessList', payList.nftList)
              await deleteBasketItemAll()
              await router.push({name: 'NftPaymentMultiSuccessView'})
            }
          });
          tempArray = []

        } else {
          throw {response: "알 수 없는 코인"}
        }
      } catch (e: any) {
        signProps.value = false
        if (e.isError) {
          customError.value = e
        } else if (e.code === -32002) {
          toggleConfirm(false)
        } else if (e.code === 4001) {
          openPopup("warning", {message: "modal.warningPopup.signDiscontinued"})
        } else if (e.response.message) {
          openPopup("warning", {message: e.response.message})
        }
        closeSignModal()
      }

    }


    return {
      payList,
      priceOpen,
      togglePrice,
      closeSign,
      signOpen,
      nextToSign,
      openBuyingModal,
      signTrstMultiBuyingApprove,
      pendingOpen,
      togglePending,
      customError,
      confirmOpen,
      closeSignModal,
      signProps
    }
  }
})

