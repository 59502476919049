
import {computed, defineComponent, ref} from 'vue';
import {useInjectFormItemContext} from "ant-design-vue/es/form";

export default defineComponent({
  name: "GazeInput",
  emits: ["update:modelValue", "change", "blur", "clickEndIcon","reset",'resetSearchBar'],
  props: {
    modelValue: {
      default: null,
    },
    topPlaceholder: {
      default: "",
    },
    movingHolder: {
      default: false,
    },
    placeholder: {
      default: "",
    },
    fixPlaceholder: {
      default: "",
    },
    endIcon: {
      default: "",
    },
    endIconClass: {
      default: "",
    },
    defaultIcon: {
      default: false,
    },
    style: {
      default: {},
    },
    type: {
      default: "",
    },
    disabled: {
      default: false,
    },
    searchBar:{
      default:false
    }
  },
  setup(props, context) {
    const inputRef = ref();
    const {onFieldChange, onFieldBlur} = useInjectFormItemContext();
    const isFocus = ref(false);
    const onFocus = () => {
      isFocus.value = true;
    }
    const onBlur = () => {
      onFieldBlur();
      isFocus.value = false;
    }
    const initInput = () => {
      context.emit("update:modelValue", "");
      context.emit("reset")
    }

    const initSearchBarInput = () => {
      context.emit("update:modelValue", "");
      context.emit("resetSearchBar")
    }
    const topHolderClass = computed(() => {
      if (props.movingHolder) {
        if (isFocus.value) return "placeholder placeholder-top"
        else if (props.modelValue) return  "placeholder placeholder-top"
        else return "placeholder"
      }
      else return "placeholder placeholder-top";
    })
    const className = computed(() => {
      let name = "gaze-input";
      if (props.disabled) name += " gaze-input-disabled";
      if (isFocus.value) name += " gaze-input-focus";
      if (props.modelValue) name += " gaze-input-typed";
      return name;
    })
    const focusInput = () => {
      inputRef.value.focus()
    }
    return {
      inputRef,
      focusInput,
      initInput,
      onBlur,
      onFocus,
      onFieldChange,
      className,
      topHolderClass,
      initSearchBarInput
    }
  }
})
