
import {createVNode, defineComponent, reactive, ref} from "vue";
import {useNftBuyInfos} from "@/script/nft/nftDetailScript";
import {useRoute} from "vue-router";

import NftBuyingModalComp from "@/components/pages/nft/detail/components/NftBuyingModalComp.vue"
import NftOfferSignComp from "@/components/pages/nft/comp/NftOfferSignComp.vue"
import NftConfirmModalComp from "@/components/pages/nft/comp/NftConfirmModalComp.vue"
import {useModal, useWalletModal} from "@/script/common/modalScript";
import {getNftBuyRequestApi, getNftDetailApi, getNftRegistPollingApi, postNftBuyApi} from "@/api/nftApis";
import {loadWallet, useKlaytn} from "@/script/klaytn/klaytnScript";
import {message, Modal} from "ant-design-vue";
import {store} from "@/store";
import {useWalletAddress} from "@/script/wallet/walletScript";
import {loadMetamaskWallet, useMetamaskScript} from "@/script/metamask/metamaskScript";
import router from "@/router";
import GazePendingModal from "@/components/common/modal/GazePendingModal.vue";
import {useWalletManageBundle} from "@/script/wallet/walletBundleScirpt";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {useLogin} from "@/script/login/loginScript";
import {isKaikasMobile, isMetamaskMobile, isMobileDevice} from "@/utils/windowUtils";
import {loginAlert, openPopup} from "@/script/common/popupScript";

export default defineComponent({
  name: "NftSingleBuyComp",
  components: {
    NftBuyingModalComp,
    NftOfferSignComp,
    NftConfirmModalComp,
    GazePendingModal
  },
  setup(props, context) {
    const route = useRoute();
    const customError = ref({
      isError: false,
      title: "",
      content: "",
      onOk: {},
    })
    const buyNftId = ref()

    const {
      isOpen: priceOpen,
      toggleModal: togglePrice,
    } = useModal()

    const {
      isOpen: signOpen,
      toggleModal: toggleSign
    } = useModal()

    const {
      isOpen: confirmOpen,
      toggleModal: toggleConfirm
    } = useModal()
    const {
      isOpen: pendingOpen,
      toggleModal: togglePending
    } = useModal()

    const {buyInfosData, fetchNftBuyInfos} = useNftBuyInfos()
    const {
      loginCheck
    } = useLogin();

    const type = ref('buy')
    const openBuyingModal = async (nftId: string) => {
      try {
        if (loginAlert()) {
          return
        }
        buyNftId.value = nftId
        // nftInfo.value = await getNftDetailApi(nftId);
        await fetchNftBuyInfos(nftId);
        togglePrice(true);
      } catch (e: any) {

        if (e.isError) {
          customError.value = e
          toggleConfirm(true);
        } else {
          console.log('e', e)
        }
      }
    }

    const nextToSign = () => {
      if (buyInfosData.value.priceUnit === 'MATIC') {
        // 모바일, 메타마스크 인앱
        if (isMobileDevice()) {
          //'메타마스크 인앱일때'
          if (isMetamaskMobile()) {
            // 결제
            toggleSign(true)
          } else {
            openPopup("walletInstall", {walletName: "METAMASK"}, 'singleBuy', buyNftId.value)
          }
        } else {
          toggleSign(true)
        }
      } else {

        if (isMobileDevice()) {
          if (isKaikasMobile()) {
            toggleSign(true)
          } else {
            openPopup("walletInstall", {walletName: "KAIKAS"}, 'singleBuy', buyNftId.value)
          }
        } else {
          toggleSign(true)
        }
      }

    }

    const closeSignModal = () => {
      toggleSign(false)
      togglePrice(false)
      toggleConfirm(false)
    }


    // 사인 후 등록 성공,실패 모달
    const signProps = ref()
    const reloadWindow = () => {
      window.location.reload()
    }

    const {
      signTransaction
    } = useKlaytn();

    const {
      sendTransactions,
      getTrstReceipt
    } = useMetamaskScript();

    const {
      kaikasWalletAddress,
      metamaskWalletAddress
    } = useWalletAddress();

    const intervalCount = ref(0);
    let pendingSetTimeOut: any;

    const signTrstBuyingApprove = async () => {
      const ownerWalletAddress = ref()
      ownerWalletAddress.value = buyInfosData.value.ownerAddress
      try {
        if (buyInfosData.value.priceUnit === 'KLAY') {
          await store.dispatch('payment/deleteList')
          await loadWallet()
          if (ownerWalletAddress.value.toUpperCase() === kaikasWalletAddress.value.toUpperCase()) {
            openPopup("warning", {message: "modal.warningPopup.isMyNft"})
            return
          }
          const abiAddress = await postNftBuyApi(buyNftId.value, kaikasWalletAddress.value)
          const signResult = await signTransaction(abiAddress, buyInfosData.value.price, abiAddress.nthId)
          if (signResult.status === "0x1") {
            await store.dispatch('payment/startTransferLoading')
            await store.dispatch('payment/applySuccessList', [buyInfosData.value])
            await store.dispatch('payment/stopTransferLoading')
            await router.push({name: 'NftPaymentSuccessView'})
          } else {
            await store.dispatch('payment/startTransferLoading')
            // await store.dispatch('payment/applySuccessList', [buyInfosData.value])
            await store.dispatch('payment/stopTransferLoading')
            await store.dispatch('payment/applyFailList', [buyInfosData.value])
            await router.push({name: 'NftPaymentFailView'})
          }
        }

        if (buyInfosData.value.priceUnit === 'MATIC') {
          await store.dispatch('payment/deleteList')
          await loadMetamaskWallet()
          if (!metamaskWalletAddress?.value) {
            throw{
              response: {
                message: 'modal.warningPopup.connectMetamask'
              }
            }
          }

          if (ownerWalletAddress?.value?.toUpperCase().indexOf(metamaskWalletAddress?.value?.toUpperCase()) !== -1) {
            openPopup("warning", {message: "modal.warningPopup.isMyNft"})
            return
          }
          const abiAddress = await postNftBuyApi(buyNftId.value, metamaskWalletAddress.value)
          const signResult = await sendTransactions(abiAddress, parseFloat(buyInfosData.value.price), abiAddress.nthId)
          await store.dispatch('payment/startTransferLoading')
          // 구매 폴링
          let intervalTransfer: any;
          pendingSetTimeOut = setTimeout(async () => {
            await clearInterval(intervalTransfer);
            await store.dispatch('payment/stopTransferLoading');
            pendingOpen.value = true;
            closeSignModal();
          }, 11000);

          const receipt = new Promise((resolve, reject) => {
            const receiptInterval = setInterval(async () => {
              intervalCount.value += 1;
              const trstReceipt = await getTrstReceipt(signResult);
              if (intervalCount.value > 9 || trstReceipt?.status) {
                clearInterval(receiptInterval);
                intervalCount.value = 0;
                if (trstReceipt.status !== '0x1')
                  reject({
                    response: {
                      message: 'modal.confirmModal.fail.buyFail'
                    }
                  });
                resolve(trstReceipt);
              }
            }, 1000);
          });
          await receipt;
          intervalTransfer = setInterval(async () => {
            const transferResult = await getNftRegistPollingApi(abiAddress.nthId);
            // 실패
            if (
                transferResult.status !== 'WAIT' &&
                transferResult.status !== 'PENDING' &&
                transferResult.status === 'FAIL'
            ) {
              // await store.dispatch('payment/applyFailList', transferResult)
              await store.dispatch('payment/stopTransferLoading');
              clearInterval(intervalTransfer);
              await clearTimeout(pendingSetTimeOut);
              await store.dispatch('payment/applyFailList', [buyInfosData.value])
              await router.push({name: 'NftPaymentFailView'})
            }
            // 성공
            if (
                transferResult.status !== 'WAIT' &&
                transferResult.status !== 'PENDING' &&
                transferResult.status === 'SUCCESS'
            ) {
              clearInterval(intervalTransfer);
              await clearTimeout(pendingSetTimeOut);
              await store.dispatch('payment/stopTransferLoading');
              await store.dispatch('payment/applySuccessList', [buyInfosData.value])
              await router.push({name: 'NftPaymentSuccessView'})
            }
          }, 1000);
        }
      } catch (e: any) {
        signProps.value = false
        await store.dispatch('payment/stopTransferLoading');
        clearInterval(pendingSetTimeOut)
        if (e.isError) {
          customError.value = e
        } else if (e.code === -32002) {
          toggleConfirm(false)
        } else if (e.code === 4001) {
          openPopup("warning", {message: "modal.warningPopup.signDiscontinued"})
        } else if (e.response.message) {
          openPopup("warning", {message: e.response.message})
        }
        // else {
        //   toggleConfirm(true);
        // }
      }
    }

    return {
      priceOpen,
      togglePrice,
      signOpen,
      toggleSign,
      confirmOpen,
      toggleConfirm,
      signProps,
      buyInfosData,
      nextToSign,
      reloadWindow,
      closeSignModal,
      openBuyingModal,
      signTrstBuyingApprove,
      pendingOpen,
      customError,
      type
    }
  }
})

