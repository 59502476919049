import { computed, ref } from "vue";
import { store } from "@/store";
import {
  deleteAllBasketItemApi,
  deleteBasketItemApi,
  getBasketItemListApi,
  registerBasketItemApi,
} from "@/api/basketApis";
import { getNftDetailApi, postNftMultiBuyApi } from "@/api/nftApis";
import Cookies from "js-cookie";
import { toLocalString } from "@/utils/unitUtils";
import { classToSelector } from "@/utils/elementUtils";
import { openPopup } from "@/script/common/popupScript";

export const useCookieBasket = () => {
  const COOKIE_NAME = "basket";
  const SEPARATOR = ";";
  const getItemList = async () => {
    const cartValue = Cookies.get(COOKIE_NAME);
    if (cartValue) {
      const idList = cartValue.split(SEPARATOR);
      const promiseArr = await Promise.all(
        idList.map((id) => {
          const basketItem = store.state.basket.list.find((basketItem: any) => {
            return basketItem.id === Number(id);
          });
          if (!basketItem) {
            return getNftDetailApi(id);
          }
          return basketItem;
        })
      );
      return promiseArr;
    } else return [];
  };
  const getIdList = () => {
    const cartValue = Cookies.get(COOKIE_NAME);
    if (cartValue) {
      return cartValue.split(SEPARATOR);
    } else return [];
  };
  const addBasketItem = (nftId: string) => {
    const list = getIdList();
    if (!list.includes(nftId + "")) {
      list.push(nftId);
      Cookies.set(COOKIE_NAME, list.join(SEPARATOR), { expires: 7 });
    }
  };
  const deleteBasketItem = (nftId: string) => {
    const list = getIdList();
    Cookies.set(
      COOKIE_NAME,
      list.filter((id) => Number(id) !== Number(nftId)).join(SEPARATOR)
    );
  };
  const deleteAll = () => {
    Cookies.remove(COOKIE_NAME);
  };
  return {
    getItemList,
    addBasketItem,
    deleteBasketItem,
    deleteAll,
  };
};

export const useBasket = () => {
  const basketItemList = computed(() => {
    return store.state.basket.list;
  });
  const selectedCoin = computed(() => {
    return store.state.basket.selectedCoin;
  });
  const basketItemArr: any = computed(() => {
    return basketItemList.value.map((item) => {
      return item.id;
    });
  });
  const toggleSelectedCoin = (priceUnit: string) => {
    store.commit("basket/FETCH_SELECTED_COIN", priceUnit);
  };
  const isOpen = computed(() => {
    return store.state.open.basketBar.isOpen;
  });
  const toggleBasket = (status: boolean | "toggle") => {
    if (status === "toggle") {
      console.log(
        navigator.userAgent.indexOf("iPhone") > -1 ||
          navigator.userAgent.indexOf("Android") > -1
      );
      if (
        !isOpen.value &&
        (navigator.userAgent.indexOf("iPhone") > -1 ||
          navigator.userAgent.indexOf("Android") > -1)
      ) {
        console.log("open");
        document.body.style.overflowY = "hidden";
      } else if (
        isOpen.value &&
        (navigator.userAgent.indexOf("iPhone") > -1 ||
          navigator.userAgent.indexOf("Android") > -1)
      ) {
        console.log("unset");
        document.body.style.overflowY = "unset";
      }
      store.commit("open/CONFIG_BASKET_BAR", !isOpen.value);
    } else {
      if (
        status &&
        (navigator.userAgent.indexOf("iPhone") > -1 ||
          navigator.userAgent.indexOf("Android") > -1)
      ) {
        console.log("open");
        document.body.style.overflowY = "hidden";
      } else if (
        !status &&
        (navigator.userAgent.indexOf("iPhone") > -1 ||
          navigator.userAgent.indexOf("Android") > -1)
      ) {
        console.log("unset");
        document.body.style.overflowY = "unset";
      }
      store.commit("open/CONFIG_BASKET_BAR", status);
    }
  };
  const isLogin = computed(() => {
    return store.state.auth.isLogin;
  });
  const deleteLoading = ref(false);
  const {
    getItemList: getLocalItemList,
    addBasketItem: addLocalItem,
    deleteBasketItem: deleteLocalItem,
    deleteAll: deleteLocalItemAll,
  } = useCookieBasket();
  const mergeBasket = async () => {
    if (basketItemList.value.length) {
      try {
        const res = await registerBasketItemApi(basketItemArr.value);
      } catch (e: any) {
        console.log("e", e);
      } finally {
        deleteLocalItemAll();
      }
    }
  };
  const fetchBasketItemList = async () => {
    if (isLogin.value) {
      const { data } = await getBasketItemListApi({ page: "", size: "" });
      store.commit("basket/FETCH_BASKET_LIST", data.reverse());
    } else {
      store.commit("basket/FETCH_BASKET_LIST", await getLocalItemList());
    }
  };
  const registerBasketItem = async (nftId: string, priceUnit: string) => {
    try {
      if (isLogin.value) {
        await registerBasketItemApi(nftId);
      } else {
        addLocalItem(nftId);
      }

      await fetchBasketItemList();
      toggleBasket(true);
      // const nft = await getNftDetailApi(nftId);
      toggleSelectedCoin(priceUnit);
    } catch (e: any) {
      console.log("e", e);
      if (
        e?.response.data.message ===
        "INVALID_REQUEST : 자신의 nft는 장바구니에 담을 수 없습니다."
      ) {
        openPopup("warning", {
          message: "modal.confirmModal.openModal.notYourOwnNFT",
        });
      }
    }
  };
  const deleteBasketItem = async (nftId: string) => {
    if (deleteLoading.value === false) {
      deleteLoading.value = true;
      const currentCoin = selectedCoin.value;
      if (isLogin.value) {
        await deleteBasketItemApi(nftId);
      } else {
        deleteLocalItem(nftId);
      }
      await fetchBasketItemList();
      if (
        basketItemList.value.findIndex((item: any) => {
          return item.priceUnit === currentCoin;
        }) !== -1
      ) {
        toggleSelectedCoin(currentCoin);
      }
      deleteLoading.value = false;
    }
  };
  const deleteBasketItemAll = async () => {
    if (isLogin.value) {
      await deleteAllBasketItemApi();
    } else {
      deleteLocalItemAll();
    }
    await fetchBasketItemList();
  };
  const externalClickEvent = (e: any) => {
    if (isOpen.value) {
      if (
        isOpen.value &&
        !e.target.closest(classToSelector("basket-button")) &&
        !e.target.closest(classToSelector("cart-view"))
      ) {
        toggleBasket(false);
      }
    }
  };
  const coinList = computed(() => {
    return store.getters["basket/getCoinList"];
  });
  const filteredList = computed(() => {
    return basketItemList.value.filter((item) => {
      return item.priceUnit === selectedCoin.value;
    });
  });
  const paySumInfo = computed(() => {
    let sum = 0;
    filteredList.value.forEach((item) => {
      sum += item.price;
    });
    return {
      count: filteredList.value.length,
      sum: toLocalString(sum),
      total: sum,
    };
  });

  const paySumInfoWon = computed(() => {
    let sum = 0;
    filteredList.value.forEach((item) => {
      sum += item.krwPrice;
    });
    return {
      count: filteredList.value.length,
      sum: toLocalString(sum),
    };
  });

  const paySumNftList = computed(() => {
    const list: any = [];
    filteredList.value.forEach((item) => {
      list.push({ nftId: item.id });
    });
    return {
      count: filteredList.value.length,
      list,
    };
  });

  const checkedBasketList = computed(() => {
    return basketItemList.value.map((nft) => {
      return nft.id;
    });
  });

  const cartImg = {
    add: require("@/assets/image/icons/basket/cart.png"),
    check: require("@/assets/image/icons/basket/cart-checked.png"),
    notSale: require("@/assets/image/icons/basket/cart-x-sale.png"),
  };

  // const selectedNftListId = computed(() => {
  //     const list:any = [];
  //     filteredList.value.forEach(item => {
  //         list.push({ nftId : item.id}) ;
  //     })
  //     return {
  //         count: filteredList.value.length,
  //         list
  //     }
  // })
  return {
    isOpen,
    basketItemList,
    filteredList,
    coinList,
    selectedCoin,
    paySumInfo,
    paySumInfoWon,
    isLogin,
    mergeBasket,
    toggleBasket,
    fetchBasketItemList,
    registerBasketItem,
    deleteBasketItem,
    toggleSelectedCoin,
    deleteBasketItemAll,
    externalClickEvent,
    paySumNftList,
    cartImg,
    checkedBasketList,
  };
};
