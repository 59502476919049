import {
    computed,
    ref,
} from "vue";
import {
    deleteWalletApi,
    getWalletApi,
    modifyWalletNicknameApi,
    postWalletApi,
    registerActiveWalletApi, requestRegisterWalletApi
} from "@/api/walletApi";
import {message} from "ant-design-vue";
import {PostRegisterRequest, PostWalletRequest} from "@/models/request/walletRequest";
import {store} from "@/store";
import {loadMetamaskWallet} from "@/script/metamask/metamaskScript";
import {loadWallet, useKlaytn} from "@/script/klaytn/klaytnScript";
import {lowerFirst} from "lodash";
import {openPopup} from "@/script/common/popupScript";
import {wisEventRequestRegisterWalletApi} from "@/api/wisApis";

export const useWallet = () => {
    const {getCaver} =useKlaytn()
    const walletList = computed(() => {
        return store.getters["wallet/getWalletList"];
    });
    const mapWalletList =ref();
    const walletListLoading = ref(false);
    const walletTotal = computed(() => {
        return store.state.wallet.list.length;
    })
    const getWalletList = async () => {
        walletListLoading.value = true;
        const { total, data } = await getWalletApi();
        store.commit("wallet/FETCH_WALLET_LIST", data);
        walletListLoading.value = false;
    }

    const getKaikasRequestRegisterWallet =async (request: PostRegisterRequest)=>{
        const responseCode = await requestRegisterWalletApi(request)
        const signRequest ={
            publicAddress : request.address,
            nonce:responseCode
        }
        const wallet = await loadWallet()
        const caver = getCaver(wallet);
        const handleSignMessage =async ({publicAddress, nonce} : any) => {
            try {
                const from = publicAddress;
                const sign =await caver.klay.sign(nonce.code,from)
                return sign
            } catch (err:any) {

                if(err.code===-32603){
                    openPopup("warning", {message: 'paymentManage.rejectSign'})
                }
                console.error(err);
            }
        };
        const signResult =await handleSignMessage(signRequest)
        return signResult
    }


    const getMetamaskRequestRegisterWallet =async (request: PostRegisterRequest)=>{
        const responseCode = await requestRegisterWalletApi(request)
        const signRequest ={
            publicAddress : request.address,
            nonce:responseCode
        }
        const wallet = await loadMetamaskWallet()
        const handleSignMessage =async ({publicAddress, nonce} : any) => {
            try {
                const from = publicAddress;
                const sign = await wallet.request({
                    method: 'personal_sign',
                    params: [nonce.code, from],
                });
                return sign
            } catch (err) {
                console.error(err);
            }
        };
        const signResult =await handleSignMessage(signRequest)
        return signResult
    }

    const getWisKaikasRequestRegisterWallet =async (request: PostRegisterRequest)=>{
        const responseCode = await wisEventRequestRegisterWalletApi(request)
        const signRequest ={
            publicAddress : request.address,
            nonce:responseCode
        }
        const wallet = await loadWallet()
        const caver = getCaver(wallet);
        const handleSignMessage =async ({publicAddress, nonce} : any) => {
            try {
                const from = publicAddress;
                const sign =await caver.klay.sign(nonce.code,from)
                return sign
            } catch (err:any) {

                if(err.code===-32603){
                    openPopup("warning", {message: 'paymentManage.rejectSign'})
                }
                console.error(err);
            }
        };
        const signResult =await handleSignMessage(signRequest)
        return signResult
    }

    const registerWallet = async (request: PostWalletRequest) => {
        try {
            await postWalletApi(request)
        } catch(e: any) {
            throw e?.response?.data;
        }
    }

    const registerWalletActive = async (walletId: string) => {
        await registerActiveWalletApi(walletId);
    }

    const deleteWallet = async (walletId: any) => {
        try {
            await deleteWalletApi(walletId)
            openPopup("warning", {message: "paymentManage.beforeDeleteWallet.deleteCopleteWallet"})
        } catch (error: any) {
            if (error.response.data.message === "INVALID_REQUEST : 판매중인 상품이 존재합니다.") {
                openPopup("warning", {message: "paymentManage.beforeDeleteWallet.remainProduct"})
            }else if(error.response.data.errorCode===1003){
                openPopup("warning", {message: "paymentManage.beforeDeleteWallet.representativeWallet"})
            }
        }
    }


    return {
        walletList,
        walletTotal,
        mapWalletList,
        getWalletList,
        walletListLoading,
        registerWallet,
        deleteWallet,
        registerWalletActive,
        getMetamaskRequestRegisterWallet,
        getKaikasRequestRegisterWallet,
        getWisKaikasRequestRegisterWallet
    }
}

export const useWalletNickname = () => {
    const loading = ref(false);
    const nickWriteMode= ref(false);
    const nickRef = ref();

    const initNickname = () => {
        nickWriteMode.value = false;
    }
    const renameNickname = async (walletId: string) => {
        loading.value = true;
        console.log(nickRef.value.value)
        await modifyWalletNicknameApi(walletId, nickRef.value.value);
        loading.value = false;
    }

    const toggleMode = (mode: boolean) => {
        nickWriteMode.value = mode;
        if (mode) {
            setTimeout(() => {
                nickRef.value.focus();
            }, 100)
        }
    }

    return {
        nickRef,
        nickWriteMode,
        loading,
        initNickname,
        renameNickname,
        toggleMode
    }
}

export const useWalletAddress = () => {
    const kaikasWalletAddress = computed(() => {
        return store.state.wallet.kaikas.address
    })
    const metamaskWalletAddress = computed(() => {
        return store.state.wallet.metamask.address
    })

    return {
        kaikasWalletAddress,
        metamaskWalletAddress,
    }
}