
import {defineComponent} from 'vue';
import {useRouter} from 'vue-router';

export default defineComponent({
  name: 'FooterView',
  setup() {
    const router = useRouter();
    const email = 'info@guarantable.com'
    const toAbout = () => {
      router.push({
        name: 'AboutView',
      });
    };
    const toCompany = () => {
      window.location.href = 'https://www.guarantable.com/';
    };
    const toTerms = (name: string) => {
      router.push({name: name});
    };

    const toFAQ = () => {
      window.open('https://gazemarket.gitbook.io/support/', '_blank');
    };

    return {
      router,
      toAbout,
      toCompany,
      toTerms,
      toFAQ,
      email
    };
  },
});
