
import {defineComponent, onMounted, computed} from 'vue';
import {useStore} from '@/store';
import HeaderView from './HeaderView.vue';
import BlackHeaderView from '@/components/layout/BlackHeaderView.vue';
// import HeaderMobileView from "./HeaderMobileView.vue";

export default defineComponent({
  name: 'CommonLayout',
  components: {
    BlackHeaderView,
    // HeaderMobileView,
  },
  setup() {
    const store = useStore();
    // const changeHeaderSideBar = computed(
    //     () => store.state.open.mobileSidebar.isOpen
    // );
    // const changeHeaderUserMenu = computed(
    //     () => store.state.open.mobileUserMenu.isOpen
    // );

    return {
      // changeHeaderSideBar,
      // changeHeaderUserMenu,
    };
  },
});
