import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nft-buy-comp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nft_single_buy_comp = _resolveComponent("nft-single-buy-comp")!
  const _component_nft_multi_buy_comp = _resolveComponent("nft-multi-buy-comp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_nft_single_buy_comp, { ref: "singleBuyRef" }, null, 512),
    _createVNode(_component_nft_multi_buy_comp, { ref: "multiBuyRef" }, null, 512)
  ]))
}