import {Module} from "vuex";
import {RootState} from "../index";

export interface BasketState {
    list: any[],
    selectedCoin: string,
    selectedNft:any,
    selectedWalletAddress:any
}

export const basketModule: Module<BasketState, RootState> = {
    namespaced: true,
    state: {
        list: [],
        selectedCoin: "",
        selectedNft:[],
        selectedWalletAddress:[]
    },
    mutations: {
        FETCH_BASKET_LIST: (state, list) => {
            state.list = list;
            if (list[0]) state.selectedCoin = list[list.length - 1].priceUnit
        },
        PUSH_BASKET_LIST: (state, list) => {
            state.list.push(list);
        },
        FETCH_SELECTED_COIN: (state, coinName) => {
            state.selectedCoin = coinName
        },
        PUSH_SELECTED_NFT_LIST:(state, list)=>{
            state.selectedNft.push(list)
        },
        DELETE_SELECTED_NFT_LIST:(state,list)=>{
           state.selectedNft =[]
        }

    },
    actions: {

        applySelectedNftList: async ({commit}, nft) => {
            commit("PUSH_SELECTED_NFT_LIST", nft);
        },
        deleteSelectedNftList: async ({commit}, nft) => {
            commit("DELETE_SELECTED_NFT_LIST", nft);
        },
    },
    getters: {
        getCoinList: (state) => {
            const list: any[] = []
            state.list.forEach(item => {
                const index = list.findIndex(coin => coin.name === item.priceUnit)
                if (index !== -1) {
                    list[index].count += 1;
                } else {
                    list.push({
                        name: item.priceUnit,
                        count: 1,
                    })
                }
            })
            return list;
        },

    },
};
