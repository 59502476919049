import {Module} from "vuex";
import {RootState} from "../index";

export interface WalletState {
    list: any[],
    kaikas: {
      isEnabled: boolean,
        address: string,
    },
    metamask: {
        isEnabled: boolean,
        address: any,
    }
}

export const walletModule: Module<WalletState, RootState> = {
    namespaced: true,
    state: {
        list: [],
        kaikas: {
            isEnabled: false,
            address: "",
        },
        metamask: {
            isEnabled: false,
            address: "",
        }
    },
    mutations: {
        FETCH_WALLET_LIST: (state, list) => {
            state.list = list;
        },
        FETCH_KAIKAS_INFO: (state, {isEnabled, address}) => {
            state.kaikas.isEnabled = isEnabled;
            state.kaikas.address = address;
        },
        FETCH_METAMASK_INFO: (state, {isEnabled, address}) => {
            state.metamask.isEnabled = isEnabled;
            state.metamask.address = address;
        },
    },
    actions: {
    },
    getters: {
        getWalletList: (state) => {
            return state.list.sort((wallet1, wallet2) => {
                return wallet1.isActive ? -1 : 0;
            })
        }
    },
};
