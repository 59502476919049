
import {defineComponent, onMounted, computed} from "vue";
import {useStore} from "@/store";
import HeaderView from "./HeaderView.vue";
import EventBlackHeader from "@/components/layout/EventBlackHeader.vue";
// import HeaderMobileView from "./HeaderMobileView.vue";
import FooterView from "./FooterView.vue";
import {useRoute} from "vue-router";

export default defineComponent({
  name: "CommonLayout",
  components: {
    HeaderView,
    // HeaderMobileView,
    FooterView,
    EventBlackHeader
  },
  setup() {
    const store = useStore();
    const route = useRoute()
    const header = computed(() => {
      return route.meta
    });
    // const changeHeaderSideBar = computed(
    //     () => store.state.open.mobileSidebar.isOpen
    // );
    // const changeHeaderUserMenu = computed(
    //     () => store.state.open.mobileUserMenu.isOpen
    // );

    return {
      header
      // changeHeaderSideBar,
      // changeHeaderUserMenu,
    };
  },
});
