
import {defineComponent, ref, computed, createVNode, onBeforeUnmount, onMounted} from "vue";
import {useMobileSideBar} from "@/script/layout/navBarScript";
import mobileSideBarMenu from "@/components/layout/mobile/sidebar/mobileSideBarMenu";
import mobileSideBarNftShoppingMenu from "@/components/layout/mobile/sidebar/mobileSideBarNftShoppingMenu";
import mobileCoinType from "@/components/layout/mobile/sidebar/mobileCoinType";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "MobileSideBarNftListDetailComp",
  components: {},

  setup() {
    const router = useRouter()
    const {isOpen, toggleSidebar, toggleSidebarDetail} = useMobileSideBar()
    const setLocalStorageNftTab = () => {
      localStorage.setItem('nft-selectedTab', 'nft')
    }
    const moveNftListTags = async (url: any, tag: any, index: any) => {
      await setLocalStorageNftTab()
      await router.push({name: 'NftListView', query: {name: url, tag: tag, index: index}})
      await toggleSidebarDetail('nftShopping')
      await toggleSidebar()
    }

    const moveNftListCoinType = async (url: any, coin: any) => {
      await setLocalStorageNftTab()
      await router.push({name: 'NftListView', query: {name: url, tokenStnd: coin}})
      await toggleSidebarDetail('nftShopping')
      await toggleSidebar()
    }

    return {
      isOpen,
      toggleSidebar,
      toggleSidebarDetail,
      mobileSideBarMenu,
      mobileSideBarNftShoppingMenu,
      mobileCoinType,
      moveNftListTags,
      moveNftListCoinType

    };
  },
});
