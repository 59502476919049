const mobileSideBarMenu = [
  {
    icon: require('@/assets/image/icons/mobile/sidebar/sidebar-nft-search.png'),
    title: 'common.header.mobile.mobileSideBarMenu.nftName',
    open: 'nftSearch',
  },
  {
    icon: require('@/assets/image/icons/mobile/sidebar/sidebar-nft-shopping.png'),
    title: 'common.header.mobile.mobileSideBarMenu.nftShopping',
    more: require('@/assets/image/icons/mobile/sidebar/more-btn.png'),
    open: 'nftShopping',
  },
];

export default mobileSideBarMenu;
