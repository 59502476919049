import { Module } from "vuex";
import { RootState } from "../index";

interface LoadingKeys {
    isLogin: boolean;
    nftDetail: boolean;
}

export interface LoadingState {
    app: LoadingKeys,
}

export const loadingModule: Module<LoadingState, RootState> = {
    namespaced: true,
    state: () => ({
        app: {
            isLogin: false,
            nftDetail: false,
        },
    }),
    mutations: {
        CONFIG_APP_LOADING: (state, { name, state: loadingState }) => {
            switch (name) {
                case 'isLogin':
                    state.app.isLogin = loadingState;
                    break;
                case 'nftDetail':
                    state.app.nftDetail = loadingState;
                    break;
            }
        },
    },
    actions: {},
    getters: {
        getAppLoading: (state) => {
            return !!Object.values(state.app).find(loading => loading);
        }
    },
};
