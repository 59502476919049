<template>
  <div :class="isOpen ? 'cart-view cart-view-open' : 'cart-view cart-view-close'">
    <div class="cart-header">
      <div class="cart-title">{{$t('basket.title')}}</div>
      <div class="close-icon pointer-hover" @click="toggleBasket(false)" />
    </div>
    <div class="cart-count-row">
      <div class="item-count">{{$t('basket.total')}} {{ basketItemList.length }}{{$t('basket.count')}}</div>
      <div
        v-if="basketItemList.length !== 0"
        class="item-delete pointer-hover"
        @click="deleteBasketItemAll"
      >
        {{$t('basket.allDelete')}}
      </div>
    </div>
    <div v-if="basketItemList.length !== 0" class="cart-content">
      <div class="coin-list">
        <div
          v-for="(coin, index) in coinList"
          :key="index"
          :class="selectedCoin === coin.name ? 'coin-item coin-item-selected' : 'coin-item'"
          @click="toggleSelectedCoin(coin.name)"
        >
          <img
            :src="
              selectedCoin === coin.name ? getCoinIcon(coin.name, 'WHITE') : getCoinIcon(coin.name)
            "
            class="coin-icon"
          />
          <div class="coin-name">{{ coin.name }} ({{ coin.count }})</div>
        </div>
      </div>
      <div class="cart-item-list">
        <div class="cart-item-list-inner">
          <div v-for="(item, index) in filteredList" :key="index">
            <basket-item-comp :nft="item" :disabled="item.status === 'UNSALE'" />
          </div>
        </div>
      </div>
      <div class="cart-buy-area">
        <div class="cart-coin-sum-row">
          <div class="item-count">
            {{$t('basket.total')}} <span class="sum-count">{{ paySumInfo.count }}</span> {{$t('basket.count')}}
          </div>
          <div class="sum-amount">{{ paySumInfo.sum }} {{ selectedCoin }}</div>
        </div>
        <div class="cart-hr" />
        <!--        <div class="pay-method-row">-->
        <!--          <div class="pay-name-row">-->
        <!--            <div class="pay-method-title">-->
        <!--              메타#1-->
        <!--            </div>-->
        <!--            <div class="pay-method-name">-->
        <!--              메타마스크 (7944)-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="method-button-area pointer-hover">-->
        <!--            <div class="method-button">-->
        <!--              결제수단 변경-->
        <!--            </div>-->
        <!--            <div class="arrow-icon" />-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="buy-button-row" @click="openMultiModal">
          <div class="buy-button">{{$t('basket.buyNft1')}} {{ selectedCoin }} {{$t('basket.buyNft2')}}</div>
        </div>
        <div class="cart-warn">
          <span class="notice-icon" />
          {{$t('basket.buyAll')}}
        </div>
      </div>
    </div>
    <basket-empty-comp v-else />
    <nft-buy-comp ref="nftBuyRef" />
  </div>
</template>

<script>
import {defineComponent, watch, onMounted, onBeforeUnmount, ref, createVNode, computed} from 'vue';
import BasketEmptyComp from '@/components/pages/basket/BasketEmptyComp.vue';
import BasketItemComp from '@/components/pages/basket/BasketItemComp.vue';
import {useBasket} from '@/script/basket/basketScript';
import {getCoinIcon} from '@/script/common/constantScript';
import {useRouter} from 'vue-router';
import NftBuyComp from '@/components/pages/nft/comp/buy/NftBuyComp';
import {useNftBuyToggle} from '@/script/nft/nftModalScript';

export default defineComponent({
  name: 'BasketView',
  components: {
    BasketEmptyComp,
    BasketItemComp,
    NftBuyComp,
  },
  setup() {
    const router = useRouter();
    const {
      basketItemList,
      isOpen,
      coinList,
      filteredList,
      selectedCoin,
      paySumInfo,
      paySumInfoWon,
      paySumNftList,
      isLogin,
      toggleBasket,
      fetchBasketItemList,
      toggleSelectedCoin,
      deleteBasketItemAll,
      mergeBasket,
      externalClickEvent,
    } = useBasket();

    watch(isLogin, async (value, oldValue) => {
      if (value) await mergeBasket();
      await fetchBasketItemList();
    });

    const {nftBuyRef, openBuyingModal} = useNftBuyToggle();

    const openMultiModal = () => {
      nftBuyRef.value.openBuyingModal(filteredList.value, 'multi', selectedCoin.value);
    };

    onMounted(() => {
      window.addEventListener('click', externalClickEvent);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('click', externalClickEvent);
    });

    return {
      isOpen,
      coinList,
      filteredList,
      selectedCoin,
      paySumInfo,
      isLogin,
      toggleBasket,
      getCoinIcon,
      toggleSelectedCoin,
      deleteBasketItemAll,
      basketItemList,
      paySumInfoWon,
      paySumNftList,
      nftBuyRef,
      openMultiModal,
    };
  },
});
</script>

<style scoped></style>
