<template>
  <div
      :class="`gaze-modal`"
      v-if="isOpen"
  >
    <div class="modal-content" @click.stop="() => {}">
      <div class="modal-header" v-if="isHeader">
        <div class="modal-title" v-if="title">{{ title }}{{ priceUnit? `(${priceUnit})`:"" }}</div>
        <slot name="title"></slot>
        <img
            src="@/assets/image/icons/close.svg"
            class="close-button pointer-hover"
            @click="closeModal"
        >
      </div>
      <slot name="content" :toggleModal="toggleModal"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useModal } from "@/script/common/modalScript";

export default defineComponent({
  name: "GazeModal",
  emits: ["close"],
  props: {
    title: {
      default: "",
    },
    isHeader: {
      default: true,
    },
    visible: {
      default: false,
    },
    priceUnit:{
      default:""
    }
  },
  setup(props, context) {
    const {
      isOpen,
      toggleModal,
    } = useModal();

    if (props.visible) {
      toggleModal(true);
    }

    const closeModal = () => {
      context.emit('close');
      toggleModal(false);
    }

    return {
      isOpen,
      toggleModal,
      closeModal,
    }
  }
})
</script>

<style scoped>

</style>