import {RegisterForm} from "@/models/authModels";
import apiCaller from "@/api/index";
import {openPopup} from "@/script/common/popupScript";
import {RequestRegister, VerifyRegister, WisRequestRegister} from "@/models/request/walletRequest";

export async function wisEventRegisterApi(registerForm :RegisterForm,termsMarketing:any){
    const requsetForm = {
        email: registerForm.email,
        pwd: registerForm.pwd,
        name: registerForm.name,
        nickname:registerForm.nickname,
        mrktPolicyAgreed:termsMarketing
    }

    const response = await apiCaller.post(
        '/wis/users/signup/email',
        { body: requsetForm },
        {}
    );
    return response.data;
}

export async function wisEventPhoneRequestApi(phoneNumber:any){
    const phoneFormRequest = {
        countryCode:"82",
        phone:phoneNumber
    }
    const response = await apiCaller.post(
        '/wis/users/phone/request',
        { body: phoneFormRequest },
        {}
    );
    if(response.data.message==="REQUIRED_PERMISSION : failed authentication"){
        openPopup("warning", {message: '로그인이 필요합니다.'})
        throw response
    }
    return response.data;
}

export async function wisEventPhoneVerifyApi(transactionCode:string,authCode:string){
    const verifyFormRequest = {
        transactionCode:transactionCode,
        authCode:authCode
    }
    const response = await apiCaller.post(
        '/wis/users/phone/verify',
        { body: verifyFormRequest },
        {}
    );

    return response.data;
}


export async function wisEventRequestRegisterWalletApi(request: WisRequestRegister) {
    const response = await apiCaller.get("/wis/wallets/request_register",
        {
            query: {
                address:request.address
            },
        }, {});
    return response.data;
}

export async function wisEventVerifyRegisterWalletApi(request: VerifyRegister) {
    const response = await apiCaller.post("/wis/wallets/verify_register",
        {body: request,}, {});
    return response.data;
}
