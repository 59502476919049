import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/nft',
        name: 'NftListView',
        component: () => import('@/components/pages/nft/NftListView.vue'),
        meta: {
            layout: "black-header",
        },
    },
    {
        path: '/nft/:nftId',
        name: 'NftDetailView',
        component: () => import('@/components/pages/nft/detail/NftDetailView.vue'),
        meta: {
            layout: "common-layout",
        },
    },
    {
        path: '/nft/payment/success',
        name: 'NftPaymentSuccessView',
        component: () => import('@/components/pages/nft/payment/NftPaymentSuccessView.vue'),
        meta: {
            layout: "common-layout",
        },
    },
    {
        path: '/nft/payment/multi/success',
        name: 'NftPaymentMultiSuccessView',
        component: () => import('@/components/pages/nft/payment/NftPaymentMultiSuccessView.vue'),
        meta: {
            layout: "common-layout",
        },
    },
    {
        path: '/nft/payment/fail',
        name: 'NftPaymentFailView',
        component: () => import('@/components/pages/nft/payment/NftPaymentFailView.vue'),
        meta: {
            layout: "common-layout",
        },
    },
];

export default routes