import apiCaller from "./index";
import {PostWalletRequest, WalletId, RequestRegister, VerifyRegister} from "@/models/request/walletRequest";

export async function requestRegisterWalletApi(request: RequestRegister) {
    const response = await apiCaller.get("/wallets/request_register",
        {
            query: {
                address: request.address,
                chain: request.chain
            },
        }, {});
    return response.data;
}

export async function verifyRegisterWalletApi(request: VerifyRegister) {
    const response = await apiCaller.post("/wallets/verify_register",
        {body: request,}, {});
    return response.data;
}


export async function postWalletApi(wallet: PostWalletRequest) {
    const response = await apiCaller.post("/wallets", {body: wallet}, {});
    return response.data;
}

export async function getWalletApi() {
    const response = await apiCaller.get("/wallets", {}, {});
    return response.data;
}

export async function deleteWalletApi(walletId: WalletId) {
    const response = await apiCaller.delete("/wallets/:walletId",
        {
            params: {walletId},
        }, {});
    return response.data;
}

export async function registerActiveWalletApi(walletId: string) {
    const response = await apiCaller.put("/wallets/:walletId/active",
        {
            params: {walletId},
        }, {});
    return response.data;
}

export async function convertCoinToKrwtApi(symbol: string, price: number) {
    const response = await apiCaller.get("/wallets/coin/price",
        {
            query: {symbol, price},
        }, {});
    return response.data.krwPrice;
}

export async function modifyWalletNicknameApi(walletId: string, nickname: string) {
    const response = await apiCaller.put("/wallets/:walletId/nickname",
        {
            params: {walletId},
            body: {nickname}
        }, {});
    return response.data.krwPrice;
}