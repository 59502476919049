import {Module} from "vuex";
import {RootState} from "../index";

export interface TermsState {
    service: boolean,
    toggleTermsModal: boolean,
    personalInfo:boolean,
    toggleTermsPersonalInfo:boolean,
    marketing:boolean,
    toggleTermsMarketing:boolean
}

export const termsModule: Module<TermsState, RootState> = {
    namespaced: true,
    state: {
        service: false,
        toggleTermsModal: false,
        personalInfo:false,
        toggleTermsPersonalInfo:false,
        marketing:false,
        toggleTermsMarketing:false
    },
    mutations: {
        TERMS_SERVICE_ACCEPT: (state, check) => {
            state.service = check;
        },
        TERMS_SERVICE_MODAL: (state, open) => {
            state.toggleTermsModal = open;
        },

        TERMS_PERSONAL_INFO_ACCEPT: (state, check) => {
            state.personalInfo = check;
        },
        TERMS_PERSONAL_INFO_MODAL: (state, open) => {
            state.toggleTermsPersonalInfo = open;
        },

        TERMS_MARKETING_ACCEPT: (state, check) => {
            state.marketing = check;
        },
        TERMS_MARKETING_MODAL: (state, open) => {
            state.toggleTermsMarketing = open;
        },
        TERMS_ALL_RESET_MODAL: (state, open) => {
            state.service = false;
            state.toggleTermsModal = false;
            state.personalInfo = false;
            state.toggleTermsPersonalInfo = false;
            state.marketing = false;
            state.toggleTermsMarketing = false;
        },

    },
    actions: {
        changeTermsService: ({commit}, check) => {
            commit("TERMS_SERVICE_ACCEPT", check)
        },
        openTermsService: ({commit}, open) => {
            commit("TERMS_SERVICE_MODAL", open)
        },

        changeTermsPersonalInfo: ({commit}, check) => {
            commit("TERMS_PERSONAL_INFO_ACCEPT", check)
        },
        openTermsPersonalInfo: ({commit}, open) => {
            commit("TERMS_PERSONAL_INFO_MODAL", open)
        },

        changeTermsMatketing: ({commit}, check) => {
            commit("TERMS_MARKETING_ACCEPT", check)
        },
        openTermsMarketing: ({commit}, open) => {
            commit("TERMS_MARKETING_MODAL", open)
        },
        resetTerms: ({commit}, open) => {
            commit("TERMS_ALL_RESET_MODAL", open)
        },

    },
    getters: {},
};
