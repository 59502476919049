<template>
  <gaze-modal
      :visible="true"
      class="wallet-install-modal"
      ref="installModalRef"
      :title="walletType ==='wallet' ? $t('modal.walletInstallModal.mobile.title'):$t('modal.walletInstallModal.mobile.title2')"
      @close="closePopup"
  >
    <template #content>
      <wallet-install-comp
          @canceled="closePopup"
          :wallet="curWallet"
      />
    </template>
  </gaze-modal>
</template>

<script>
import {computed, defineComponent, ref} from "vue";
import GazeModal from "@/components/common/modal/GazeModal";
import WalletInstallComp from "@/components/pages/wallet/register/WalletInstallComp";
import {store} from "@/store";
import { closePopup } from "@/script/common/popupScript";

export default defineComponent({
  name: "WalletInstallPopup",
  emits: ["canceled"],
  props: ["wallet"],
  components: {
    GazeModal,
    WalletInstallComp,
  },
  setup(props, context) {
    const curWallet = computed(() => {
      return store.state.open.popup.walletInstall.walletName
    })
    const walletType = computed(() => {
      return store.state.open.popup.type
    })
    const installModalRef = ref();

    return {
      curWallet,
      installModalRef,
      closePopup,
      walletType
    }
  }

})
</script>

<style scoped>

</style>