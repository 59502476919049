<template>
  <div class="more-contents-button">
    <button
        class="gaze-button-p1"
        @click="routeMoreContent"
    >
      {{ $t(buttonText)}}
    </button>
  </div>
</template>

<script>
import {computed} from "vue";
import router from "@/router";

export default {
  name: "MoreContentsButton.vue",
  props: {
    content: {
      default: "nft",
    }
  },
  setup(props) {
    const buttonText = computed(() => {
      switch(props.content) {
        case "nft":
          return 'listView.nftList.emptySection.nft.btnText'
        case "cltn":
          return 'listView.nftList.emptySection.cltn.btnText'
        case "member":
          return 'listView.nftList.emptySection.member.btnText'
      }
      return "알 수 없는 컨텐츠"
    })
    const routeMoreContent = () => {
      router.push({ name: 'NftListView', query: { name: props.content, tokenStnd: '' }})
    }
    return {
      buttonText,
      routeMoreContent,
    }
  }
}
</script>

<style scoped>

</style>