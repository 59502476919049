import mobileSideBarMenu from "@/components/layout/mobile/sidebar/mobileSideBarMenu";
import {notExist} from "@/utils/rules";
import {openPopup} from "@/script/common/popupScript";

export default {
    common: {
        header: {
            signIn: '로그인',
            shoppingHover: {
                category: {
                    title: 'NFT 카테고리',
                    curation: 'gaze 큐레이션',
                    underTen: '10만원 이하',
                    digitalArt: '디지털 아트',
                    memberTicket: '멤버십/티켓',
                    wine: '와인',
                    finance: '금융'
                },
                priceUnit: {
                    title: '거래 통화',
                    klay: 'KLAY',
                    matic: 'MATIC',
                    bottomTitle: 'SHOP 컬렉션',
                    more: '더보기'
                }
            },
            searchBar: {
                placeholder: 'NFT, 컬렉션, 사용자 검색'
            },
            loggedInHoverMenu: {
                myNft: '나의 NFT',
                dashboard: '대시보드',
                paymentManagement: '결제수단 관리',
                accountSetting: '계정 설정',
                logout: '로그아웃'
            },
            mobile: {
                mobileSideBarMenu: {
                    nftName: 'NFT 검색',
                    nftShopping: 'NFT 쇼핑',
                },
                mobileLoginSection: {
                    loginBtn: '로그인',
                    signUpBtn: '회원가입',
                    myNft: '나의 NFT',
                    dashboard: '대시보드',
                    paymentManagement: '결제수단 관리',
                    accountSetting: '계정 설정',
                    logout: '로그아웃'
                },
                mobileNftSearchSection: {
                    cltn: '컬렉션',
                    member: '멤버',
                    noResult: '검색 결과가 없습니다.',
                    allResult: '검색된 모든 '
                },
                mobileNftShoppingSection: {
                    category: {
                        title: 'NFT 카테고리',
                        curation: 'gaze 큐레이션',
                        underTen: '10만원 이하',
                        wine: '와인',
                        digitalArt: '디지털 아트',
                        ticketMember: '티켓/패스',
                        finance: '금융',
                    },
                    priceUnit: {
                        title: '거래통화',
                    }

                }
            }
        },
        footer: {
            infoSection: {
                company: '상호: 주식회사 개런터블',
                ceo: '대표: 이동주',
                policyAdmin: '개인정보관리책임자: 구본성',
                address: '주소: 서울특별시 송파구 송파대로 201 테라타워2차 A동 1016호',
                email: '이메일 : {email}',
                businessRegistrationNumber: '사업자등록번호 : 575-87-02196',
                copyRight: '© 2022 gaze. All Rights Reserved',
                termsConditions: '이용약관',
                privacyPolicy: '개인정보처리방침',
                businessInfo: '사업자정보확인'
            },
            contactUs: {
                title: '문의하기',
                contents1: '안녕하세요, gaze에 관심 가져주셔서 감사해요.',
                contents2: '어떤 내용이 궁금하신가요?',
                contents3: '먼저, 다른 고객들이 자주 궁금해하는 질문을 확인해보세요.',
                contents4: '도움말 및 FAQ 보러가기',
                contents5: '그래도 해결이 안되셨다면',
                contents6: '고객지원 팀에 문의해주세요.',
                contents7: '고객지원 팀에 문의하기',
            },
            aboutSection: {
                title: '시선이 머문 곳에, 새로운 라이프의 시작',
                slide: {
                    finance: '금융',
                    wine: '와인',
                    ticket: '티켓',
                    membership: '멤버십',
                    bag: '가방',
                    jewelry: '쥬얼리',
                },
                desc: {
                    title1: '라이프스타일',
                    title2: 'NFT',
                    title3: '커머스 플랫폼',
                    contents1: '누구나 자신이 열망하고 상상하는 가치를 새롭게 만들고,',
                    contents2: '향유할 수 있도록 마음 속 시선이 사로잡히는 공간 “gaze 👀”',
                },
                ourMission: {
                    imagine: {
                        title1: '상상하는 것을',
                        title2: '실현해요.',
                        title3: ''
                    },
                    mingle: {
                        title1: '관심사가 비슷한',
                        title2: '사람과 어울려요.',
                        title3: ''
                    },
                    keep: {
                        title1: '안전하게',
                        title2: '소장해요.'
                    },
                    mobile: {
                        imagine: {
                            text1: '상상하는 것을',
                            text2: '실현해요'
                        },
                        mingle: {
                            text1: '관심사가 비슷한',
                            text2: '사람과 어울려요.'
                        }
                    }
                },
                descTwoSection: {
                    engage: {
                        text1: '일상 생활 속 가치 있는 상품, 그리고 경험의 순간들을 기록하여',
                        text2: '안전하게 사고 팔 수 있습니다.',
                        text3: '일상에서 이용하는 다양한 서비스와 상품을 구매하고',
                        text4: '양도하거나 판매할 수 있는'
                    },
                    mingle: {
                        text1: '관심사가 비슷한 멤버와 함께 즐기고 소통하는',
                        text2: '관심 주제 기반 소셜 라이프를 제공합니다.',
                        text3: '멤버들 간 NFT 트렌드를 교류하는 실시간 SNS 피드',
                        text4: '멤버들과 관심 주제로 소통하는 리워드 기반 커뮤니티',
                    },
                    keep: {
                        text1: '나의 소장 제품과 서비스 이용 경험, 타인과의 거래한 모든 정보들을',
                        text2: '인증된 gaze 블록체인 기술을 통해 안전하게 보관합니다.',
                        text3: '모든 자산과 거래내역을 안전하게 보장하는 안심 기술',

                    }
                },
                descThreeSection: {
                    text1: 'gaze는 블록체인 기술을 통해 구성원 모두가 참여자가 되어',
                    text2: '상품과 콘텐츠를 주체적으로 만들어나가',
                    text3: '삶의 다양한 영역에 주도권을 되찾기 위한 새로운 라이프 문화를 만들어나갑니다.',
                }
            }
        },
        filter: {
            priceUnit: [
                {
                    unit: '모든 통화',
                },
                {
                    unit: 'KLAY',
                },
                {
                    unit: 'MATIC',
                },
            ],
            statusList: [
                {
                    status: '모든 상태',
                },
                {
                    status: '미판매',
                },
                {
                    status: '판매중',
                },

            ],
            nftSortOptions: [
                {
                    option: '최근 등록순',
                },
                {
                    option: '인기 높은순',
                },
                {
                    option: '인기 낮은순',
                },
                {
                    option: '높은 가격순',
                },
                {
                    option: '낮은 가격순'
                },
            ],
            searchNft: 'NFT 검색',
            searchPrice: {
                title: '가격',
                maxPrice: '최대가격 *',
                minPrice: '최소가격 *',
                applyBtn: '적용하기'
            },
            chrtNft: 'NFT 특성',
            mobile: {
                chrtNft: {
                    title: 'NFT 특성',
                    filter: 'NFT 특성 필터',
                    complete: '완료',
                    allReset: '모두 초기화',
                    search: 'NFT 검색'
                }
            },
            total: '총',
            count: '개',
            onlySale: '판매중인 상품만 보기'
        },
        nftCardComp: {
            leftDay: ' 일 남음',
            about: '약 ',
            saleBtn: '구매하기',
            unSaleBtn: '미판매',
            noPrice: '판매가 없음',
            highOfferPrice: '최고 제안 금액',
        },
        datePicker: {
            placeholder: '날짜 선택',
            locale: 'ko',
            close: '취소',
            ok: '결정'
        },
        metamask: '메타마스크',
        kaikas: '카이카스'
    },
    home: {
        first: {
            title1: '시선을',
            title2: '사로잡는',
            title3: 'NFT.',
            contents1: '나만의 가치 있는 NFT 상품을 소유하고',
            contents2: 'NFT 홀더만의 혜택을 누려보세요.',
            buttonText: 'NFT 탐색하기',
        },
        nftCarouselView: {
            titleContents: '모든 NFT 상품보기',
            leftDay: ' 일 남음',
            about: '약 ',
            saleBtn: '구매하기',
            unSaleBtn: '미판매'
        },
        onboardingView: {
            title: 'NFT가 처음인가요?',
            card1: {
                title: '결제 통화 준비',
                contents1: '거래 통화인',
                contents2: '코인이 필요해요',
                buttonText: '코인 구매 방법'
            },
            card2: {
                title: '결제 수단 연결',
                contents1: '코인을 담을',
                contents2: '지갑이 필요해요',
                buttonText: '지갑 만드는 방법'
            },
            card3: {
                title: 'NFT 구매',
                contents1: '서명을 하여',
                contents2: 'NFT를 구매해요',
                buttonText: 'NFT 구매 방법'
            },
            card4: {
                title1: 'NFT',
                title2: '바로 구매하기',
            },
        },
        membersView: {
            moreBtn: '더보기',
            mobile: {
                moreBtn: '모든 콜렉터 보기'
            }
        },
        trendingTags: {
            comingUp: '지금 뜨는',
            new: 'new',
            wine: '와인',
            best: 'best',
            membership: '멤버쉽',
            collectors: 'collectors',
            klay: '클레이',
            aboutGaze: 'about gaze',
            popularCollection: '인기 컬렉션',
            nft: 'NFT',
            matic: '마틱'
        },
        mobile: {
            first: {
                title1: '시선을 사로잡는',
                title2: 'NFT',
                contents1: '나만의 가치 있는 NFT 상품을 소유하고',
                contents2: 'NFT 홀더만의 혜택을 누려보세요.',
            }
        }
    },
    listView: {
        nftList: {
            title: 'NFT',
            bannerSection: {
                leftSection: {
                    title1: '나의 시선',
                    title2: '을 사로잡는 NFT',
                },
                rightSection: {
                    purchase: '구매하고',
                    forSale: '팔고',
                    resell: '양도하는'
                },
            },
            filterSection: {
                nftFilter: {
                    cardFilterList: [
                        {
                            title: 'gaze 큐레이션'
                        },
                        {
                            title: '가장 인기있는'
                        },
                        {
                            title: '10만원 이하'
                        },
                        {
                            title: '와인'
                        },
                        {
                            title: '티켓/멤버십'
                        },
                        {
                            title: '디지털 아트'
                        },
                        {
                            title: '뮤직'
                        },
                        {
                            title: '금융'
                        },
                    ],
                    nftSortOptions: {
                        mostRecent: '최근 등록순',
                        mostPopular: '인기 높은순',
                        leastPopular: '인기 낮은순',
                        highPrice: '높은 가격순',
                        rowPrice: '낮은 가격순'
                    },
                },
                cltnFilter: {
                    searchCltn: '컬렉션 검색',
                    cltnSortOptions: {
                        mostRecent: '최근 등록순',
                        mostPopular: '인기 높은순',
                        leastPopular: '인기 낮은순',
                        highNth: '높은 거래량순',
                        rowNth: '낮은 거래량순'
                    },
                },
                memberFilter: {
                    searchMember: '유저 검색'
                },
            },
            emptySection: {
                nft: {
                    title: 'NFT 목록이 없습니다.',
                    btnText: 'gaze 추천 NFT 보러가기'
                },
                cltn: {
                    title: '컬렉션 목록이 없습니다.',
                    btnText: 'gaze 추천 컬렉션 보러가기'
                },
                member: {
                    title: '유저 목록이 없습니다.',
                    btnText: 'gaze 추천 멤버 보러가기'
                }
            }

        },
        collectionList: {
            title: '컬렉션'
        },
        memberList: {
            title: '멤버'
        }
    },
    nftDetailView: {
        publicNo: '비공개 상품입니다.',
        explain: {
            title: '설명'
        },
        chrtInfo: {
            title: '특성정보'
        },
        benefitDetail: {
            title: '혜택정보',
            benefit: '혜택'
        },
        blockInfo: {
            title: '상세 정보',
            tksd: '토큰 스탠다드',
            blockChainNetwork: '블록체인 네트워크',
            creatorLoyalty: '창작자 로열티'
        },
        cltnInfo: {
            title: '컬렉션 정보',
            nftCount: 'NFT수',
            owner: '소유자',
            volume: '거래량',
            leastPrice: '최저 거래가'
        },
        otherNftList: {
            title: '이 컬렉션의 다른 NFT'
        },
        priceInfo: {
            title: '',
            owner: '소유자',
            price: '판매 금액',
            highOfferPrice: '최고 제안 금액',
            lastSalePrice: '마지막 거래 금액',
            deadline: '일 후 판매가 마감됩니다.',
            buyBtn1: ' 에 구매하기',
            buyBtn2: '',
            unSaleBtn: '미판매',
            updateBtn: '판매정보 수정하기',
            registerBtn: '판매 등록하기',
            registerCancelBtn: '판매등록 취소하기',
            offerBtn: '가격 제안하기',
            offerCancelBtn: '가격 제안 취소하기',

        },
        historySection: {
            title: '히스토리',
            filter: {
                transferType: {
                    register: '판매 등록',
                    offer: '가격 제안',
                    modify: '금액 수정',
                    transfer: '지갑 전송'
                },
                applyBtn: '적용',
                cancelBtn: '취소'
            },
            timeline: {
                buy: 'NFT 구매',
                first: '최초 등록',
                register: '판매 등록',
                transfer: 'NFT 지갑 전송',
                offer: '가격 제안',
                accept: '제안 수락',
                reject: '제안 거절',
                offerCancel: '제안 취소',
                cancel: '판매 취소',
                modify: '금액 수정',
                more: '더보기',
                empty: '히스토리가 없습니다'
            }
        },
        mobile: {
            tab: {
                details: '상세정보',
                benefits: '혜탹정보',
                history: '히스토리'
            },
            footer: {
                cancel: '판매 취소하기',
                update: '판매 정보 수정하기',
                register: '판매 등록하기',
                unSale: '미판매',
                offer: '가격 제안하기',
                offerCancel: '가격 제안 취소하기',
                buy: '구매하기'
            },

        }
    },
    cltnDetailView: {
        title: '컬렉션 정보',
        nftCount: 'NFT수',
        owner: '소유자',
        volume: '거래량',
        leastPrice: '최저 거래가',
        nftUtilGuide: 'NFT 유틸리티 클레임 가이드',
        empty: 'NFT 목록이 없습니다.'

    },
    modal: {
        nftBuy: {
            title: 'NFT 결제 진행',
            subTitle: 'NFT 구매정보',
            owner: '소유자',
            amount: 'NFT 상품 금액',
            serviceFee: '서비스 수수료',
            totalAmount: '총 결제 금액',
            buyBtnText1: '',
            buyBtnText2: ' 를 결제합니다.',
            mobile: {
                title: '구매할 NFT 정보를 확인해주세요.'
            }
        },
        multiNftBuy: {
            title: 'NFT 결제 진행',
            subtitle: 'NFT 구매정보',
            total: '총',
            count: '건',
            owner: '소유자',
            nftPrice: 'NFT 상품 금액',
            totalPrice: '총 결제 금액',
            buyBtnText1: '',
            buyBtnText2: '를 결제합니다.',
            mobile: {
                title: '구매할 NFT 정보를 확인해주세요.'
            }
        },
        nftRegister: {
            title: '판매 등록',
            subtitle: 'NFT 판매 등록을 합니다',
            currentPrice: '최근 거래 금액',
            price: '금액',
            canOffer: '가격제안도 받을 수 있어요',
            platformFee: '플랫폼 수수료',
            creatorLoyalty: '창작자 로열티',
            totalAmount: '총 예상 수령 금액',
            setSalePeriod: {
                title: '판매 기간 설정',
                none: '설정하지 않음',
                oneDay: '1일',
                twoDay: '2일',
                oneWeek: '1주',
                sixMonth: '6개월',
            },
            saleRegisterBtn: '판매 등록하기',
            failRegister: '판매 등록에 실패했습니다.',
            mobile: {
                setSalePeriod: {
                    subtitle: '기간 설정하기',
                },
                dayOptions: [
                    {
                        period: '기간 없음'
                    },
                    {
                        period: '1 일'
                    },
                    {
                        period: '2 일'
                    },
                    {
                        period: '1 주일'
                    },
                    {
                        period: '6 개월'
                    },
                ]
            }
        },
        nftModify: {
            title: '수정 하기',
            subtitle: 'NFT 판매 정보를 수정합니다',
            nowPrice: '현재 판매 금액',
            saleModifyBtn: '판매 수정하기'
        },
        nftOffer: {
            title: 'NFT 가격 제안',
            subtitle: '가격을 제안합니다.',
            nowPrice: '현재 판매 금액',
            hopePrice: '희망 금액',
            notice: {
                title: '가격 제안 전 확인해주세요!',
                text1: 'NFT 소유자가 가격제안을 수락하면 gaze에서 소유권을 당사자에게 이전합니다.',
                text2: '가격제안 시, 제안금액을 선결제가 진행되며 제안을 직접 취소하거나 거절되는 경우 (소유자 제안거절, 제안기간 만료) 결제 취소처리 되며 최대 15분 이내 지갑으로 환급됩니다.'
            },
            offerBtn: '판매 제안하기',
            mobile: {
                subtitle: '제안할 NFT 정보를 확인해주세요.'
            },
        },

        signModal: {
            title: '서명하기',
            desc: '서명을 진행하여 모든 단계를 완료해주세요.',
            buy: {
                title: 'NFT 구매',
                pendingTitle: '결제 지연 안내',
                pendingContents: '결제'
            },
            offer: {
                title: '가격제안',
                pendingTitle: '제안 지연 안내',
                pendingContents: '제안'
            },
            saleCancel: {
                title: '판매취소'
            },
            signBtnText: '서명하기',
            signReason: '서명을 하는 이유?'
        },
        pendingModal: {
            ko: {
                subtitle1: '블록체인 네트워크 지연으로',
                subtitle2: '대기중',
                subtitle3: ' 입니다.',
                notice1: '네트워크 상황에 따라 최대 10분 소요될 수 있으며,',
                notice2: '처리 현황을 실시간 확인 할 수 있습니다.'
            },
            en: {
                subtitle1: '',
                notice1: '',
                notice2: '',
                notice3: ''
            },
            waitBtn: '기다리는 동안 쇼핑',
            checkBtn: '현황 확인',
            whyPending: '결제가 지연되는 이유는?'
        },
        confirmModal: {
            success: {
                offer: '정상적으로 제안이 완료되었습니다.',
                offerCancel: '정상적으로 제안이 취소되었습니다.',
                reject: '정상적으로 제안이 거절되었습니다.',
                offerAccept: '정상적으로 제안이 수락되었습니다.',
                register: '판매 등록이 완료되었어요.',
                update: '판매 수정이 완료되었어요.',
                cancel: '판매 중지가 완료되었어요.',
                buy: '구매가 완료되었어요.'
            },
            fail: {
                // 제안
                offer: '제안에 실패했습니다.',
                offerReason: '제안이 실패된 이유는?',

                offerCancel: '제안 취소에 실패했습니다.',
                offerCancelQaRouter: '제안 취소가 실패된 이유는?',

                offerReject: '제안 거절에 실패했습니다.',
                offerRejectQaRouter: '제안 거절에 실패된 이유는?',

                offerAccept: '제안 수락에 실패했습니다.',
                offerAcceptQaRouter: '제안 수락이 실패된 이유는?',
                // 판매
                register: '판매 등록을 실패했어요.',
                registerQaRouter: '등록이 실패된 이유는?',

                update: '판매 수정을 실패했어요.',
                updateQaRouter: '수정이 실패된 이유는?',

                cancel: '판매 취소에 실패했어요.',
                cancelQaRouter: '판매 취소에 실패한 이유는?',

                // 구매
                buy: '구매에 실패했어요.',
                buyQaRouter: '구매에 실패한 이유는?',

                // 공통
                buyFail: '구매에 실패했습니다.'
            },
            openModal: {
                previousRequest: '이전 요청을 처리중입니다.',
                anotherWallet: '다른 계정에 등록된 지갑주소입니다.',
                noActive: '활성화된 제안이 아닙니다.',
                noSaleAnotherWallet: '다른 계정에 등록된 지갑으로는 구매를 할 수 없습니다.',
                consecutiveNum: '연속된 4자리 숫자 or 문자 비밀번호',
                notYourOwnNFT: '자신의 NFT는 장바구니에 담을 수 없습니다.',
                notYourOwnWallet: {
                    title: 'NFT를 소유하고 있는 지갑이 아닙니다.',
                    contents: 'NFT를 소유하고 있는 지갑으로 서명해주세요.'
                },
                failSaleCancel: '판매취소에 실패했습니다.',
                notYourOwnOffer: {
                    title: '제안을 했던 지갑이 아닙니다.',
                    contents: '제안을 했던 지갑으로 서명해주세요.'
                },
                over1Won: '1원 이상의 금액을 입력해주세요',
                over0Won: '0원 이상의 금액을 입력해주세요',
                notForSale: '판매중인 상품이 아닙니다.'
            },
            sale: {
                register: '판매 등록이 완료되었어요.',
                update: '판매 수정이 완료되었어요.',
                cancel: '판매 중지가 완료되었어요.',
                buy: '구매가 완료되었어요.'
            },
            check: '확인'
        },
        walletInstallModal: {
            pc: {
                title: ' 지갑이 아직 설치되지 않았습니다.',
                desc1: '크롬 확장프로그램 설치가 필요합니다.',
                desc2: '설치 후 새로고침을 진행해주세요.',
                english: {
                    // The MetaMask wallet has not been installed yet.
                    text1: '',
                    text2: '',
                    text3: '',
                    text4: ''
                }
            },
            mobile: {
                title: '지갑 설치 안내',
                title2: '결제 진행 안내',
                contents: {
                    title: ' 앱이 필요합니다.',
                    text1: '모바일에서 진행하기 위해서는 ',
                    text2: ' 앱에서 서명을 하여야 합니다.',
                    text3: ' 앱으로 이동 하시겠습니까?',
                },
                ok: '확인',
                install: '설치 하기',
                move: '이동 하기',
                cancel: '취소',
                en: {
                    contents: {
                        // To proceed on mobile, you need to sign the transaction using the Metamask app.
                        title: '',
                        text1: '',
                        text2: '',
                        text3: '',
                        question: ''
                    },
                }
            },
        },
        warningPopup: {
            //modal.warningPopup.connectMetamask
            // modal.warningPopup.isMyNft
            isMyNft: '자신의 NFT는 살 수 없습니다.',
            connectMetamask: '메타마스크 지갑을 연결해주세요.',
            signDiscontinued: '서명이 중단되었습니다.',
            signTerminated: '서명에 실패했습니다.',
            notSale: '판매중인 상품이 아닙니다.',
        },
        loginAlertPopup: {
            title: '로그인이 필요한 서비스입니다.',
            contents: '로그인 페이지로 이동합니다.',
            ok: '확인'
        },

    },
    login: {
        title: '로그인',
        email: '이메일 주소 *',
        password: '비밀번호 *',
        loginBtnText: '로그인',
        forgotPassword: '비밀번호를 잊으셨나요?',
        notYetGaze: 'gaze 계정이 아직 없나요?',
        signup: '회원가입 하기',
        notExactly: '입력하신 계정 정보가 맞지 않습니다.',
        unverifiedPage: {
            title: '계정 인증을 완료해주세요.',
            contents: '가입하신 이메일 주소로 인증 코드를 다시 발송했어요.',
            email: '',
            question: '가입 인증 메일을 아직 못 받았나요?',
            resend: '인증코드 재발송',
            btnText: '확인'
        },
        unverifiedModal: {
            title: '인증코드를 재발송했습니다.',
            ok: '확인',
            cancel: '취소'
        },
        waitVerified: {
            titleSignup: '이메일 인증을 하여\n회원가입을 완료하세요.',
            titlePassword: '이메일 인증을 하여\n회원가입을 완료하세요.',
            subtitle: '가입한 이메일 주소로 인증 메일을 보냈습니다.',
            subtitle2: '인증을 완료하여 계정을 활성화 하세요. 이 링크는 24시간 후 만료됩니다.',
            subtitle3: '인증을 완료하여 비밀번호 변경을 완료하세요.',
            subtitle4: '이 링크는 30분 후 만료됩니다.',
            waitEmail: '이메일 인증을 기다리고 있습니다.'
        },
        verified: {
            title: 'gaze의 멤버가 되신 것을\n환영합니다!',
            contents: '이메일이 성공적으로 인증되었습니다.',
            loginBtn: '로그인하러 가기'
        },
        expired: {
            title: '해당 인증링크가 만료되었습니다.',
            contents: {
                text1: '해당 인증 링크는 이미 만료되었습니다.',
                text2: '아래 버튼을 클릭하여 다시 인증 링크를 보내 인증을 완료하세요.'
            },
            resendBtn: '인증링크 다시 보내기',
            notReceiveVerifyEmail: '가입 인증 메일을 받지 못했나요?',
            notReceivePasswordEmail: '비밀번호 인증 메일을 받지 못했나요?',
            invisibleEmail: '이메일이 보이지 않는 경우 스펨 메일함을 확인해주세요.'
        },
        mobile: {
            unverifiedPage: {
                title: '',
                contents: '가입하신 이메일 주소로 인증 코드를 다시 발송했어요.',
                question1: '',
                question2: '가입 인증 메일을 아직 못 받았나요?',
            },
        }
    },
    signup: {
        title: '회원가입',
        email: '이메일 주소 *',
        nickname: '닉네임 *',
        password: '비밀번호 *',
        passwordCheck: '비밀번호 확인 *',
        terms: {
            service: '[필수] gaze 서비스 이용 약관에 동의',
            personal: '[필수] 개인정보 수집 이용에 동의',
            marketing: '[선택] 마케팅 수신 채널에 동의'
        },
        lang: {
            korean: '한국어',
            english: '영어',
        },
        signupBtn: '회원가입',
        alreadyAccount: '이미 계정이 있나요?',
        loginBtn: '로그인 하기'
    },
    findPassword: {
        title: '비밀번호 재설정을 위해 가입 시\n입력했던 이메일을 입력해주세요.',
        email: '이메일 주소 *',
        emailBtn: '이메일 보내기',
        signupBtn: '회원가입 하기',
        loginBtn: '로그인 하기',
        mobile: {
            title: '비밀번호 변경이\n완료되었어요.',
            loginBtn: '로그인 하러 가기'
        }
    },
    resetPassword: {
        title: '비밀번호를 재성정해주세요.',
        subtitle: '비밀번호 입력 규칙을 확인해주세요.\n',
        contents1: '* 연속적인 숫자, 생일, 전화번호 등 비슷한 설정을 제외하고 비밀번호를 변경해주세요.',
        contents2: "* 영문, 숫자, 기호를 모두 포함(8~20자리)을 입력해주세요.",
        password: '비밀번호 *',
        passwordConfirm: '비밀번호 확인 *',
        passwordResetBtn: '비밀번호 재설정'
    },
    waitPassword: {
        title: '비밀번호 재설정을 위한 이메일이\n해당 주소로 발송되었습니다.',
        email: '이메일'
    },
    myPage: {
        profileEdit: '프로필 수정',
        navigator: {
            haveNft: '보유 NFT',
            itrtNft: '관심 NFT',
            itrtCltn: '관심 컬렉션'
        },
        nftCard: {
            shareSelect: {
                linkCopy: '링크 복사',
                twitter: '트위터 공유',
                facebook: '페이스북 공유'
            },
            shareBtn: '공유하기',
            engLeftDay: '',
            leftDay: ' 일 남음',
            noPrice: '판매가 없음',
            highOfferPrice: '최고 제안금액',
            buyBtn: '구매하기',
            emptyNftList: 'NFT 목록이 없습니다.',
            unSaleBtn: '미판매',
            updateBtn: '판매 정보 수정하기',
            registerBtn: '판매 등록하기',
            registerCancelBtn: '판매 취소하기',

        },
        cltnCard: {
            nftCount: 'NFT수',
            owner: '소유자',
            volume: '거래량',
            lowestPrice: '최저 거래가',
            storePage: '스토어 페이지',
            emptyCltnList: '컬렉션 목록이 없습니다.'
        }
    },
    dashboard: {
        sttc: {
            title: '대시보드',
            subtitle: '내 자산',
            explain: '현재 이만큼의 코인을 보유하고 있어요.',
            totalExplain: '현 시세기준 보유가치를 선정했습니다.',
            klay: '클렉이',
            matic: '마틱',
            myNft: '나의 NFT',
            myNftExplain: '현재 이만큼의 NFT를 보유하고 거래 진행 중에 있어요.',
            totalHaveNft: '전체 보유 NFT',
            saleNFt: '판매중인 NFT',
            offerCount: '가격 제안한 건수',
            getOfferCount: '가격 제안받은 건수',
            count: ''
        },
        walletManage: {
            title: 'NFT 현황 목록',
            explain: '지갑을 등록해서 NFT 거래 현황을 한 곳에 모아보세요.',
            subtitle1: '총',
            subtitle2: '개',
            subtitle3: '지갑이 연결되어 있어요.',
            managePayment: '결제 수단 관리',
            wallet: {
                kaikas: '카이카스',
                metamask: '메타마스크',
                register: '지갑등록',
                failTitle: '결제수단 등록 실패',
                failContents: '결제수단이 정상적으로 등록되지 않았습니다.',
                reRegister: '다시 등록하기',
                alreadyRegisterTitle: '지갑 중복 등록 안내',
                alreadyRegister: '이미 다른 계정에 등록된 지갑입니다.',
                alreadyRegisterSolve: '지갑이 이미 등록된 경우 해결방법은?',
                ok: '확인'

            },
            mobile: {
                subtitle: '개 지갑 연결중'
            }
        },
        trstList: {
            navigator: {
                haveNft: '보유 NFT',
                offerNft: '제안중인 NFT',
                history: '지난 거래이력',
                filter: {
                    sortOptions: [
                        {
                            option: '최근순'
                        },
                        {
                            option: '인기 높은순'
                        },
                        {
                            option: '인기 낮은순'
                        },
                        {
                            option: '높은 가격순'
                        },
                        {
                            option: '낮은 가격순'
                        },
                    ],
                    statusOptions: [
                        {
                            option: '모든 통화'
                        },
                        {
                            option: 'KLAY'
                        },
                        {
                            option: 'MATIC'
                        },
                    ],
                }

            }
        },
        emptyComp: {
            text1: '아직 gaze와 함께한 여정이 없군요.',
            text2: '지금 gaze에서 잘 나가는 상품을 확인해보세요!'
        },
        searchEmptyComp: {
            nothing: '검색 결과가 없습니다.'
        },
        priceName: {
            default: '가격',
            TRANSFER: '구매 가격',
            REGISTER: '판매 등록 가격',
            MODIFY: '수정 가격',
            CANCEL: '판매 취소 가격',
            ACCEPT: '제안 수락 가격',
            OFFER: '제안 가격',
            OFFER_CANCEL: '제안 취소 가격',
            REJECT: '제안 거절 가격',
        },
        buttonName: {
            REGISTER: '판매 등록',
            MODIFY: '판매 정보 수정',
            CANCEL: '판매 취소',
            OFFER_CANCEL: '제안 취소',
            REJECT: '제안 거절',
            ACCEPT: '제안 수락',
        }
    },
    paymentManage: {
        title: '결제수단 관리',
        subtitle: '지갑을 등록해서 NFT를 구매해보세요.',
        notHaveWallet: '블록체인 지갑이 없나요?',
        addWallet: '지갑 추가 등록',
        copy: '복사완료!',
        changeWalletNick: '지갑 별칭 변경',
        deleteWallet: '지갑 삭제',
        success: {
            title: '다음 지갑을 등록할까요?',
            subtitle: '지갑에서 계정 변경시 주소정보가 변경됩니다',
            error: '지갑 주소를 불러오는데 실패했습니다. 새로고침 후 재시도해 주세요',
            register: '등록',
            cancel: '취소',
        },
        activeWallet: '활성화 계정이 변경되었습니다',
        beforeDeleteWallet: {
            deleteCopleteWallet: '지갑 삭제가 완료되었습니다.',
            remainProduct: '판매중인 상품이 존재합니다.',
            representativeWallet: '대표 지갑은 삭제할 수 없습니다.'
        },
        rejectSign: '서명을 거부하였습니다.'
    },
    saleConfigComp: {
        register: {
            title: '판매등록'
        },
        update: {
            title: '판매수정'
        },
    },
    userConfig: {
        title: '계정설정',
        profileInfo: '프로필 정보',
        individualInfo: '개인 정보',
        uploadImg: "프로필 사진 업로드 하기",
        deleteImg: '프로필 사진 삭제',
        nickname: '닉네임',
        nicknameChange: '닉네임 변경',
        nickPlaceholder: '변경할 닉네임을 입력해주세요.',
        change: '변경하기',
        cancel: '취소',
        intro: '소개',
        connectSns: {
            title: '소셜계정 연결',
            subtitle: '소셜 계정을 추가하여 나를 알리세요.',
            insta: '인스타그램',
            facebook: '페이스북',
            twitter: '트위터',
            youtube: '유튜브',
            discord: '디스코드'
        },
        profileUpdateBtn: '프로필 정보 업데이트 하기',
        undefinedURL: '주소 형식이 맞지 않아요.',
        accounts: '계정주소',
        indv: {
            email: '이메일 주소',
            password: '비밀번호',
            passwordChange: '비밀번호 변경',
            changedNickname: '닉네임이 변경되었습니다.',
            nowPwdInput: '현재 비밀번호 입력 *',
            willChangePwdInput: '변경할 비밀번호 *',
            willChangePwdInputCheck: '변경할 비밀번호 확인 *',
            cancel: '취소',
            change: '변경하기'
        }

    },
    basket: {
        title: '장바구니',
        total: '총 ',
        count: ' 개',
        allDelete: '전체 삭제',
        buyNft1: '',
        buyNft2: ' NFT 구매하기',
        buyAll: '같은 통화인 NFT의 경우에 한하여 한번에 구매가 가능합니다.',
        empty: {
            text1: '장바구니에',
            text2: ' NFT를 담아보세요'
        },
        cardComp: {
            saleImpossible: '현재 거래 불가능',
            remove: '항목 삭제'
        }
    },
    paymentComplete: {
        success: {
            title: 'NFT 구매 완료',
            carouselComp: {
                pc: {
                    total: '총',
                    count: '건',
                    title: '',
                    subtitle1: '의 NFT를 구매',
                    subtitle2: '완료',
                    subtitle3: '했습니다.',
                },
                mobile: {
                    total: '총',
                    subtitle1: '의',
                    subtitle2: 'NFT를 구매 완료했습니다.',
                }

            },
            moreShoppingBtn: '쇼핑더하기',
            goToListBtn: '구매 목록으로 가기'
        },
        multiSuccess: {
            success: {
                title: 'NFT 구매 완료',
                someSuccess: '일부 NFT 구매 완료'
            },
            fail: {
                title: 'NFT 구매 실패',
                againPurchase: '다시 구매를 진행할까요?',
                whyFailPurchase: 'NFT 구매가 실패되는 이유, 해결방법은?'
            },
            moreShoppingBtn: '쇼핑더하기',
            goToListBtn: '구매 목록으로 가기',
            againPurchaseBtn: '다시 구매 시도하기'
        },
        failCarouselComp: {
            pc: {
                total: '총 ',
                count: '건',
                subtitle1: '의 NFT를 구매',
                subtitle2: '실패',
                subtitle3: '했습니다.',
                warningText: '구매 실패한 NFT 결제금액은 15분 후에 개인지갑으로 자동 환급됩니다.'
            },
            mobile: {
                total: '총 ',
                count: '건',
                title: '의',
                subtitle: 'NFT를 구매 실패했습니다.',
                warningText: '구매 실패한 NFT 결제금액은 15분 후에 개인지갑으로 자동 환급됩니다.'
            }
        },
        fail: {
            title: 'NFT 구매 실패',
            againPurchase: '다시 구매를 진행할까요?',
            againPurchaseBtn: '다시 구매 시도하기',
            whyFailPurchase: 'NFT 구매가 실패되는 이유, 해결방법은?',
            moreShoppingBtn: '쇼핑더하기',
            goToListBtn: '구매 목록으로 가기',
        }
    },

    dashFilter: {
        SALE: '판매중',
        UNSALE: '미판매',
        OFFER_SEND: '제안 요청',
        OFFER_RECEIVE: '제안 받음',
        SELL: '판매',
        BUY: '구매',
        ACCEPT: '제안 수락',
        REJECT: '제안 거절',
        MODIFY: '수정',
        OFFER_CANCEL: '제안 취소',
        TRANSFER: '구매',
        REGISTER: '판매',
        CANCEL: '판매 취소',
        allReset: 'All reset'
    },
    nftStatusFilter: {
        SALE: '판매중',
        UNSALE: '미판매',
        OFFER_SEND: '제안 요청',
        OFFER_RECEIVE: '제안 받음',
        SELL: '판매',
        BUY: '구매',
        ACCEPT: '제안 수락',
        REJECT: '제안 거절',
        MODIFY: '가격 수정',
        OFFER_CANCEL: '제안 취소',
        TRANSFER: '구매',
        REGISTER: '판매 등록',
        CANCEL: '판매 취소',
        OFFER: '제안'
    },
    historyFilter: {
        SALE: 'For sale',
        UNSALE: 'Not for sale',
        OFFER_SEND: 'Offer request',
        OFFER_RECEIVE: 'Offer received',
        SELL: 'Sale',
        BUY: 'Purchase',
        ACCEPT: 'Offer accept',
        REJECT: 'Offer rejection',
        MODIFY: 'Price modification',
        OFFER_CANCEL: 'Offer cancellation',
        TRANSFER: 'Purchase',
        REGISTER: 'Sale listing',
        CANCEL: 'Cancellation of sale',
        OFFER: 'Offer'
    },
    nftResultGroup: {
        SUCCESS: '성공',
        FAIL: '실패',
        PENDING: '대기',
        default: ''
    },
    wallet: {
        kaikas: '카이카스',
        metamask: '메타마스크'
    },
    systemPage: {
        check: {
            title: '시스템 점검중',
            contents: '보다 안정적인 서비스를 제공하기 위해 시스템 점검 중입니다.\n' +
                '아래 기간 동안 전체 서비스 이용이 중지되오니\n' +
                '너른 마음으로 양해 부탁드립니다. 감사합니다.',
            date: '일시 '
        },
        connect: {
            title: '접속오류',
            contents: '일시적인 오류로 요청하신 페이지에 접속할 수 없습니다.\n' +
                '잠시 후 다시 접속해주세요.\n\n' +
                '동일한 문제가 지속적으로 발생하거나\n' +
                '궁금한 점이 있으시다면 고객센터를 통해 문의해주세요.'
        },
        error: {
            title: '시스템 장애',
            contents: '      일시적인 오류로 요청하신 페이지에 접속할 수 없습니다.\n' +
                '잠시 후 다시 접속해주세요.\n' +
                '동일한 문제가 지속적으로 발생하거나\n' +
                '궁금한 점이 있으시다면 고객센터를 통해 문의해주세요.'
        },
        contactBtn: '고객센터 문의'
    },

    rules: {
        required: '필수 항목입니다.',
        emailRequired: '필수 항목입니다',
        passwordRequired: '필수 항목입니다',
        nicknameRequired: '필수 항목입니다',
        notExist: '입력하신 계정 정보가 맞지 않습니다.',
        emailFormat: '이메일 형식에 맞지 않습니다',
        password: '영문,숫자,기호를 모두 포함(8~20자리)를 입력해주세요.',
        snsLink: '주소 형식이 맞지 않아요.',
        alreadyUse: '이미 사용중인 닉네임이에요.',
        alreadyEmail: '해당 이메일은 이미 가입되어 있어요.',
        beforeNickname: '기존 닉네임과 동일해요.',
        contiNumberSentence: '연속적인 숫자 또는 문자는 사용 할 수 없습니다.',
        nicknameCheck: '1글자 이상 50글자 이하로 입력해주세요.',
        confirmPwd: '비밀번호가 일치하지않습니다.'
    },
}