
import {defineComponent, ref, computed, createVNode, onBeforeUnmount, onMounted} from "vue";
import {useStore} from "@/store";
import {useTempImg} from "@/script/temp/tempScript";
import {useUserLogout} from "@/script/logout/logoutScript";
import {useRouter} from "vue-router";
import {useMobileSideBar} from "@/script/layout/navBarScript";

export default defineComponent({
  name: "MobileSideBarLoggedMenu",
  components: {},

  setup() {
    const store = useStore()
    const router = useRouter()
    const userProfile = computed(() => {
      return store.state.auth.userProfileImg
    })
    const userName = computed(() => {
      return store.state.auth.name
    })
    const {tempProfile} = useTempImg()
    const {logout} = useUserLogout()
    const {isOpen, isShoppingOpen, toggleSidebar, toggleSidebarDetail} = useMobileSideBar()


    const moveRouter = async (routerName: any) => {
      await router.push({name: `${routerName}`})
      await toggleSidebar()
    }
    const mobileLogout = async () => {
      await logout()
      await toggleSidebar()
    }

    return {
      userProfile,
      tempProfile,
      userName,
      logout,
      moveRouter,
      mobileLogout
    };
  },
});
