<template>
  <div v-if="isKaikasMobile()">
    <div v-show="fileTypeCheck(nft?.rsrcType)==='image'" class="item-image nft-card-img">
      <img class="thumbnail-img" v-once loading="lazy" v-if="nft?.thumbnail" :src="nft?.thumbnail ?nft?.thumbnail :nft?.rsrcPath" alt="">
      <img class="thumbnail-img" v-once loading="lazy" v-else :src="nft.rsrcPath ? nft.rsrcPath :tempNftImg.img " alt="">
    </div>
    <div @click.stop v-show="fileTypeCheck(nft.rsrcType)==='video'" class="item-image nft-card-img">
      <video id="myVideo" v-if="thumbnailCheck" :poster="nft.thumbnail ? nft.thumbnail : tempNftImg.img" autoplay webkit-playsinlin muted playsinline controls :src="nft.rsrcPath" type="video/mp4">
      </video>
      <img class="thumbnail-img" v-once loading="lazy" v-else :src="nft.thumbnail ? nft.thumbnail : tempNftImg.img" alt="">
      <button v-show="thumbnailCheck===false" class="video-play-button" @click="changeThumbnail">
        <img class="video-play-button-img" v-once loading="lazy" src="@/assets/image/icons/nft/play_icon.svg" alt="">
      </button>
<!--      <a id="play-video" class="video-launch video-play-button" rel="shadowbox;height=450;width=800" href="https://player.vimeo.com/video/198890171?color=257CB2&title=0&byline=0&portrait=0">-->
<!--        <span></span>-->
<!--      </a>-->
<!--      <div id="playButton" v-show="thumbnailCheck===false" class="play-icon" @click="changeThumbnail">-->
<!--        <img v-once loading="lazy" src="@/assets/image/icons/nft/play_icon.svg" alt="">-->
<!--      </div>-->
    </div>
  </div>
  <div v-else>
    <div v-show="fileTypeCheck(nft?.rsrcType)==='image'" class="item-image">
      <img class="thumbnail-img" v-once loading="lazy" v-if="nft?.thumbnail" :src="nft?.thumbnail ?nft?.thumbnail :nft?.rsrcPath" alt="">
      <img class="thumbnail-img" v-once loading="lazy" v-else :src="nft?.rsrcPath ? nft?.rsrcPath :tempNftImg?.img " alt="">
    </div>
    <div v-show="fileTypeCheck(nft?.rsrcType)==='video'" class="item-image">
      <video :src="nft?.rsrcPath ?nft?.rsrcPath :tempNftImg?.img" autoplay muted loop playsinline>
      </video>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, ref} from "vue";
import {isKaikasMobile} from "@/utils/windowUtils";
import {fileTypeCheck} from "@/utils/fileTypeCheckUtils";
import {useTempImg} from "@/script/temp/tempScript";


export default defineComponent({
  name: "NftImageOrVideoSmall",
  components: {},
  emits: [],
  props: {
    nft:Object
  },
  setup(props, context) {
    const {tempNftImg} = useTempImg()
    const thumbnailCheck = ref(false)
    const changeThumbnail = () => {
      thumbnailCheck.value = !thumbnailCheck.value
    }
    nextTick(() => {
      if (isKaikasMobile()) {
        const video = document.getElementById('myVideo');
        const playButton = document.getElementById('playButton');

        playButton.addEventListener('click', function () {
          video.play();
        });
      }
    })

    return {
      isKaikasMobile,
      fileTypeCheck,
      tempNftImg,
      thumbnailCheck,
      changeThumbnail
    }
  }
})
</script>

<style scoped lang="scss">
video{
  border-radius: 5px;
}
.nft-card-img{
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  position: relative;
}
.nft-card-img{
  img{
    width: 100%;
  }

}
//.play-icon{
//  -webkit-box-align: center;
//  align-items: center;
//  border-radius: 50%;
//  display: flex;
//  height: 32px;
//  -webkit-box-pack: center;
//  justify-content: center;
//  bottom: 8px;
//  right: 8px;
//  width: 32px;
//  z-index: 1;
//  position: absolute;
//  color: rgb(255, 255, 255);
//  background-color: rgba(159, 159, 159, 0.4);
//  backdrop-filter: blur(10px);
//  img{
//    width: 25px !important;
//  }
//}


//.video-play-button {
//  position: absolute;
//  bottom: 10px;
//  right: 10px;
//  z-index: 10;
//  box-sizing: content-box;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  width: 20px;
//  border-radius: 50%;
//  box-shadow: 0 0px 30px rgba(255, 175, 21, 0.5);
//  &:before {
//    content: "";
//    position: absolute;
//    z-index: 0;
//    left: 50%;
//    top: 50%;
//    transform: translateX(-50%) translateY(-50%);
//    display: block;
//    width: 30px;
//    height: 30px;
//    background: rgba(#ffaf15, 0.65);
//    border-radius: 50%;
//    animation: pulse-border 1500ms ease-out infinite;
//  }
//  &:after {
//    content: "";
//    position: absolute;
//    z-index: 1;
//    left: 50%;
//    top: 50%;
//    transform: translateX(-50%) translateY(-50%);
//    display: block;
//    width: 25px;
//    height: 25px;
//    background: white;
//    border-radius: 50%;
//    transition: all 200ms;
//    transition: all 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
//  }
//  &:hover {
//    &:after {
//      background: #ffaf15;
//      transition: all 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
//    }
//  }
//  .video-play-button-img {
//    position: relative;
//    z-index: 3;
//    max-width: 100%;
//  }
//}
//
//@keyframes pulse-border {
//  0% {
//    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
//    opacity: 1;
//  }
//
//  100% {
//    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
//    opacity: 0;
//  }
//}
</style>