import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [

    {
        path: "/member/detail/:id",
        name: "UserDetailView",
        component: () =>
            import("@/components/pages/member/MemberDetailView.vue"),
    },
];

export default routes