
import GazeInput from '@/components/common/input/GazeInput.vue';
import {computed, onBeforeUnmount, onMounted, onUnmounted, onUpdated, ref} from 'vue';
import {useHeaderSearch} from '@/script/common/headerSearchScript';
import {useTempImg} from '@/script/temp/tempScript';
import router from '@/router';
import {store} from '@/store';
export default {
  components: {GazeInput},
  props: ['onClcik'],
  setup(props: any) {
    const {
      searchValue,
      searchKeyword,
      fetchHomeSearchList,
      resetSearchKeyword,
      searchNftList,
      searchCltnList,
      searchMemberList,
      searchNftListLength,
      searchCltnListLength,
      searchMemberListLength,
    } = useHeaderSearch();

    const {tempProfile} = useTempImg();

    const searchByKeyword = () => {
      fetchHomeSearchList(searchKeyword.keyword);
    };

    const handleOnClick = () => {
      props.onClcik('nftSearch');
      searchKeyword.keyword = '';
      store.commit('search/DELETE_SEARCH_KEYWORD_LIST');
      store.commit('search/DELETE_SEARCH_NFT_LIST');
      store.commit('search/DELETE_SEARCH_CLTN_LIST');
      store.commit('search/DELETE_SEARCH_MEMBER_LIST');
      searchNftListLength.value = 0;
      searchCltnListLength.value = 0;
      searchMemberListLength.value = 0;
    };

    const moveDetailPage = async (type: any, id: any) => {
      if (type === 'nft') {
        await router.push(`/nft/${id}`);
        handleOnClick();
        store.commit('open/TOGGLE_MOBILE_SIDEBAR');
      } else if (type === 'cltn') {
        await router.push(`/cltn/detail/${id}`);
        handleOnClick();
        store.commit('open/TOGGLE_MOBILE_SIDEBAR');
      } else if (type === 'member') {
        await router.push(`/member/detail/${id}`);
        handleOnClick();
        store.commit('open/TOGGLE_MOBILE_SIDEBAR');
      }
    };

    const tempThumimg = {
      icon: require('@/assets/image/thumb.png'),
    };

    const emptyInput = () => {
      searchKeyword.keyword = '';
      searchByKeyword();
    };

    const globalSearchKeyword = computed(() => {
      return store.state.search.keyword;
    });

    const setLocalStorageNftTab = () => {
      localStorage.setItem('nft-selectedTab', 'nft');
    };

    const moveNftList = async (url: any) => {
      await setLocalStorageNftTab();
      await router.push({
        name: 'NftListView',
        query: {name: url, keyword: globalSearchKeyword.value},
      });
      emptyInput();
      store.commit('open/TOGGLE_MOBILE_SIDEBAR');
      store.commit('search/DELETE_SEARCH_KEYWORD_LIST');
    };
    const searchContainerRef = ref();
    function handleScroll() {
      const nftBar = document.getElementById('nft');
      const cltnBar = document.getElementById('cltn');
      const memberBar = document.getElementById('member');

      if (nftBar != undefined && cltnBar != undefined && memberBar != undefined) {
        if (nftBar?.offsetTop <= searchContainerRef.value.scrollTop + 154) {
          cltnBar.style.position = 'inherit';
          nftBar.style.position = 'fixed';
          memberBar.style.position = 'inherit';
          nftBar.style.top = '151px';
          searchContainerRef.value.style.marginTop = '24px';
        }
        if (cltnBar?.offsetTop <= searchContainerRef.value.scrollTop + 154) {
          memberBar.style.position = 'inherit';
          nftBar.style.position = 'inherit';
          cltnBar.style.position = 'fixed';
          cltnBar.style.top = '151px';
          searchContainerRef.value.style.marginTop = '24px';
        }
        if (memberBar?.offsetTop <= searchContainerRef.value.scrollTop + 154) {
          memberBar.style.position = 'fixed';
          memberBar.style.top = '151px';
          cltnBar.style.position = 'inherit';
          nftBar.style.position = 'inherit';
          searchContainerRef.value.style.marginTop = '24px';
        }
      }
    }
    function handleResize() {
      searchContainerRef.value.style.height = window.innerHeight + 'px';
    }

    onMounted(() => {
      handleResize();
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
      searchContainerRef.value.addEventListener('scroll', handleScroll);
    });
    onUpdated(() => {
      const nftBar = document.getElementById('nft');
      const cltnBar = document.getElementById('cltn');
      const memberBar = document.getElementById('member');
      if (nftBar?.style.position === 'fixed') {
        nftBar.style.position = 'inherit';
        searchContainerRef.value.style.marginTop = 'inherit';
      }
      if (cltnBar?.style.position === 'fixed') {
        cltnBar.style.position = 'inherit';
        searchContainerRef.value.style.marginTop = 'inherit';
      }
      if (memberBar?.style.position === 'fixed') {
        memberBar.style.position = 'inherit';
        searchContainerRef.value.style.marginTop = 'inherit';
      }
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    });

    return {
      searchValue,
      searchKeyword,
      fetchHomeSearchList,
      resetSearchKeyword,
      searchNftList,
      searchCltnList,
      searchMemberList,
      searchNftListLength,
      searchCltnListLength,
      searchMemberListLength,
      searchByKeyword,
      moveDetailPage,
      tempProfile,
      tempThumimg,
      moveNftList,
      emptyInput,
      searchContainerRef,
      handleOnClick,
    };
  },
};
