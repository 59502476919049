import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_svg = _resolveComponent("base-svg")!

  return (_openBlock(), _createBlock(_component_base_svg, {
    width: _ctx.width,
    height: _ctx.height,
    fill: _ctx.fill,
    viewBox: `0 0 32 32`
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M20.8001 13.4666C20.8001 17.5166 17.5169 20.7999 13.4668 20.7999C9.41671 20.7999 6.13346 17.5166 6.13346 13.4666C6.13346 9.41646 9.41671 6.13322 13.4668 6.13322C17.5169 6.13322 20.8001 9.41646 20.8001 13.4666ZM19.1463 21.6983C17.5331 22.8134 15.5762 23.4666 13.4668 23.4666C7.94395 23.4666 3.4668 18.9894 3.4668 13.4666C3.4668 7.94371 7.94395 3.46655 13.4668 3.46655C18.9896 3.46655 23.4668 7.94371 23.4668 13.4666C23.4668 15.9177 22.5849 18.1628 21.1212 19.902L28.4097 27.1905L26.5241 29.0761L19.1463 21.6983Z",
        fill: _ctx.fill
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["width", "height", "fill"]))
}