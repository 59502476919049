import axios from 'axios';
import router from "@/router";
import {openPopup} from "@/script/common/popupScript";
import {Modal} from "ant-design-vue";
import {createVNode} from "vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL + '/api/v1',
})

// instance.interceptors.response.use((response) => {
//     if(response.data.error) return errorHandler(response);
// })

// const errorHandler = (response) => {
// }
instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401 || error.response.errorCode === 1001) {
            alert("로그인이 필요한 서비스입니다")
            router.push({name: "login"});
        } else if (error.response.status === 502) {
            const lastUrl =  error.response.request.responseURL.split('/')
            const setUrl = new Set(lastUrl)
            if(setUrl.has('login-info')){
                openPopup("warning", {message: '서비스 점검중입니다. 잠시후 다시 시도해주세요.'})
                // Modal.confirm({
                //     title: '서비스 점검중입니다. 잠시후 다시 시도해주세요.',
                //     icon: createVNode(ExclamationCircleOutlined),
                //     wrapClassName: 'login-check-modal',
                //     // content: '로그인 페이지로 이동합니다.',
                //     okText: '확인',
                //     cancelText: '취소',
                //     centered: true,
                //     async onOk() {
                //         console.log('');
                //         // await router.push({name: 'LoginView'})
                //     },
                //     onCancel() {
                //         console.log('Cancel');
                //     },
                // });
            }
            // alert("서비스 점검중입니다. 잠시후 다시 시도해주세요.");
        }else if(error.response.data.message=== 'INVALID_REQUEST : 이전 요청을 처리중입니다.'){
            openPopup("warning", {message: 'modal.confirmModal.openModal.previousRequest'})
        }else if(error.response.data.message === 'INVALID_REQUEST : 다른 계정에 등록된 address입니다.'){
            openPopup("warning", {message: 'modal.confirmModal.openModal.anotherWallet'})
        }else if(error.response.data.message==='INVALID_REQUEST : 활성화된 제안이 아닙니다.'){
            openPopup("warning", {message: 'modal.confirmModal.openModal.noActive'})
        }else if(error.response.data.message==='INVALID_REQUEST : 다른 계정에 등록된 지갑으로는 구매를 할 수 없습니다.'){
            openPopup("warning", {message: 'modal.confirmModal.openModal.noSaleAnotherWallet'})
        }
        // else if(error.response.data.message==='INVALID_VALUE : 연속된 4자리 숫자 or 문자 비밀번호'){
        //     openPopup("warning", {message: '연속된 4자리 숫자 or 문자 비밀번호'})
        // }
        else console.log("서버오류")
        throw error;
    }
);


export default instance