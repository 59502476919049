import {reactive, ref} from "vue";
import {getHeaderShopCltnApi} from "@/api/cltnApis";
import {useRouter} from "vue-router";


export const useHeaderHover = () => {
    const router = useRouter()
    const headerShopCltnData = ref()
    const fetchHeaderShopCltn = async () => {
        try {
            const res = await getHeaderShopCltnApi();
            headerShopCltnData.value = res.data

        } catch (e) {
            console.log(e)
        }
    };
    const routeCltnDetail = (id:any) =>{
        router.push({name:'CltnDetailView',params:{id:id}})
    }

    return {
        fetchHeaderShopCltn,
        headerShopCltnData,
        routeCltnDetail
    }
}